import { useTranslation } from "react-i18next";
import {
  Col,
  Collapse,
  CollapseProps,
  Row,
  Select,
  Space,
  theme,
  Typography,
} from "antd";
import { DocumentCard } from "./DocumentCard";
import { CSSProperties } from "react";

import { CaretRightOutlined } from "@ant-design/icons";
import { DataType, EntityDocument } from "../models/dataType";

const { Text } = Typography;

interface DocumentsProps {
  data: DataType;
}

const getItems = (
  data: DataType,
  panelStyle: CSSProperties
): CollapseProps["items"] => {
  let documents: EntityDocument[] = []
  documents = documents.concat(data.dmsDocuments);
  documents = documents.concat(data.divisionDocuments);
  if (!documents) return [];
  const rows = [];

  // get Types, remove duplicates
  let types = Array.from(new Set(documents.map((val) => val.type)));

  // Keep Other category at the end
  types.sort();
  if (types.includes("Other")) {
    types = types.filter((v) => v !== "Other");
    types.push("Other");
  }

  for (let i = 0; i < types.length; i++) {
    const typeDocs = documents.filter((doc) => doc.type === types[i]);
    rows.push({
      key: i,
      label: (
        <Col style={{ marginBottom: "12px" }}>
          <Text style={{ color: "#234963" }} strong>
            {types[i]}: [{typeDocs.length}]
          </Text>
        </Col>
      ),
      children: <DocumentRow documents={typeDocs} />,
      style: panelStyle,
    });
  }

  return rows;
};

const DocumentRow = ({ documents }: { documents: EntityDocument[] }) => {
  // translate size from bytes
  let docs = [];
  for (const document of documents) {
    docs.push(
      <DocumentCard
        document={document}
        key={document.title}
      />
    );
  }
  return (
    <Row>
      <Col style={{ width: "100%", paddingRight: "0.5%" }}>{docs}</Col>
    </Row>
  );
};

export const Documents = ({ data }: DocumentsProps) => {
  const { t } = useTranslation();

  const { token } = theme.useToken();

  const panelStyle: React.CSSProperties = {
    marginBottom: 24,
    background: token.colorFillAlter,
    borderRadius: token.borderRadiusLG,
    border: "none",
  };

  return (
    <div style={{ width: "100%" }}>
      {/*<Row className="product-searchbar">*/}
      {/*  <Col>*/}
      {/*    <Space>*/}
      {/*      <Text strong>{t("Document Type")}</Text>*/}
      {/*      <Select*/}
      {/*        style={{ width: 120 }}*/}
      {/*        options={[{ value: "all", label: "All" }]}*/}
      {/*        defaultValue={"all"}*/}
      {/*      />*/}
      {/*    </Space>*/}
      {/*  </Col>*/}
      {/*</Row>*/}
      <Collapse
        bordered={false}
        defaultActiveKey={["1"]}
        expandIcon={({ isActive }) => (
          <CaretRightOutlined rotate={isActive ? 90 : 0} />
        )}
        style={{ background: "#f5f5f5" }}
        items={getItems(data, panelStyle)}
      />
    </div>
  );
};
