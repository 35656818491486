import { createContext } from "react";

export type Preference = {
  parameter: string;
  value: string;
};

type WishListItem = {
  type: "factory" | "supplier" | "product";
  reference: string;
};

export interface User {
  token: string;
  firstName: string;
  lastName: string;
  email: string;
  createdOn: string;
  enabled: boolean;
  lastUpdatedOn: string;
  preferences: Preference[];
  wishListItems: WishListItem[];
}

export const UserContext = createContext<User | null>(null);
