export type SourceType = "supplier" | "factory" | "customer";

interface Contact {
  title: string;
  name: string;
  email: string;
}

export interface EntityDocument {
  title: string;
  type: string;
  date: number;
  size: number;
}

export interface DMSDocument extends EntityDocument {
  barcode: string;
}

export interface DivisionDocument extends EntityDocument {
  division: string;
  fileUrl: string;
}

export interface DataType {
  name: string;
  code: string;
  complianceLevel: string;
  socialCompliance: string;
  lfScore: string;
  auditScore: string;
  address: string[];
  contacts: Contact[];
  telephone: string;
  yearsWithLf: string;
  lastShipment: string;
  shipmentNo: string[];
  factories: string;
  topCustomers: string[];
  topCustomersCalculated: {
    customer: string;
    value?: string;
    name?: string;
    percent: string;
  }[];
  customerPercent: string[];
  topProductCategories: string[];
  topProductCategoryCogs: string[];
  topProductCategoryCogValues: string[];
  topExportCountries: string[];
  topExportCountriesCalculated: {
    country: string;
    cogPercent: string;
    cogValue: string;
  }[];
  countryPercent: string[];
  topExportCountryCodes: string[];
  productionCountry: string;
  topProductionCountries: {
    country: string;
    cogPercent: string;
    cogValue: string;
  }[];
  productType: string;
  supplierWebsite: string;
  businessVolOverall: string;
  businessVolLf: string;
  avgLeadTimeCalculated: string;
  avgOrderAmountCalculated: string;
  avgOrderQuantityCalculated: string;
  avgPmCostCalculated: string;
  avgLeadTime: string[];
  avgOrderAmount: string[];
  avgOrderQuantity: string[];
  quantityShipped: string[];
  avgPmCost: string[];
  pieces: string[];
  deliveryRate: string[];
  qualityClaim: string[];
  cogs: string[];
  fob: string[];
  supplierRelationships: string[];
  factoryRelationships: string[];
  factoryRelationshipsFullName: string[];
  factoryRelationshipsCompliance: string[];
  customerRelationships: string[];
  imageUrl: string;
  noOfEmployees: string;
  dmsDocuments: DMSDocument[];
  divisionDocuments: DivisionDocument[];
}

export interface CommentDataType {
  supplier_code: string;
  supplier_name: string;
  overall_rating: number;
  delivery: number;
  responsiveness: number;
  sustainability: number;
  capacity: number;
}

export interface ProductDataType {
  division_code: string;
  family_code: string;
  description: string;
  number: string;
  item_description: string;
  item_number: string;
  item_run_number: string;
  operating_group: string;
  product_category: string;
  product_category_code: string;
  product_category_group: string;
  product_category_group_code: string;
  product_family: string;
  product_id: string;
  product_type: string;
  product_type_code: string;
  avg_cost: string;
  avg_order_amount: string;
  sales__average_order_size: string;
  sales__cogs: string;
  color_description: string;
  color_quantity: string;
  customer: string;
  sales__fob: string;
  factory_code: string;
  supplier_code: string;
  sales__po_count: string;
  sales__quantity: string;
  sales__shipment_count: string;
  season: string;
  status_code: string;
  stream: string;
  target_price: any;
  image_url: string;
}

// Utility Functions
const reduceCountryName = (country: string) => {
  if (!country) return;
  const countryCodeMap: { [key: string]: any } = {
    "united states of america": "USA",
    china: "CHN",
    "hong kong": "HKG",
    "united kingdom": "UK",
    canada: "CAN",
  };

  return countryCodeMap[country.toLowerCase()] ?? country;
};

const twoDec = (num: number) => Math.round(num * 100) / 100;
const formatNumber = (num: number) =>
  num ? twoDec(num).toLocaleString() : "--";

const arrayOptional = (raw: any | any[]): any[] => {
  return Array.isArray(raw) ? raw : [raw];
};

const metricsArrayFilter = (
  raw: number[],
  years: string[],
  percentage?: boolean,
  debug?: boolean,
): string[] => {
  let valid_array = ["--", "--", "--", "--", "--"];
  if (!raw || !years) return valid_array;
  if (!Array.isArray(raw)) raw = [raw];
  if (!Array.isArray(years)) years = [years];

  if (debug) {
    console.log(raw);
  }

  if (percentage) {
    raw = raw.map((x) => x * 100);
  }

  // Fix for 2024 inclusion
  // years = years.filter(x => x !== "2024")
  //
  // for (let i = 0; i < years.length; i++) {
  //   if (years[i] === "2023") valid_array[0] = raw[i]?.toLocaleString();
  //   else if (years[i] === "2022") valid_array[1] = raw[i]?.toLocaleString();
  //   else if (years[i] === "2021") valid_array[2] = raw[i]?.toLocaleString();
  // }

  // years = years.filter(x => x !== "2024")

  // should update for dynamic
  for (let i = 0; i < years.length; i++) {
    if (years[i] === "2024") valid_array[0] = raw[i]?.toLocaleString();
    else if (years[i] === "2023") valid_array[1] = raw[i]?.toLocaleString();
    else if (years[i] === "2022") valid_array[2] = raw[i]?.toLocaleString();
    else if (years[i] === "2021") valid_array[3] = raw[i]?.toLocaleString();
    else if (years[i] === "2020") valid_array[4] = raw[i]?.toLocaleString();
  }

  return valid_array;
};

const gatherContacts = (result: any): Contact[] => {
  const contacts: any[] = [];
  // Owner
  result.demographic__owner?.raw &&
    contacts.push({
      title: result.demographic__owner_title?.raw,
      name: result.demographic__owner?.raw,
      email: result.demographic__owner_email?.raw,
    });

  // Primary Contact
  contacts.push({
    title: "Primary",
    name: undefined,
    email: result.demographic__primary_contact_email?.raw,
  });

  return contacts;
};

const gatherDMSDocuments = (result: any): DMSDocument[] => {
  const documents = [];
  const codes = result["documents__DMS.barcode"]?.raw;
  if (!codes) {
    return [];
  }

  if (Array.isArray(codes)) {
    for (let i = 0; i < codes.length; i++) {
      const dms: DMSDocument = {
        title: result["documents__DMS.doc_name"]?.raw[i],
        type: result["documents__DMS.doc_type"]?.raw[i],
        barcode: result["documents__DMS.barcode"]?.raw[i],
        date: result["documents__DMS.created_at"]?.raw[i],
        size: result["documents__DMS.file_size"]?.raw[i],
      };
      documents.push(dms);
    }
  } else {
    documents.push({
      title: result["documents__DMS.doc_name"]?.raw,
      type: result["documents__DMS.doc_type"]?.raw,
      barcode: result["documents__DMS.barcode"]?.raw,
      date: result["documents__DMS.created_at"]?.raw,
      size: result["documents__DMS.file_size"]?.raw,
    });
  }

  documents.sort((a, b) => a.date - b.date);
  return documents;
};

const gatherDivisionDocuments = (result: any): DivisionDocument[] => {
  const documents = [];
  const codes = result["documents__division.file_url"]?.raw;
  if (!codes) {
    return [];
  }

  if (Array.isArray(codes)) {
    for (let i = 0; i < codes.length; i++) {
      const dms: DivisionDocument = {
        division: result["documents__division.division_code"]?.raw[i],
        title: result["documents__division.doc_name"]?.raw[i],
        type: result["documents__division.doc_type"]?.raw[i],
        fileUrl: result["documents__division.file_url"]?.raw[i],
        date: result["documents__division.created_at"]?.raw[i],
        size: result["documents__division.file_size"]?.raw[i],
      };
      documents.push(dms);
    }
  } else {
    documents.push({
      division: result["documents__division.division_code"]?.raw,
      title: result["documents__division.doc_name"]?.raw,
      type: result["documents__division.doc_type"]?.raw,
      fileUrl: result["documents__division.file_url"]?.raw,
      date: result["documents__division.created_at"]?.raw,
      size: result["documents__division.file_size"]?.raw,
    });
  }

  documents.sort((a, b) => a.date - b.date);
  return documents;
};

const calcPercent = (current: string, total: string): string => {
  const result = Math.round((parseFloat(current) / parseFloat(total)) * 100);
  return isNaN(result) ? "--" : result.toLocaleString();
};

const countryPercent = (result: any): any[] => {
  const raw = result["sales__export_country_agg.export_country"]?.raw;
  if (!raw) return ["--", "--", "--", "--", "--"];

  // Check for as many countries as there are in top countries
  const percentages = [];
  let total = result["sales__export_country_agg.cogs"]?.raw;
  total = Array.isArray(total)
    ? total.reduce((val, curr) => val + curr, 0)
    : total;

  if (Array.isArray(raw)) {
    for (let i = 0; i < raw.length; i++) {
      const percentage = calcPercent(
        result["sales__export_country_agg.cogs"]?.raw[i],
        total,
      );

      percentages.push(percentage);
    }
  } else {
    return [
      calcPercent(result["sales__export_country_agg.cogs"]?.raw, total),
      "-",
      "-",
    ];
  }
  return percentages;
};

// Facility Specific formatting functions

const supplierTopCustomers = (result: any): string[] => {
  const raw = result["sales__customer_agg.customer_name"]?.raw;
  if (!raw) return ["--", "--", "--"];

  if (!Array.isArray(raw)) return [raw, "--", "--"];
  const filtered = Array.from(new Set(raw));
  if (filtered.length < 5) {
    while (filtered.length < 5) filtered.push("--");
  }

  return filtered;
};

const customerCalculateTopProductCategories = (result: any) => {
  const productCategories =
    result["sales__product_category_cogs.product_category"]?.raw;
  const productCategoryCogs = result["sales__product_category_cogs.cogs"]?.raw;
};

const customerCalculateTopCustomers = (result: any) => {
  const customerCodes = result["sales__supplier_cogs_agg.supplier_code"]?.raw;
  const customerNames = result["sales__supplier_cogs_agg.supplier_name"]?.raw;
  let customerFobc = result["sales__supplier_cogs_agg.cogs"]?.raw;

  const customerFobcSum = Array.isArray(customerFobc)
    ? customerFobc.reduce((a, c) => a + c, 0)
    : customerFobc;

  const tempCustomerFobc = Array.isArray(customerFobc)
    ? customerFobc[0]
    : customerFobc;

  // const total = Array.isArray(yearlyAggFob) ? yearlyAggFob.reduce((accumulator, currentValue) => accumulator + currentValue, 0) : yearlyAggFob

  if (!Array.isArray(customerCodes)) {
    // customerFobc = customerFobc ? customerFobc.toFixed(2) : '0';
    if (!tempCustomerFobc)
      return [
        {
          customer: customerCodes ? customerCodes : "",
          percent: "--",
          value: "--",
          name: customerNames ? customerNames : "",
        },
      ];

    const sortedArr = [
      {
        customer: customerCodes ? customerCodes : "",
        name: customerNames ? customerNames : "",
        value: tempCustomerFobc.toFixed(2),
        percent: calcPercent(
          tempCustomerFobc.toFixed(2),
          customerFobcSum.toFixed(2),
        ),
      },
    ].sort((a, b) => parseFloat(b.value) - parseFloat(a.value));

    return sortedArr;
  }

  const customerFobcs = customerCustomerFob(result);

  const customerFobcsWithPercent = customerFobcs
    .map((fobc) => ({
      customer: fobc.customerCode,
      value: fobc.fobc.toFixed(2),
      name: fobc.customerName,
      percent: calcPercent(fobc.fobc.toFixed(2), customerFobcSum),
    }))
    .sort((a, b) => parseFloat(b.value) - parseFloat(a.value));

  return customerFobcsWithPercent;
};

export const factoryCalculatedTopCustomers = (data: any) => {
  const fobc = data["sales__customer_fobc.fobc"]?.raw;
  const customerNames = data["sales__customer_fobc.customer_code"]?.raw;

  const customerFobcSum = Array.isArray(fobc)
    ? fobc.reduce((a, c) => a + c, 0)
    : fobc;

  if (!Array.isArray(customerNames)) {
    const tempCustomerFobc = Array.isArray(fobc) ? fobc[0] : fobc;

    if (!tempCustomerFobc)
      return [
        {
          customer: customerNames ? customerNames : "",
          percent: "--",
        },
      ];

    const sortedArr = [
      {
        customer: customerNames,
        percent: calcPercent(
          tempCustomerFobc.toString(),
          customerFobcSum.toString(),
        ),
      },
    ].sort((a, b) => parseFloat(b.percent) - parseFloat(a.percent));
    return sortedArr;
  }

  const customerNamesSorted = customerNames.map(
    (name: string, index: number) => {
      const tempFobc = Array.isArray(fobc) && fobc[index] ? fobc[index] : 0;
      const percentage = calcPercent(tempFobc, customerFobcSum);

      return {
        customer: name,
        percent: percentage,
      };
    },
  );

  return customerNamesSorted;
};

const supplierCalculatedTopCustomers = (result: any) => {
  const customerCodes = result["sales__customer_fobc_agg.customer_code"]?.raw;
  const yearlyAggFob = result["sales__yearly_agg.fob"]?.raw;
  let customerFobc = result["sales__customer_fobc_agg.fobc"]?.raw;

  const customerFobcSum = Array.isArray(customerFobc)
    ? customerFobc.reduce((a, c) => a + c, 0)
    : customerFobc;

  const tempCustomerFobc = Array.isArray(customerFobc)
    ? customerFobc[0]
    : customerFobc;

  if (!Array.isArray(customerCodes)) {
    customerFobc = customerFobc ? customerFobc.toString() : "0";
    if (!tempCustomerFobc)
      return [
        {
          customer: customerCodes ? customerCodes : "",
          percent: "--",
        },
      ];

    const sortedArr = [
      {
        customer: customerCodes,
        percent: calcPercent(
          tempCustomerFobc.toString(),
          customerFobcSum.toString(),
        ),
      },
    ].sort((a, b) => parseFloat(b.percent) - parseFloat(a.percent));

    return sortedArr;
  }

  const customerFobcs = supplierCustomerFob(result);

  const customerFobcsWithPercent = customerFobcs.map((fobc) => ({
    customer: fobc.customerCode,
    percent: calcPercent(fobc.fobc.toString(), customerFobcSum),
  }));

  return customerFobcsWithPercent;
};

const supplierProductCategory = (result: any): string[] => {
  const raw = result["sales__product_category_cogs.product_category"]?.raw;
  if (!raw) return ["--", "--", "--"];

  const product_categories = Array.isArray(raw) ? raw : [raw, "--", "--"];
  const final = product_categories.map((c) => (c ? c : "--"));
  while (final.length < 5) final.push("--");
  return final;
};

const supplierProductCategoryCogs = (result: any): string[] => {
  const cogs = result["sales__product_category_cogs.cogs"]?.raw;
  if (!cogs) return ["0", "0", "0"];

  if (!Array.isArray(cogs)) return ["100", "--", "--"];

  let raw: any[] = result["sales__product_category_cogs.cogs"]?.raw;
  const total = raw.reduce((val, curr) => val + curr, 0);

  const cogs_arr = cogs.map((fob) => calcPercent(fob, total));
  while (cogs_arr.length < 5) cogs_arr.push("--");
  return cogs_arr;
};

const supplierCustomerPercent = (result: any): string[] => {
  if (!result) return ["0", "0", "0", "0", "0"];

  const customer_set = new Set(
    result["sales__customer_agg.customer_code"]?.raw,
  );
  if (customer_set.size === 1) return ["100", "--", "--", "--", "--"];

  const fob = result["sales__customer_agg.fob"]?.raw;
  if (!Array.isArray(fob)) return ["100", "--", "--", "--", "--"];

  let total = result["sales__yearly_agg.fob"]?.raw;
  if (Array.isArray(total)) {
    total = total[0] ?? 100;
  }

  const cust = fob.map((val) => {
    return calcPercent(val, total);
  });
  while (cust.length < 5) cust.push("--");

  return cust;
};

const factoryCustomerPercent = (result: any): string[] => {
  if (!result) return ["0", "0", "0", "0", "0"];

  const fob = result["sales__customer_fobc.fobc"]?.raw;
  if (!Array.isArray(fob)) return ["100", "0", "0", "--", "--"];

  let total = result["sales__yearly_agg.fob"]?.raw;
  if (Array.isArray(total)) {
    total = total[0] ?? 100;
  }

  const cust = fob.map((val) => calcPercent(val, total));
  while (cust.length < 5) cust.push("--");
  return cust;
};

function supplierYearsWithLF(result: any): string {
  const established = parseInt(result["first_order_date"]?.raw);
  if (!established) return "--";
  const establishedYear = new Date(established).getFullYear();
  const currentYear = new Date().getFullYear();
  return (currentYear - establishedYear).toLocaleString();
}

function customerYearsWithLF(result: any): string {
  const established = parseInt(result["lf__first_date_with_lf"]?.raw);
  if (!established) return "--";
  const establishedYear = new Date(established).getFullYear();
  const currentYear = new Date().getFullYear();
  return (currentYear - establishedYear).toLocaleString();
}

const supplierSocialCompliance = (result: any) => {
  if (!result) return "--";

  const A = result["compliance__social_compliance.A"]?.raw ?? 0;
  const B = result["compliance__social_compliance.B"]?.raw ?? 0;
  const C = result["compliance__social_compliance.C"]?.raw ?? 0;
  const D = result["compliance__social_compliance.D"]?.raw ?? 0;
  const UNRATED = result["compliance__social_compliance.UNRATED"]?.raw ?? 0;

  if (A === 0 && B === 0 && C === 0 && D === 0 && UNRATED === 0) {
    return "--";
  }

  let output = "";
  if (A > 0) output += "A: " + A;
  if (B > 0) output += " B: " + B;
  if (C > 0) output += " C: " + C;
  if (D > 0) output += " D: " + D;
  if (UNRATED > 0) output += " Unrated: " + UNRATED;

  return output;
};

const supplierLastShipment = (result: any) => {
  const timeValue = result["demographic__last_shipment_date"]?.raw;
  if (!timeValue) return "--";

  return new Date(timeValue).toUTCString();
};

const factoryTopCustomers = (result: any): string[] => {
  const raw = result["sales__customer_fobc.customer_name"]?.raw;
  if (!raw) return ["--", "--", "--", "--", "--"];

  if (!Array.isArray(raw)) {
    return [raw, "--", "--", "--", "--"];
  }
  const filtered = Array.from(new Set(raw));
  if (filtered.length < 5) {
    while (filtered.length < 5) filtered.push("--");
  }

  return filtered;
};

const factoryProductCategories = (result: any): string[] => {
  const raw = result["sales__product_category_cogs.product_category"]?.raw;
  if (!raw) return ["-", "-", "-", "-", "-"];

  if (!Array.isArray(raw)) return [raw, "--", "--", "--", "--"];

  while (raw.length < 5) raw.push("--");
  return raw;
};

const factoryExportCountries = (result: any): string[] => {
  const raw = result["sales__export_country_cogs.export_country"]?.raw;
  if (!raw) return ["--", "--", "--"];

  if (Array.isArray(raw)) {
    while (raw.length < 5) raw.push("--");
    return raw;
  } else {
    return [raw, "--", "--"];
  }
};

const facilityExportCountry = (result: any): string[] => {
  const raw = result["sales__export_country_agg.export_country"]?.raw;
  if (!raw) return ["--", "--", "--", "--", "--"];
  if (!Array.isArray(raw)) return [raw, "--", "--", "--", "--"];

  while (raw.length < 5) raw.push("--");
  return raw;
};

const formatExportCountries = (
  exportCountries: string[],
  exportCountryCogs: number[] | number,
): { cogs: number; export_country: string }[] => {
  if (Array.isArray(exportCountryCogs))
    return exportCountries.map((country, index) => ({
      cogs: exportCountryCogs[index] ? exportCountryCogs[index] : 0,
      export_country: country,
    }));

  const countryCogs = exportCountryCogs ? exportCountryCogs : 0;

  return exportCountries.map((country, index) => ({
    cogs: index === 0 ? countryCogs : 0,
    export_country: country,
  }));
};

const formatProductionCountries = (
  productionCountries: string[],
  productionCountryCogs: number[],
): { cogs: number; production_country: string }[] => {
  if (Array.isArray(productionCountryCogs))
    return productionCountries.map((country, index) => ({
      cogs: productionCountryCogs[index] ? productionCountryCogs[index] : 0,
      production_country: country,
    }));

  const countryCogs = productionCountryCogs ? productionCountryCogs : 0;

  return productionCountries.map((country, index) => ({
    cogs: index === 0 ? countryCogs : 0,
    production_country: country,
  }));
};

const formatCustomerFob = (
  fobcArr: number[],
  customerCodes: string[],
  customerNames: string[],
): { fobc: number; customerCode: string; customerName: string }[] => {
  return fobcArr.map((fobc, index) => ({
    fobc: fobc ? fobc : 0,
    customerCode:
      customerCodes && customerCodes[index] ? customerCodes[index] : "",
    customerName:
      customerNames && customerNames[index] ? customerNames[index] : "",
  }));
};

const supplierExportCountry = (
  result: any,
): { cogs: number; export_country: string }[] => {
  if (result["sales__export_country_cogs"]?.raw) {
    return result["sales__export_country_cogs"]?.raw;
  }

  let exportCountryAggCogs = result["sales__export_country_agg.cogs"]?.raw;

  if (!exportCountryAggCogs) exportCountryAggCogs = [0, 0, 0, 0, 0];

  const processedExportCountryAggCounts = Array.isArray(exportCountryAggCogs)
    ? exportCountryAggCogs
    : [exportCountryAggCogs];

  const exportCountriesRaw = facilityExportCountry(result);
  // const exportCountryCogsRaw = result["sales__export_country_agg.cogs"]?.raw ? result["sales__export_country_agg.cogs"]?.raw : [0, 0, 0, 0, 0]
  return formatExportCountries(
    exportCountriesRaw,
    processedExportCountryAggCounts,
  );
};

const factoryExportCountry = (
  result: any,
): { cogs: number; export_country: string }[] => {
  const exportCountriesRaw = factoryExportCountries(result);
  const exportCountryCogsRaw = result["sales__export_country_cogs.cogs"]?.raw
    ? result["sales__export_country_cogs.cogs"]?.raw
    : [0, 0, 0, 0, 0];

  const formattedCountries = formatExportCountries(
    exportCountriesRaw,
    exportCountryCogsRaw,
  );

  return formattedCountries;
};

const customerExportCountry = (
  result: any,
): { cogs: number; export_country: string }[] => {
  const exportCountriesRaw = customerExportCountries(result);
  const exportCountryCogsRaw = result["sales__cogs_per_export_country.cogs"]
    ?.raw
    ? result["sales__cogs_per_export_country.cogs"]?.raw
    : [0, 0, 0, 0, 0];
  return formatExportCountries(exportCountriesRaw, exportCountryCogsRaw);
};

const customerProductCountry = (
  result: any,
): { cogs: number; production_country: string }[] => {
  const productionCountriesRaw = customerProductionCountries(result);
  const productionCountryCogsRaw = result[
    "sales__fobc_per_production_country.fobc"
  ]?.raw
    ? result["sales__fobc_per_production_country.fobc"]?.raw
    : [0, 0, 0, 0, 0];
  return formatProductionCountries(
    productionCountriesRaw,
    productionCountryCogsRaw,
  );
};

const supplierCustomerFob = (
  result: any,
): { fobc: number; customerCode: string }[] => {
  const customerCodes = result["sales__customer_fobc_agg.customer_code"]?.raw;
  const customerNames = result["sales__customer_fobc_agg.customer_name"]?.raw;
  const customerFobc = result["sales__customer_fobc_agg.fobc"]?.raw;

  if (Array.isArray(customerCodes) && Array.isArray(customerFobc))
    return formatCustomerFob(customerFobc, customerCodes, customerNames);

  return [{ fobc: customerFobc, customerCode: customerCodes }];
  // return formatCustomerFob(customerFobc, customerCodes)
};

const customerCustomerFob = (
  result: any,
): { fobc: number; customerCode: string; customerName: string }[] => {
  const customerCodes = result["sales__supplier_cogs_agg.supplier_code"]?.raw;
  const customerNames = result["sales__supplier_cogs_agg.supplier_name"]?.raw;
  const customerFobc = result["sales__supplier_cogs_agg.cogs"]?.raw;

  if (
    Array.isArray(customerCodes) &&
    Array.isArray(customerFobc) &&
    Array.isArray(customerNames)
  )
    return formatCustomerFob(customerFobc, customerCodes, customerNames);

  return [
    {
      fobc: customerFobc,
      customerCode: customerCodes,
      customerName: customerNames,
    },
  ];
  // return formatCustomerFob(customerFobc, customerCodes)
};

const calculateTopExportCountries = (
  result: any,
  source: Exclude<SourceType, "product">,
) => {
  let exportCountries: { cogs: number; export_country: string }[];

  if (source === "customer") {
    exportCountries = customerExportCountry(result);
  } else {
    exportCountries =
      source === "supplier"
        ? supplierExportCountry(result)
        : factoryExportCountry(result);
  }

  const cogSum = exportCountries.reduce(
    (acc, country) => acc + country.cogs,
    0,
  );

  const sortedExportCountries = exportCountries
    .sort((a, b) => b.cogs - a.cogs)
    .splice(0, 5);

  return sortedExportCountries.map((country) => ({
    country: country.export_country,
    cogPercent: calcPercent(country.cogs.toString(), cogSum.toString()),
    cogValue: country.cogs.toString(),
  }));
};

const calculateTopProductionCountries = (result: any) => {
  const productionCountries: { cogs: number; production_country: string }[] =
    customerProductCountry(result);

  const cogSum = productionCountries.reduce(
    (acc, country) => acc + country.cogs,
    0,
  );

  const sortedProductionCountries = productionCountries
    .sort((a, b) => b.cogs - a.cogs)
    .splice(0, 5);

  return sortedProductionCountries.map((country) => ({
    country: country.production_country,
    cogPercent: calcPercent(country.cogs.toString(), cogSum.toString()),
    cogValue: country.cogs.toString(),
  }));
};

const customerTopProductCategory = (result: any): string[] => {
  const raw = result["sales__cogs_per_category.product_category"]?.raw;
  if (!Array.isArray(raw)) return [raw, "--", "--", "--", "--"];

  while (raw.length < 5) raw.push("--");
  return raw;
};

const customerTopProductCategoryCogs = (result: any): string[] => {
  const raw = result["sales__cogs_per_category.cogs"]?.raw;
  if (!raw) return ["--", "--", "--", "--", "--"];
  if (!Array.isArray(raw)) return ["100", "--", "--", "--", "--"];

  const total = raw.reduce((val: any, curr: any) => val + curr, 0);
  const percentages = raw.map((i: any) => calcPercent(i, total));

  while (percentages.length < 5) percentages.push("--");
  return percentages;
};

const customerExportCountries = (result: any): string[] => {
  const raw = result["sales__cogs_per_export_country.export_country"]?.raw;
  if (!raw) return ["--", "--", "--", "--", "--"];
  if (!Array.isArray(raw)) return [raw, "--", "--", "--", "--"];

  while (raw.length < 5) raw.push("--");
  return raw;
};

const customerProductionCountries = (result: any): string[] => {
  const raw =
    result["sales__fobc_per_production_country.production_country"]?.raw;
  if (!raw) return ["--", "--", "--", "--", "--"];
  if (!Array.isArray(raw)) return [raw, "--", "--", "--", "--"];

  while (raw.length < 5) raw.push("--");
  return raw;
};

//sales__fobc_per_production_country.production_country
const calculateWeightedAverage = (
  values: number[],
  counts: number[],
  multiplyByCount?: boolean,
  consoleLogString?: string,
): string => {
  let weightedValuesSum = 0;
  let countsSum = 0;

  let filteredValues = values;
  let filteredCounts = counts;

  if (Array.isArray(values))
    filteredValues = values.filter((value) => !isNaN(value));
  if (Array.isArray(counts))
    filteredCounts = counts.filter((count) => !isNaN(count));

  filteredValues.forEach((value, index) => {
    if (!isNaN(filteredValues[index]) && !isNaN(filteredCounts[index])) {
      weightedValuesSum += multiplyByCount
        ? value
        : value * filteredCounts[index];
      countsSum += filteredCounts[index];
    }
  });

  return countsSum ? (weightedValuesSum / countsSum).toFixed(2) : "--";
};

// Result Formatters
export const formatFacilityResult = (
  source: Exclude<SourceType, "product">,
  result: any,
): DataType => {
  const averageLeadTimes = metricsArrayFilter(
    result["sales__average_lead_time.average_lead_time"]?.raw,
    result["sales__average_lead_time.year"]?.raw,
  ).map(Number);

  const averageOrderAmount = metricsArrayFilter(
    result["sales__average_order_cogs.average_order_cogs"]?.raw,
    result["sales__average_order_cogs.year"]?.raw,
  ).map((value) => Number(value.replace(/,/g, "")));

  const averageOrderQuantities = metricsArrayFilter(
    result["sales__average_order_quantity.average_order_quantity"]?.raw,
    result["sales__average_order_quantity.year"]?.raw,
  ).map((value) => Number(value.replace(/,/g, "")));

  const averagePmCosts = metricsArrayFilter(
    result["sales__average_pm_cost.average_pm_cost"]?.raw,
    result["sales__average_pm_cost.year"]?.raw,
  ).map((value) => Number(value.replace(/,/g, "")));

  const salesCogs = metricsArrayFilter(
    result["sales__cogs.cogs"]?.raw,
    result["sales__cogs.year"]?.raw,
  ).map((value) => Number(value.replace(/,/g, "")));

  const shipmentQuantity = metricsArrayFilter(
    result["sales__pieces.pieces"]?.raw,
    result["sales__pieces.year"]?.raw,
  ).map((value) => Number(value.replace(/,/g, "")));

  const averageOrderCount = result["sales__order_count"]?.raw.map(
    (value: number | string) =>
      typeof value === "string" ? Number(value.replace(/,/g, "")) : value,
  );

  const weightedAverageLeadTime = calculateWeightedAverage(
    averageLeadTimes,
    averageOrderCount,
    false,
    "avgLeadTimeCalc",
  );

  const weightedAverageOrderAmount = calculateWeightedAverage(
    averageOrderAmount,
    averageOrderCount,
    false,
    "avgOrderAmountCalc",
  );

  const weightedAverageQuantity = calculateWeightedAverage(
    averageOrderQuantities,
    averageOrderCount,
    false,
    "avgQuantityCalc",
  );

  const weightedAveragePmCost = calculateWeightedAverage(
    salesCogs,
    shipmentQuantity,
    true,
    "avgPmCostCalc",
  );

  // const calculatedTopExportCountries = calculateTopExportCountries(result)

  const commonMap: DataType = {
    name: "",
    code: "",
    quantityShipped: [],
    contacts: gatherContacts(result),
    lfScore: result.lf__lf_score?.raw ?? "--",
    auditScore: formatNumber(result.lf__technical_audit_score?.raw),
    // Filter out null addresses
    address: result["demographic__address.address"]?.raw,
    telephone: result["demographic__address.telephone"]?.raw,
    factories: result.demographic__number_of_factory?.raw ?? "--",
    topProductCategoryCogs: supplierProductCategoryCogs(result),
    topProductCategoryCogValues: arrayOptional(
      result["sales__product_category_cogs.cogs"]?.raw,
    ),
    avgLeadTimeCalculated: weightedAverageLeadTime,
    avgOrderAmountCalculated: weightedAverageOrderAmount,
    avgOrderQuantityCalculated: weightedAverageQuantity,
    avgPmCostCalculated: weightedAveragePmCost,
    avgLeadTime: metricsArrayFilter(
      result["sales__average_lead_time.average_lead_time"]?.raw,
      result["sales__average_lead_time.year"]?.raw,
    ),
    avgOrderAmount: metricsArrayFilter(
      result["sales__average_order_cogs.average_order_cogs"]?.raw,
      result["sales__average_order_cogs.year"]?.raw,
    ),
    avgOrderQuantity: metricsArrayFilter(
      result["sales__average_order_quantity.average_order_quantity"]?.raw,
      result["sales__average_order_quantity.year"]?.raw,
    ),
    avgPmCost: metricsArrayFilter(
      result["sales__average_pm_cost.average_pm_cost"]?.raw,
      result["sales__average_pm_cost.year"]?.raw,
    ),
    pieces: metricsArrayFilter(
      result["sales__pieces.pieces"]?.raw,
      result["sales__pieces.years"]?.raw,
    ),
    supplierWebsite: "--",
    topExportCountries: facilityExportCountry(result),
    topExportCountriesCalculated: calculateTopExportCountries(result, source),
    countryPercent: countryPercent(result),
    cogs: metricsArrayFilter(
      result["sales__cogs.cogs"]?.raw,
      result["sales__cogs.year"]?.raw,
    ),
    fob: [],
    productType: "--",

    lastShipment: supplierLastShipment(result),

    shipmentNo: metricsArrayFilter(
      result["sales__shipments.shipments"]?.raw,
      result["sales__shipments.year"]?.raw,
    ),

    businessVolOverall:
      result["demographic__annual_turnover_usd"]?.raw.toLocaleString() ?? "--",
    businessVolLf: "--",
    deliveryRate: metricsArrayFilter(
      result["sales__otd_rate.otd_rate"]?.raw,
      result["sales__otd_rate.year"]?.raw,
      true,
    ),
    qualityClaim: metricsArrayFilter(
      result["sales__yearly_pass_rate.pass_rate"]?.raw,
      result["sales__yearly_pass_rate.year"]?.raw,
      true,
    ),
    topExportCountryCodes: ["--", "--", "--"],
    imageUrl: result.image_url?.raw,

    complianceLevel: "",
    socialCompliance: "",
    yearsWithLf: "--",
    topCustomers: [],
    topCustomersCalculated: [],
    customerPercent: [],
    topProductCategories: [],
    productionCountry: "",
    topProductionCountries: [],
    supplierRelationships: arrayOptional(
      result["relationships__supplier_relationships.supplier_code"]?.raw,
    ),
    factoryRelationships: arrayOptional(
      result["relationships__factory_relationships.factory_code"]?.raw,
    ),
    factoryRelationshipsFullName: arrayOptional(
      result["relationships__factory_relationships.factory_name"]?.raw,
    ),
    factoryRelationshipsCompliance: arrayOptional(
      result["relationships__factory_relationships.social_compliance_rating"]
        ?.raw,
    ),
    customerRelationships: arrayOptional(
      result["relationships__customer_relationships.customer_code"]?.raw,
    ),
    dmsDocuments: [],
    divisionDocuments: [],
    noOfEmployees: result["demographic__no_of_employees"]?.raw ?? "--",
  };

  const supplierMap: DataType = {
    ...commonMap,

    name: result.demographic__supplier_full_name?.raw,
    address: [
      result.demographic__address?.raw,
      result.demographic__address_local?.raw,
    ]
      .filter((a: any) => a)
      .filter((a: any) => a.trim()),

    code: result.supplier_code?.raw,
    quantityShipped: metricsArrayFilter(
      result["sales__pieces.pieces"]?.raw,
      result["sales__pieces.year"]?.raw,
    ),
    topCustomers: supplierTopCustomers(result),
    topCustomersCalculated: supplierCalculatedTopCustomers(result).sort(
      (a, b) => parseFloat(b.percent) - parseFloat(a.percent),
    ),
    topProductCategories: supplierProductCategory(result),
    productionCountry: result.demographic__country_name?.raw,
    yearsWithLf: supplierYearsWithLF(result),
    customerPercent: supplierCustomerPercent(result),
    complianceLevel: "",
    socialCompliance: supplierSocialCompliance(result),
    fob: metricsArrayFilter(
      result["sales__yearly_agg.fob"]?.raw,
      result["sales__yearly_agg.ship_year"]?.raw,
    ),
    // dmsDocuments: gatherDMSDocuments(result), Disabled due to missing DMS access for supplier documents
    divisionDocuments: gatherDivisionDocuments(result),
  };

  const factoryMap: DataType = {
    ...commonMap,

    name: result.demographic__factory_name?.raw,
    code: result.factory_code?.raw,
    complianceLevel: result.compliance__social_compliance_level?.raw ?? "--",
    socialCompliance: result.compliance__social_compliance_rating?.raw ?? "--",
    topCustomers: factoryTopCustomers(result),
    topCustomersCalculated: factoryCalculatedTopCustomers(result).sort(
      (a, b) => parseFloat(b.percent) - parseFloat(a.percent),
    ),
    // Elastic search will return just a string if there's only one item in the array
    topProductCategories: factoryProductCategories(result),
    topExportCountries: factoryExportCountries(result),
    productionCountry: result.demographic__production_country_code?.raw,
    yearsWithLf: supplierYearsWithLF(result),
    quantityShipped: metricsArrayFilter(
      result["sales__pieces.pieces"]?.raw,
      result["sales__pieces.year"]?.raw,
    ),
    customerPercent: factoryCustomerPercent(result),
    cogs: metricsArrayFilter(
      result["sales__cogs.cogs"]?.raw,
      result["sales__cogs.year"]?.raw,
    ),
    fob: ["--", "--", "--"],
    dmsDocuments: gatherDMSDocuments(result),
  };

  const customerMap: DataType = {
    ...commonMap,
    name: result.demographic__customer_full_name?.raw,
    code: result.customer_code?.raw,
    complianceLevel: "",
    socialCompliance: "",

    yearsWithLf: customerYearsWithLF(result),
    topCustomers: result["sales__supplier_cogs_agg.supplier_code"]?.raw.slice(
      0,
      5,
    ),
    topCustomersCalculated: customerCalculateTopCustomers(result),
    topProductCategories: customerTopProductCategory(result),
    topProductCategoryCogs: customerTopProductCategoryCogs(result),
    topProductCategoryCogValues: result["sales__cogs_per_category.cogs"]?.raw,
    topExportCountries: customerExportCountries(result),
    topExportCountriesCalculated: calculateTopExportCountries(result, source),
    productionCountry: result.demographic__country_name?.raw,
    topProductionCountries: calculateTopProductionCountries(result),
    customerPercent: [],
  };

  let newData;
  switch (source) {
    case "supplier":
      newData = supplierMap;
      supplierCalculatedTopCustomers(result);
      break;

    case "factory":
      newData = factoryMap;
      break;

    case "customer":
      newData = customerMap;
      break;
  }
  // Format data
  // Top Export Countries reduction
  if (newData.topExportCountries) {
    const country: any = newData.topExportCountries;
    newData.topExportCountryCodes = Array.isArray(
      newData.topExportCountriesCalculated,
    )
      ? newData.topExportCountriesCalculated.map((c) => {
          return reduceCountryName(c.country);
        })
      : reduceCountryName(country);
  } else newData.topExportCountries = ["--", "--", "--", "--", "--"];

  // NaN correction
  newData.yearsWithLf = isNaN(parseInt(newData.yearsWithLf))
    ? "--"
    : newData.yearsWithLf;

  return newData;
};

export const formatProductResult = (result: any) => {
  const productMap: ProductDataType = {
    division_code: result.division_code?.raw,
    family_code: result.family_code?.raw,
    description: result.description?.raw,
    number: result.number?.raw,
    item_description: result.item_description?.raw,
    item_number: result.item_number?.raw,
    item_run_number: result.item_run_number?.raw,
    operating_group: result.operating_group?.raw,
    product_category: result.product_category?.raw,
    product_category_code: result.product_category_code?.raw,
    product_category_group: result.product_category_group?.raw,
    product_category_group_code: result.product_category_group_code?.raw,
    product_family: result.product_family?.raw,
    product_id: result.product_id?.raw,
    product_type: result.product_type?.raw,
    product_type_code: result.product_type_code?.raw,
    avg_cost: result.sales__average_cost?.raw,
    avg_order_amount: result.sales__average_order_amount?.raw,
    sales__average_order_size: result.sales__average_order_size?.raw,
    sales__cogs: result.sales__cogs?.raw,
    color_description: result["sales__color.color_description"]?.raw,
    color_quantity: result["sales__color.color_quantity"]?.raw,
    customer: result.sales__customer?.raw,
    sales__fob: result.sales__fob?.raw,
    factory_code: result["sales__manufacturer.factory_code"]?.raw,
    supplier_code: result["sales__manufacturer.supplier_code"]?.raw,
    sales__po_count: result.sales__po_count?.raw,
    sales__quantity: result.sales__quantity?.raw,
    sales__shipment_count: result.sales__shipment_count?.raw,
    season: result.season?.raw,
    status_code: result.status_code?.raw,
    stream: result.stream?.raw,
    target_price: result.target_price?.raw,
    image_url: result.image_url?.raw,
  };

  productMap.target_price =
    productMap.target_price === 0 ? "-" : productMap.target_price;

  return productMap;
};

export interface PostTransactionType {
  user: string;
  method: string;
  path: string;
  response: string;
  accessedDate: number;
  payload: string;
}
