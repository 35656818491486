import { Spin } from "antd";
import { useEffect, useState } from "react";

interface ImageCollageComponentProps {
  entityId: string;
  getImages: (entity: string) => Promise<string[]>;
  maxImages?: number;
}

export const ImageCollageComponent = ({
  entityId,
  getImages,
  maxImages = 4,
}: ImageCollageComponentProps) => {
  const [images, setImages] = useState<string[]>([]);
  const defaultImage = process.env.PUBLIC_URL + "/fallbackImage.png";

  // Image logic
  useEffect(() => {
    const getCategoryImages = async () => {
      let images: string[] = await getImages(entityId);
      if (images.length === 0) {
        setImages([defaultImage]);
        return;
      }
      // Limit array by maxImages
      images = images.slice(0, Math.min(images.length, maxImages));

      // Limit by format max (2^x)
      const formatMax = Math.pow(2, images.length);
      images = images.slice(0, Math.min(images.length, formatMax));

      setImages(images);
    };

    getCategoryImages();
  }, [defaultImage, entityId, getImages, maxImages]);

  return images.length > 0 ? (
    <div className="rounded image-collage">
      {images.map((image: string) => {
        return (
          <img
            key={image}
            // style={{ width: `${100 / images.length}%` }}
            height={280}
            width={280}
            src={image}
            alt=""
          />
        );
      })}
    </div>
  ) : (
    <Spin></Spin>
  );
};
