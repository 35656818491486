import {Typography} from "antd";

export interface TopThreeMetricsData {
    key: string;
    value: string;
    percentage: string;
    cogsValue: string;
}

const { Text } = Typography;

export const GetMetricsColumns = (metricCol: { title: string, dataIndex: string, key: string}) => [
    {
        ...metricCol,
        // width: '10em'
    },
    {
        title: 'COGS',
        dataIndex: 'cogsValue',
        key: 'cogsValue',
        // width: '20px',
        render: (text: string) => <Text>{text === "0" ? '--' : `${parseFloat(text).toFixed(2)}`}</Text>
    },
    {
        title: 'Percentage',
        dataIndex: 'percentage',
        key: 'percentage',
        // width: '20px',
        render: (text: string) => <Text>{text === "0" ? '--' : `${text}%`}</Text>
    }
]
