import {useEffect, useState} from "react";
import {Button, Col, Layout, Row, Space, Typography} from "antd";
import {useTranslation} from "react-i18next";
import {NavBar} from "../components/NavBar";
import {ArrowLeftOutlined} from "@ant-design/icons";
import {useNavigate} from "react-router-dom";
import {CategoryGenerator} from "../components/CategoryGenerator";
import {ResultCard} from "../components/ResultCard";
import {formatFacilityResult, formatProductResult, ProductDataType, SourceType} from "../models/dataType";
import {getShortList} from "../services/ShortListService";
import {
    getCustomerClient,
    getFactoryClient,
    getProductClient,
    getSearchClient,
    getSupplierClient
} from "../services/SearchService";
import {ResultProductCard} from "../components/ResultProductCard";
import {useCompareLists} from "../contexts/CompareListContext";
import {useShortLists} from "../contexts/ShortListContext";
import {useMsalWrapper} from "../contexts/MSALContext";

const { Link } = Typography;

const categories = [
    "Suppliers",
    "Factories",
    "Products",
    "Customers",
    // "Compare",
];

const { Header, Content } = Layout;

const { Title } = Typography;

export const ShortListPage = () => {
    const [activeCategory, setActiveCategory] = useState("Suppliers");

    const navigate = useNavigate();

    const { t } = useTranslation();

    const [totalIds, setTotalIds] = useState<string[]>([])

    const [supplierIds, setSupplierIds] = useState<string[]>([])

    const [factoryIds, setFactoryIds] = useState<string[]>([])

    const [productIds, setProductIds] = useState<string[]>([])

    const [customerIds, setCustomerIds] = useState<string[]>([])

    const [listedItems, setListedItems] = useState<any[]>([])

    const [selectedItems, setSelectedItems] = useState<{type: string, code: string}[]>([])

    const { isItemCompare } = useCompareLists()
    
    const { deleteShortListId } = useShortLists()

    const { acquireToken } = useMsalWrapper()

    const [searchClient, setSearchClient] = useState<any>(getSearchClient)

    // Map item category to type category
    const categoryMap: { [index: string]: SourceType } = {
        Suppliers: "supplier",
        Factories: "factory",
        // Products: "product",
        Customers: "customer",
        // Compare: "compare"
    };

    // Map category types to corresponding id array & search client
    // This is to alternate between categories / tabs
    const categoryArrMap: { [index: string]: { arr: string[], client: any} } = {
        Suppliers: {arr: supplierIds, client: getSupplierClient()},
        Factories: {arr: factoryIds, client: getFactoryClient()},
        Products: {arr: productIds, client: getProductClient()},
        Customers: {arr: customerIds, client: getCustomerClient()}
    }

    // Set the current active category, search client, & id array
    const handleSetActiveCategory = (category?: any) => {
        setSelectedItems([])
        let currentCategory = activeCategory
        if (category) {
            currentCategory = category
        }

        setActiveCategory(currentCategory)

        const categoryMapped = categoryArrMap[currentCategory]

        setSearchClient(categoryMapped.client)

        return categoryMapped.arr
    };

    // Segregate short list id arr from api into
    // arr corresponding to each category type
    const selectCategoryList = (type: string, code: string, tempArr: {suppArr: any[], factArr: any[], prodArr: any[], custArr: any[]}) => {
        switch (type) {
            case "supplier":
                if (supplierIds.includes(code)) return
                tempArr.suppArr.push(code)
                break
            case "factory":
                if (factoryIds.includes(code)) return
                tempArr.factArr.push(code)
                break
            case "product":
                if (productIds.includes(code)) return
                tempArr.prodArr.push(code)
                break
            case "customer":
                if (customerIds.includes(code)) return
                tempArr.custArr.push(code)
        }
    }

    // Perform elastic search for each id in current category's id array
    const getShortItems = async (itemArr: string[]) => {
        const newResults: any[] = []

        const queryArr: any[] = []

        const options = { page: {size: 1, current: 1} }

        itemArr.map((itemId: string) => queryArr.push({query: itemId}))

        // I tried using multi-search but wasn't able to achieve exact match
        const newArr = itemArr.map(async (itemId: string): Promise<any[]> => {
            const result = await searchClient.search(itemId, options)

            newResults.push(result.rawResults[0])
            return result.rawResults[0]
        })

        Promise.all(newArr).then(() => {
            setListedItems(newResults)
        })
    }

    const removeItem = (code: string) => {
        const newArr = listedItems.filter((item) => {
            return !((item?.item_number?.raw && item.item_number.raw === code)
                || (item?.id?.raw && item.id.raw === code))})

        setListedItems(newArr)
    }

    // Acquire short list id array from API
    const getShortListed = async () => {
        const token = await acquireToken()
        if (!token) return

        const [tempSupplierId, tempFactoryId, tempProductId, tempCustomerId]: string[][] = [[],[],[],[]]

        const shortList = await getShortList(token)

        if (!shortList?.data) return

        let itemIdArr: string[] = []


        shortList.data.forEach((item: any) => {
            selectCategoryList(item.type, item.code, {suppArr: tempSupplierId, factArr: tempFactoryId, prodArr: tempProductId, custArr: tempCustomerId})
            itemIdArr.push(item.code)})

        setSupplierIds(tempSupplierId)
        setFactoryIds(tempFactoryId)
        setProductIds(tempProductId)
        setCustomerIds(tempCustomerId)

        return itemIdArr
    }

    // Fetch shortlist id array & set total id array
    useEffect(() => {
        getShortListed().then((response) => {
            if (!response) return
            setTotalIds(response)
        })
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])


    // On totalIds & activeCategory state mutation, handle setting active category & elastic search shortlisted items
    useEffect(() => {
        const itemArr = handleSetActiveCategory()
        if (!itemArr) return
        getShortItems(itemArr)
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [activeCategory, totalIds])


    const onSelectItem = (token: string, code: string, selected: boolean) => {
        if (selected) {
            setSelectedItems([...selectedItems, {type: token, code: code}])
            return
        }
        setSelectedItems(selectedItems.filter((item) => item.code !== code))
    }


    const onRemoveSelected = async () => {
        const token = await acquireToken();
        if (!token) return;

        let newArr = listedItems


        for (const selected of selectedItems) {
            await deleteShortListId(selected.type, selected.code);
            newArr = newArr.filter((item) => {
                return !((item?.item_number?.raw && item.item_number.raw === selected.code)
                    || (item?.id?.raw && item.id.raw === selected.code))})
        }

        setListedItems(newArr)
    };

    return (
        <Layout className="frame">
            <Header id="search-header">
                <NavBar />
            </Header>
            <Content className="content">
                <Row id="search-title" style={{marginBottom: 0}}>
                    <div id="box" />
                    <Title level={2}>
                        {t("Shortlist")}
                    </Title>
                </Row>
                <Row id="search-backlink">
                    <Link onClick={() => navigate(-1)}>
                        <Space>
                            <ArrowLeftOutlined />
                            Back to Search Results
                        </Space>
                    </Link>
                </Row>
                <Row className="categories">
                    {CategoryGenerator(
                        categories,
                        activeCategory,
                        handleSetActiveCategory,
                        listedItems.length ? listedItems.length : 0
                    )}
                </Row>
                <Row className="search-body" wrap={false}>

                    <Col className="results" flex={"auto"} style={{marginTop: 20}}>
                        <Button shape="round" size="small" style={{color: 'black', marginLeft: "auto"}}
                                disabled={selectedItems.length === 0} onClick={onRemoveSelected}
                        >
                            {t("Remove Items")}
                        </Button>
                        <>
                            {listedItems && listedItems.length > 0 &&
                                listedItems.map((result: any) => {
                                    if (!result) return null
                                    let item
                                    let compare
                                    const category = categoryMap[activeCategory]
                                    if (activeCategory !== 'Products') {
                                        item = formatFacilityResult(category, result)

                                        compare = isItemCompare(category, item.code)
                                    } else { item = formatProductResult(result) }

                                    return (activeCategory === 'Products' ?
                                            <ResultProductCard selectItem={onSelectItem} onShortListed={removeItem} shortListed={true} key={result?.id?.raw} data={item as ProductDataType}/>
                                            :
                                            <ResultCard selectItem={onSelectItem} isCompared={compare} onShortListed={removeItem} shortListed={true} key={result?.id?.raw} source={category} result={result}/>
                                    )
                                })
                            }
                        </>
                    </Col>
                </Row>
                {/*<Row id="search-paging">*/}
                {/*    <Paging />*/}
                {/*</Row>*/}
            </Content>
        </Layout>
    )
}
