import React from "react";
import {Col, Rate, Row, Typography} from "antd";

interface iRatingTextSpan {
    marginTop?: string,
    text: string,
    starSize?: number,
    defaultValue?: number,
    setRating?: (rating: number) => void
}

const { Text } = Typography;

export const RatingTextSpan: React.FC<iRatingTextSpan> = (props) => {
    return (
        <Row style={{marginTop: `${props.marginTop ? props.marginTop : '5px'}`, alignItems: 'center'}}>
            <Col style={{alignItems: 'center'}}>
                <Text>{props.text}</Text>
            </Col>
            <Col style={{marginLeft: 'auto'}}>
                <Rate
                    onChange={(e) => {
                        if (props.setRating) props.setRating(e)
                    }} allowHalf defaultValue={props.defaultValue ? props.defaultValue : 0} style={{ fontSize: props.starSize ? props.starSize : 20 }}/>
            </Col>
        </Row>
    )
}
