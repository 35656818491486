import React from "react";

import type { FieldValue } from "@elastic/search-ui";
import {FacetViewProps} from "@elastic/react-search-ui-views";
import {format} from "date-fns";
import appendClassName from "../utils/elasticUtils/appendClassName";
import getFilterValueDisplay from "../utils/elasticUtils/getFilterValueDisplay";

function DateMultiCheckboxFacet({
                                className,
                                label,
                                onMoreClick,
                                onRemove,
                                onSelect,
                                options,
                                showMore,
                                showSearch,
                                onSearch,
                                searchPlaceholder
                            }: FacetViewProps) {
    return (
        <fieldset className={appendClassName("sui-facet", className)}>
            <legend className="sui-facet__title">{label}</legend>

            {showSearch && (
                <div className="sui-facet-search">
                    <input
                        className="sui-facet-search__text-input"
                        type="search"
                        placeholder={searchPlaceholder || "Search"}
                        onChange={(e) => {
                            onSearch(e.target.value);
                        }}
                    />
                </div>
            )}

            <div className="sui-multi-checkbox-facet">
                {options.length < 1 && <div>No matching options</div>}
                {options.map((option) => {
                    const checked = option.selected;
                    const value = option.value as FieldValue;
                    return (
                        <label
                            key={`${getFilterValueDisplay(option.value)}`}
                            htmlFor={`example_facet_${label}${getFilterValueDisplay(
                                option.value
                            )}`}
                            className="sui-multi-checkbox-facet__option-label"
                        >
                            <div className="sui-multi-checkbox-facet__option-input-wrapper">
                                <input
                                    data-transaction-name={`facet - ${label}`}
                                    id={`example_facet_${label}${getFilterValueDisplay(
                                        option.value
                                    )}`}
                                    type="checkbox"
                                    className="sui-multi-checkbox-facet__checkbox"
                                    checked={checked}
                                    onChange={() => (checked ? onRemove(value) : onSelect(value))}
                                />
                                <span className="sui-multi-checkbox-facet__input-text">
                                    {getFilterValueDisplay(format(new Date(option.value as string), 'yyyy-MM-dd'))}
                                </span>
                            </div>
                            <span className="sui-multi-checkbox-facet__option-count">
                {option.count.toLocaleString("en")}
              </span>
                        </label>
                    );
                })}
            </div>

            {showMore && (
                <button
                    type="button"
                    className="sui-facet-view-more"
                    onClick={onMoreClick}
                    aria-label="Show more options"
                >
                    + More
                </button>
            )}
        </fieldset>
    );
}

export default DateMultiCheckboxFacet;
