import { Row, Col, Typography } from "antd";
import { useTranslation } from "react-i18next";

const { Title, Text } = Typography;

export const SearchErrorPage = ({resultSearchTerm}: {resultSearchTerm: string}) => {
  const { t } = useTranslation();

  return (
    <Row>
      <Col className="search-error">
        <Row>
          <img src={process.env.PUBLIC_URL + "/icons/sad.svg"} alt="" />
        </Row>
        <Row>
          <Title level={3}>{t("No Results Found")}</Title>
        </Row>
        <Row>
          <Text>
            {t("Seems like there are no results for ")}
            <Text style={{ color: "var(--red)" }} strong>
              {resultSearchTerm}
            </Text>
          </Text>
        </Row>
        <Row>
          <Text>
            {t(
              "Please try a different keyword or reset your filters and try again."
            )}
          </Text>
        </Row>
      </Col>
    </Row>
  );
};
