import { Row, Col, Typography, Tooltip } from "antd";
import { useTranslation } from "react-i18next";
import {useNavigate} from "react-router-dom";

const { Text } = Typography;

interface DataRowProps {
  text: string;
  data: any;
  tooltip?: string;
  navigation?: string;
  reverseBold?: boolean;
}

export const DataRow = ({ text, data, tooltip, navigation, reverseBold }: DataRowProps) => {
  const navigate = useNavigate();
  const { t } = useTranslation();
  return (
    <Row className="data-row" wrap={false}>
      <Col span={14} style={{maxWidth: '34vw'}}>
        <Tooltip title={tooltip ?? text}>
            {
                reverseBold ?
                    <Text type="secondary">{t(text)}</Text>
                    :
                    <Text strong>{t(text)}</Text>
            }
        </Tooltip>
      </Col>
      <Col>

          {navigation?
              // eslint-disable-next-line
              <a onClick={() => navigate({ pathname: navigation })} type="secondary" >{data}</a>
              :
              reverseBold ?
                  <Text >{data}</Text>
                  :
                  <Text type="secondary" >{data}</Text>
          }
      </Col>
    </Row>
  ) ;
};
