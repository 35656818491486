/* eslint-disable @typescript-eslint/no-unused-vars */
import { Row, Col, message, Button, Upload } from "antd";
import { useTranslation } from "react-i18next";

import { IconLink } from "../components/IconLink";
import { CustomSearchBox } from "../components/CustomSearchBox";
import { UserDropdown } from "../components/UserDropDown";
import { ChangelogPopover } from "../components/ChangelogPopover";
import React, { useState } from "react";
import { RcFile } from "antd/es/upload";
import { validateImage } from "../services/UploadUtils";
import { UploadOutlined } from "@ant-design/icons";
import { Link, useNavigate } from "react-router-dom";

export const LandingPage = () => {
  const { t } = useTranslation();

  const navigate = useNavigate();

  const [searchTerm, setSearchTerm] = useState<string>("");

  const [fileValid, setFileValid] = useState<boolean>(false);

  const beforeUpload = (file: RcFile) => {
    setFileValid(false);
    const [isJpg, isLt5M] = validateImage(file, "image/jpeg", 5);
    if (!isJpg) {
      message.error("Incorrect Image format. Only JPEG/JPG supported");
    }
    if (!isLt5M) {
      message.error("Image must smaller than 5MB");
    }
    if (isJpg && isLt5M) {
      setFileValid(true);
      message.success("Image uploaded successfully");
    }
    return isJpg && isLt5M;
  };

  const onChange = (file: any) => {
    if (file.status !== "uploading") {
      if (!fileValid) {
        console.error("File invalid");
        return;
      }
      navigate(`/image/search`, { state: { file } });
    }
  };

  return (
    <Col>
      <Row className="landing-top">
        <div id="landing-col">
          <Row id="icons-row">
            <Col span={8} flex={"auto"}>
              <img
                src={process.env.PUBLIC_URL + "/icons/LFLogo.svg"}
                alt="Li & Fung"
              />
            </Col>
            <Col className="icons" span={8}>
              <IconLink
                src={process.env.PUBLIC_URL + "/icons/warehouse.svg"}
                text="Suppliers"
                link="/suppliers"
              />
              <IconLink
                src={process.env.PUBLIC_URL + "/icons/factories.svg"}
                text="Factories"
                link="/factories"
              />
              <IconLink
                src={process.env.PUBLIC_URL + "/icons/box.svg"}
                text="Products"
                link="/image"
              />
              <IconLink
                src={process.env.PUBLIC_URL + "/icons/customers.svg"}
                text="Customers"
                link="/customers"
              />

              {/*<div style={{display: 'flex', height: 50}}>*/}
              {/*  <Upload beforeUpload={beforeUpload}*/}
              {/*          onChange={onChange}*/}
              {/*          showUploadList={false}>*/}
              {/*    <div className="icon-link" >*/}
              {/*      <a>*/}
              {/*        <img style={{marginBottom: -3}} className="rounded" src={process.env.PUBLIC_URL + "/icons/image.svg"} alt="Image"/>*/}
              {/*        <p> {t('Image')} </p>*/}
              {/*      </a>*/}
              {/*    </div>*/}
              {/*    <UploadOutlined style={{opacity: 0, height: 0, width: 0}}/>*/}
              {/*  </Upload>*/}
              {/*</div>*/}
            </Col>
          </Row>
          <Row>
            <CustomSearchBox />
          </Row>
          <Row id="landing-user">
            <ChangelogPopover />
            <UserDropdown />
          </Row>
        </div>
      </Row>
    </Col>
  );
};
