import {
  UnauthenticatedTemplate,
  AuthenticatedTemplate,
  useMsal,
  useIsAuthenticated,
} from "@azure/msal-react";
import { InteractionStatus } from "@azure/msal-browser";
import { AccountInfoContext } from "../contexts/AccountInfoContext";
import { LoginPage } from "./LoginPage";

import { Outlet, useLocation, useNavigate } from "react-router-dom";
import { SearchProvider } from "@elastic/react-search-ui";
import { searchConfig } from "../infrastructure/searchConnector";
import { MaintenancePage } from "./MaintenancePage";
import { useEffect, useState } from "react";

import { User, UserContext } from "../contexts/UserInfoContext";
import * as gtag from "../infrastructure/gtag";
import { getUser } from "../services/UserService";
import { IdTokenContext } from "../contexts/IdTokenContext";
import {ShortListProvider} from "../contexts/ShortListContext";
import {CompareListProvider} from "../contexts/CompareListContext";

export const RootPage = () => {
  const [user, setUser] = useState<User | null>(null);
  const [token, setToken] = useState("");
  const { instance, inProgress, accounts } = useMsal();
  // hide landing page if searching
  const maintenance = process.env.REACT_APP_MAINTENANCE_MODE === "true";
  const location = useLocation();
  const isAuthenticated = useIsAuthenticated();
  const navigate = useNavigate();

  useEffect(() => {
    const getUserProfile = async () => {
      if (isAuthenticated && inProgress === InteractionStatus.None) {
        const response = await instance.acquireTokenSilent({
          account: accounts[0],
          scopes: [],
        });

        const token = response.idToken;
        if (!token) return;
        try {
          const user: any = await getUser(token);
          setUser(user);
          setToken(token);
          return;
        } catch (error: any) {
          console.error(error);
          navigate("/backend-error");
        }
      }
    };
    if (accounts.length === 0) return;

    getUserProfile();
  }, [accounts, inProgress, instance, isAuthenticated, navigate]);

  // Google Analytics
  useEffect(() => {
    gtag.pageview(location.pathname);
  }, [location]);

  return (
    <>
      {maintenance ? (
        <MaintenancePage />
      ) : (
        <AccountInfoContext.Provider value={accounts[0]}>
          <UnauthenticatedTemplate>
            <LoginPage />
          </UnauthenticatedTemplate>

          <AuthenticatedTemplate>
            <IdTokenContext.Provider value={token}>
              <UserContext.Provider value={user}>
                <CompareListProvider>
                  <ShortListProvider>
                    <SearchProvider config={searchConfig}>
                      <Outlet />
                    </SearchProvider>
                  </ShortListProvider>
                </CompareListProvider>
              </UserContext.Provider>
            </IdTokenContext.Provider>
          </AuthenticatedTemplate>
        </AccountInfoContext.Provider>
      )}
    </>
  );
};
