import { Row, Col, Card, Button, Typography } from "antd";
import { DataType, SourceType } from "../models/dataType";
import { useTranslation } from "react-i18next";
import { titleCase } from "./PerformanceMetrics";

const { Text } = Typography;

interface ContactsProps {
  source: SourceType;
  data: DataType;
}

const AddressRow = (address: string) => {
  return (
    <Row
      key={address}
      gutter={10}
      align={"middle"}
      wrap={false}
      className="address-row"
    >
      <Col>
        <img src={process.env.PUBLIC_URL + "/icons/map-marker.svg"} alt="" />
      </Col>
      <Col>
        <Text type="secondary">{address}</Text>
      </Col>
    </Row>
  );
};

export const Contacts = ({ source, data }: ContactsProps) => {
  const { t } = useTranslation();

  return (
    <>
      {/*<Row className="product-searchbar">*/}
      {/*  <div className="product-searchbar-right">*/}
      {/*    <Button shape="round" className="export-button">*/}
      {/*      <img src={process.env.PUBLIC_URL + "/icons/download.svg"} alt="" />*/}
      {/*      {t("Export Contacts")}*/}
      {/*    </Button>*/}
      {/*  </div>*/}
      {/*</Row>*/}
      <Card className="card">
        <Row justify={"space-between"} wrap={false}>
          <Col span={8}>
            <Row gutter={[0, 16]} wrap={false}>
              <Text strong>{t("LF Contacts")}</Text>
            </Row>
          </Col>
          <Col span={8}>
            <Row gutter={[0, 16]}>
              <Text strong>
                {t(titleCase(source)) + " " + t("Contact Persons")}
              </Text>
            </Row>
          </Col>
          <Col span={8}>
            <Row gutter={[0, 16]}>
              <Text strong>{t(titleCase(source)) + " " + t("Address")}</Text>
              {data.address?.map((a) => AddressRow(a))}
            </Row>
          </Col>
        </Row>
      </Card>
    </>
  );
};
