import { createContext, useContext, useMemo, useState } from "react";
import { DataType } from "../models/dataType";

interface CompareListContextData {
  supplierListItems: DataType[];
  factoryListItems: DataType[];
  addComparedItem: (type: string, item: DataType) => void;
  deleteComparedItem: (type: string, code: string) => void;
  isItemCompare: (type: string, code: string) => boolean;
  getTotalLength: () => number;
}

interface iCompareListProviderProps {
  children: React.ReactNode;
}

const CompareListContext = createContext<CompareListContextData>(
  {} as CompareListContextData,
);

const useCompareLists = (): CompareListContextData => {
  return useContext(CompareListContext);
};

const CompareListProvider: React.FC<iCompareListProviderProps> = ({
  children,
}) => {
  const [supplierListItems, setSupplierListItems] = useState<DataType[]>([]);
  const [factoryListItems, setFactoryListItems] = useState<DataType[]>([]);

  const compareListContextValue: CompareListContextData = useMemo(() => {
    const isItemCompare = (type: string, code: string): boolean => {
      if (type === "supplier") {
        return supplierListItems.some((item) => item.code === code);
      }
      return factoryListItems.some((item) => item.code === code);
    };
    const addComparedItem = (type: string, item: DataType) => {
      if (isItemCompare(type, item.code)) return;

      if (type === "supplier" && supplierListItems.length < 3) {
        setSupplierListItems((prevItems) => [...prevItems, item]);
        return;
      }

      if (type === "factory" && factoryListItems.length < 3) {
        setFactoryListItems((prevItems) => [...prevItems, item]);
      }
    };

    const deleteComparedItem = (type: string, code: string) => {
      if (type === "supplier") {
        setSupplierListItems((prevItems) =>
          prevItems.filter((item) => item.code !== code),
        );

        return;
      }
      setFactoryListItems((prevItems) =>
        prevItems.filter((item) => item.code !== code),
      );
    };

    const getTotalLength = (): number => {
      return factoryListItems.length + supplierListItems.length;
    };

    return {
      supplierListItems,
      factoryListItems,
      addComparedItem,
      deleteComparedItem,
      isItemCompare,
      getTotalLength,
    };
  }, [supplierListItems, factoryListItems]);

  return (
    <CompareListContext.Provider value={compareListContextValue}>
      {children}
    </CompareListContext.Provider>
  );
};

export { CompareListProvider, useCompareLists };
