import {DataType, SourceType} from "../models/dataType";
import {Button, Card, Col, Divider, Image, Row, Space, Tag, Tooltip, Typography} from "antd";
import {useTranslation} from "react-i18next";
import {titleCase} from "./PerformanceMetrics";
import {DataRow} from "./DataRow";
import {createSearchParams, useNavigate} from "react-router-dom";

interface iCompareCardProps {
    source: SourceType,
    data: DataType,
    isShortListed?: boolean,
    onRemove: (type: string, code: string) => void,
    onShortList: (type: string, code: string) => Promise<void>
}

const { Text } = Typography;

export const CompareCard: React.FC<iCompareCardProps> = ({source, data, isShortListed, onRemove, onShortList}) => {

    const { t } = useTranslation();

    const navigate = useNavigate();

    const goToFacility = () => {
        if (!data) return;
        const params = { ps: "0", st: "" };
        navigate({
            pathname: `/${source}/${data.code}`,
            search: `?${createSearchParams(params)}`,
        });
    };

    return (
        <Card className="compare-card">
            <div style={{height: '250px'}}>
                <Row style={{marginBottom: '10px'}}>
                    <Col>
                        <Image
                            src={data.imageUrl + process.env.REACT_APP_SAS_TOKEN}
                            fallback={process.env.PUBLIC_URL + "/fallbackImage.png"}
                            className="rounded"
                            height={150}
                        />
                    </Col>
                </Row>
                <Row>
                    <Text strong>{data.name}</Text>
                    <Text italic>({data.code})</Text>
                </Row>
                <Row>
                    <Space>
                        <Text type="secondary">{"LF Database"}</Text>
                        <Text>{"Active"}</Text>
                    </Space>
                </Row>
            </div>
            <Divider className="divider" />
            <div style={{height: '100px'}}>
                <Row className="result-text-row" gutter={24}>
                    <Col>
                        <Text type="secondary">
                            {t("Social Compliance") + ": "}
                        </Text>
                        <Text strong>{data.socialCompliance}</Text>
                    </Col>
                    <Col>
                        <Tooltip title={"Technical Audit Score"}>
                            <Text type="secondary">{t("TA Score") + " "}</Text>
                        </Tooltip>
                        <Text strong>{data.auditScore}</Text>
                    </Col>
                    <Col>
                        <Text type="secondary">{t("LF Score") + " "}</Text>
                        <Text strong>{data.lfScore}</Text>
                    </Col>
                </Row>
            </div>
            <Divider className="divider" />
            <div style={{height: '560px'}}>
                <Text strong>{t('Top product categories') + ": "}</Text>
                <Row  className="product-tags">

                    {data?.topProductCategories?.length > 0 &&
                        data.topProductCategories.map((category) => <Tag>{category}</Tag>)
                    }
                </Row>
            </div>
            <Divider className="divider" />
            <div style={{height: '160px'}}>
                <DataRow
                    text="Production Countries"
                    data={data.productionCountry}
                />
                <DataRow text="No. of Factories" data={data.factories} />
                <Text strong>{t('Top export countries') + ": "}</Text>
                <Row>
                    <Col className="info">
                        <Text>
                            1. {titleCase(data.topExportCountries[0]) + " "} {data.countryPercent[0]}%
                        </Text>
                        <Text>
                            2. {titleCase(data.topExportCountries[1]) + " "} {data.countryPercent[1]}%
                        </Text>
                        <Text>
                            3. {titleCase(data.topExportCountries[2]) + " "} {data.countryPercent[2]}%
                        </Text>
                    </Col>
                </Row>
            </div>
            <Divider className="divider" />
            <div style={{height: '100px'}}>
                <Text strong>{t('Top 3 key customers') + ": "}</Text>
                <Row>
                    <Col className="info">
                        <Text>
                            1. {titleCase(data.topCustomers[0]) + " "} {data.customerPercent[0]}%
                        </Text>
                        <Text>
                            2. {titleCase(data.topCustomers[1]) + " "} {data.customerPercent[1]}%
                        </Text>
                        <Text>
                            3. {titleCase(data.topCustomers[2]) + " "} {data.customerPercent[2]}%
                        </Text>
                    </Col>
                </Row>
            </div>
            <Divider className="divider" />
            <div style={{height: '120px'}}>
                <DataRow text="Last Shipment Date" data={data.lastShipment} />
                <DataRow text="Business Volume w/ LF" data={data.businessVolLf}/>
                <DataRow
                    text="Business Volume Overall"
                    data={data.businessVolOverall}
                />
                <DataRow
                    text="Business Volume with LF"
                    data={data.businessVolLf}
                />
            </div>
            <Divider className="divider" />
            <DataRow text="Supplier Website" data={data.supplierWebsite} />
            <Divider className="divider" />
            <Row wrap={false} style={{justifyContent: "center", }}>
                <Button className="compare-button" shape="round" size="small" onClick={() => onRemove(source, data.code)}>
                    {t("Remove")}
                </Button>
                <Button className="compare-button" shape="round"
                        style={{ backgroundColor: isShortListed ? 'var(--red)' : '', color: isShortListed ? 'white' : 'black'}}
                        size="small" onClick={async () => onShortList(source, data.code)}>
                    {t("Shortlist")}
                </Button>
                <Button className="compare-view-button" shape="round" size="small" onClick={goToFacility}>
                    {t("View")}
                </Button>
            </Row>
        </Card>
    )
}


