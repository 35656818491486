import { getReq } from "./ApiCommonUtils";
import axios from "axios";

const getComments = async (
  token: string,
  page?: number,
  size?: number,
  subject?: string,
  objectType?: string,
) => {
  const url = process.env.REACT_APP_COMMENT_BACKEND;

  if (!url) return;

  const parameter = {
    page: page ? page : 0,
    size: size ? size : 10,
    ...(subject && { subject: subject }),
    ...(objectType && { objectType: objectType }),
  };

  return await getReq(token, `${url}`, undefined, parameter);
};

const postComment = async (
  token: string,
  subject: string,
  body: string,
  objectType: string,
  rating: number,
) => {
  const url = process.env.REACT_APP_COMMENT_BACKEND;

  if (!url) return;

  const response = await axios
    .post(
      url,
      {
        subject: subject,
        body: body,
        objectType: objectType,
      },
      { headers: { Authorization: "Bearer " + token } },
    )
    .then((response) => response)
    .catch((e) => console.error(e));

  if (!response) return;

  const objectId = response.data.id;

  await postRating(token, rating, objectId, objectType);

  return response;
};

const getRatings = async (token: string, type: string, id: string) => {
  const url = process.env.REACT_APP_RATING_BACKEND;

  if (!url) return;

  return await axios
    .get(`${url}/${type}/${id}`, {
      headers: {
        Authorization: "Bearer " + token,
      },
    })
    .then((response) => response)
    .catch((e) => console.error(e));
};

const postRating = async (
  token: string,
  value: number,
  objectId: string,
  objectType: string,
) => {
  const url = process.env.REACT_APP_RATING_BACKEND;

  if (!url) return;

  return await axios
    .post(
      url,
      {
        value: value,
        objectId: objectId,
        objectType: objectType,
      },
      { headers: { Authorization: "Bearer " + token } },
    )
    .then((response) => response)
    .catch((e) => console.error(e));
};

export { getComments, postComment, getRatings, postRating };
