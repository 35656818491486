import { Card, Col, Row, Typography } from "antd";
import { generateDocUrl } from "../services/DocumentService";
import { EntityDocument } from "../models/dataType";

interface iDocumentCardProps {
  document: EntityDocument
}

const formatBytes = (bytes: number) => {
  if (!bytes) return "0 Bytes";

  const k = 1024;
  const sizes = ["Bytes", "KB", "MB", "GB"];
  const i = Math.floor(Math.log(bytes) / Math.log(k));
  return `${parseFloat((bytes / Math.pow(k, i)).toFixed(2))} ${sizes[i]}`;
};

const { Text } = Typography;

export const DocumentCard: React.FC<iDocumentCardProps> = (props) => {
  const document = props.document
  const url = generateDocUrl(document);

  return (
    <Card style={{ height: "100px", width: "100%" }}>
      <Row>
        <Col>
          <img
            style={{ marginLeft: "-15px", marginRight: "16px" }}
            width={50}
            src={process.env.PUBLIC_URL + "/icons/document.svg"}
            alt={"document"}
          />
        </Col>
        <Col>
          <Text strong>{document.title}</Text>
          <Row style={{}}>
            <Text style={{ fontSize: "12px" }}>{formatBytes(document.size)}</Text>
            <Text
              style={{ fontSize: "12px", marginLeft: "5px" }}
              type="secondary"
            >
              {new Date(document.date).toUTCString()}
            </Text>
          </Row>
        </Col>
        <div style={{ marginLeft: "auto" }}>
          {url && (
            <a href={url} target="_blank" rel="noreferrer" download>
              <img
                width={30}
                src={process.env.PUBLIC_URL + "/icons/downloadGrey.svg"}
                alt={"download"}
              />
            </a>
          )}
        </div>
      </Row>
    </Card>
  );
};
