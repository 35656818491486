import { Layout, Row, Col, Typography, Spin } from "antd";
import { useTranslation } from "react-i18next";
import { NavBar } from "../components/NavBar";
import { useContext, useEffect, useState } from "react";
import {
  ErrorBoundary,
  Paging,
  PagingInfo,
  withSearch,
} from "@elastic/react-search-ui";
import { SearchFilters } from "../components/SearchFilters";
import { ResultCard } from "../components/ResultCard";
import { ResultProductCard } from "../components/ResultProductCard";
import { CategoryGenerator } from "../components/CategoryGenerator";
import {
  SourceType,
  formatProductResult,
  formatFacilityResult,
  PostTransactionType,
} from "../models/dataType";
import { SearchErrorPage } from "./SearchErrorPage";
import { SearchMaintenancePage } from "./SearchMaintenancePage";

import {
  Filter,
  FilterType,
  FilterValue,
  SearchResult,
} from "@elastic/search-ui";
import { useNavigate, useParams, useSearchParams } from "react-router-dom";
import { useShortLists } from "../contexts/ShortListContext";
import { isShortListed } from "../services/ShortListService";
import { useCompareLists } from "../contexts/CompareListContext";
import CustomPagingInfo from "../components/CustomPagingInfo";
import { UserContext } from "../contexts/UserInfoContext";
import { postTransaction } from "../services/TransactionService";
import { useMsalWrapper } from "../contexts/MSALContext";
const { Header, Content } = Layout;
const { Title } = Typography;

interface SearchProps {
  resultSearchTerm: string;
  setSearchTerm: (term: string) => void;
  results: SearchResult[];
  filters: Filter[] | undefined;
  setFilter: (
    name: string,
    value: FilterValue,
    type?: FilterType | undefined,
  ) => void;
  clearFilters: (except?: string[] | undefined) => void;
  totalResults: number;
  isLoading: boolean;
}

const categories = [
  "Suppliers",
  "Factories",
  "Products",
  "Customers",
  "Compare",
];

// TODO: Adjust category map for new filters
const categoryMap: { [index: string]: SourceType } = {
  Suppliers: "supplier",
  Factories: "factory",
  // Products: "product",
  Customers: "customer",
  // Compare: "compare"
};
const filterMap: { [index: string]: any } = {
  Suppliers: "supplier",
  Factories: "factory",
  Products: "product",
  Customers: "buyer",
  // Compare: "compare"
};

const filterToCategory: { [index: string]: any } = {
  supplier: "Suppliers",
  factory: "Factories",
  product: "Products",
  buyer: "Customers",
};

const SearchPrecontent = ({
  resultSearchTerm,
  setSearchTerm,
  results,
  filters,
  setFilter,
  clearFilters,
  totalResults,
  isLoading,
}: SearchProps) => {
  const { id } = useParams();
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [searchParams, setSearchParams] = useSearchParams();
  const [activeCategory, setActiveCategory] = useState(id ? id : "Suppliers");
  const [isLFEU, setIsLFEU] = useState<boolean | undefined>(undefined);
  const { t } = useTranslation();

  const navigate = useNavigate();

  const { shortListIds } = useShortLists();

  const user: any = useContext(UserContext);

  const { acquireToken } = useMsalWrapper();

  const { isItemCompare, getTotalLength, supplierListItems, factoryListItems } =
    useCompareLists();

  const [compareLength, setCompareLength] = useState<number>(getTotalLength);

  const handleSetActiveCategory = (category: any) => {
    setActiveCategory(category);
    if (category === "Compare") {
      navigate("/compare");
      return;
    }
    clearFilters();
    setFilter("entity_type", filterMap[category]);
    // clearFilters(["entity_type"]);
  };

  useEffect(() => {
    setIsLFEU(user?.divisions?.includes("lfeu") ?? false);
  }, [user]);

  useEffect(() => {
    setCompareLength(getTotalLength);
  }, [supplierListItems, factoryListItems, getTotalLength, setCompareLength]);

  useEffect(() => {
    const sendTransaction = async () => {
      const token = await acquireToken();

      if (!token || !user || isLFEU === undefined) return;

      const transaction: PostTransactionType = {
        user: user.token,
        method: "GET",
        path: "Search",
        response: "200",
        accessedDate: parseInt(Date.now().toString()),
        payload: JSON.stringify({
          searchTerm: resultSearchTerm,
          filters: filters,
          totalResults: totalResults,
        }),
      };

      await postTransaction(token, transaction);
    };

    if (isLFEU !== undefined) {
      sendTransaction();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [resultSearchTerm, filters, totalResults, user, isLFEU]);

  useEffect(() => {
    const term = searchParams.get("q");
    if (!term) return;

    setSearchTerm(term);

    if (filters?.length === 0) {
      setFilter("entity_type", filterMap[activeCategory]);
    }

    if (isLFEU) {
      if (activeCategory !== "Products") {
        setFilter("lf__LFEU", "true");
      } else {
        setFilter("operating_group", "LFEU");
      }
    } // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchParams, activeCategory, isLFEU, setFilter]);

  useEffect(() => {
    // Filter by URL
    const urlCategory = searchParams.get("c");
    if (urlCategory) {
      setFilter("entity_type", filterMap[urlCategory]);
      setActiveCategory(urlCategory);
      return;
    }

    if (filters) {
      const entity_filter = filters.find((f) => f.field === "entity_type");
      if (!entity_filter?.values) {
        setFilter("entity_type", filterMap[activeCategory]);
        return;
      }
      const filter = entity_filter.values[0] as string;
      const category = filterToCategory[filter];
      setActiveCategory(category);
    } else {
      setFilter("entity_type", filterMap[activeCategory]);
    } // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filters, searchParams]);

  useEffect(() => {
    if (activeCategory !== "Products" && isLFEU) {
      setFilter("lf__LFEU", "true");
    }

    if (activeCategory === "Products" && isLFEU) {
      setFilter("operating_group", "LFEU");
    }
  }, [isLFEU, activeCategory, setFilter]);

  return (
    <Content className="content">
      <ErrorBoundary>
        <Row id="search-title">
          <div id="box" />
          <Title level={2}>
            {t("Search Results for") + ` "${resultSearchTerm}"`}
          </Title>
        </Row>
        <Row className="categories">
          {CategoryGenerator(
            categories,
            activeCategory,
            handleSetActiveCategory,
            totalResults,
            compareLength,
          )}
        </Row>
        <Row id="search-paging" style={{ marginBottom: "-30px" }}>
          <PagingInfo view={CustomPagingInfo} />
          <Paging />
        </Row>
        <Row className="search-body" wrap={false}>
          <Col>
            <SearchFilters />
          </Col>
          <Col className="results" flex={"auto"}>
            {isLoading ? (
              <Spin tip="Loading..."></Spin>
            ) : totalResults > 0 ? (
              activeCategory !== "Compare" &&
              (activeCategory !== "Products" ? (
                <>
                  {results?.map((result: any) => {
                    const data = formatFacilityResult(
                      categoryMap[activeCategory],
                      result,
                    );
                    const isShortList = isShortListed(data.code, shortListIds);
                    const compare = isItemCompare(
                      categoryMap[activeCategory],
                      data.code,
                    );
                    return (
                      <ResultCard
                        source={categoryMap[activeCategory]}
                        result={result}
                        shortListed={isShortList}
                        isCompared={compare}
                        key={result.id.raw}
                      />
                    );
                  })}
                </>
              ) : (
                <>
                  {results?.map((result: any) => {
                    const itemNumber = result.item_number?.raw
                      ? result.item_number.raw
                      : result.item_number;
                    const isShortList = isShortListed(itemNumber, shortListIds);
                    return (
                      <ResultProductCard
                        data={formatProductResult(result)}
                        shortListed={isShortList}
                        key={result.id.raw}
                      />
                    );
                  })}
                </>
              ))
            ) : (
              <SearchErrorPage resultSearchTerm={resultSearchTerm} />
            )}
          </Col>
        </Row>
        <Row id="search-paging">
          <div />
          <Paging />
        </Row>
      </ErrorBoundary>
    </Content>
  );
};

const SearchContent = withSearch((SearchProps) => SearchProps)(
  SearchPrecontent,
);

export const SearchPage = () => {
  if (process.env.REACT_APP_MAINTENANCE_MODE === "true") {
    return <SearchMaintenancePage />;
  } else {
    return (
      <Layout className="frame">
        <Header className="header">
          <NavBar />
        </Header>
        <SearchContent />
      </Layout>
    );
  }
};
