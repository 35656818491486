import { useEffect, useState } from "react";
import { useLoaderData } from "react-router-dom";
import { getProductClient } from "../services/SearchService";
import { formatProductResult } from "../models/dataType";
import { ProductDetails } from "../components/ProductDetails";

export const productDetailLoader = ({ params }: { params: any }) => {
  const id = params.id;
  return id;
};

export const ProductDetailPage = () => {
  const [result, setResult] = useState(null);
  const id = useLoaderData();

  useEffect(() => {
    const client = getProductClient();
    const options = {
      search_fields: { product_id: {} },
    };
    client
      .search(id, options)
      .then((resultList: any) => {
        setResult(resultList.rawResults[0]);
      })
      .catch((error: any) => console.error(error));

  }, [id, result]);

  return <>{result && <ProductDetails data={formatProductResult(result)} />}</>;
};
