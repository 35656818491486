import { Filter } from "@elastic/search-ui";
import { SearchProvider, WithSearch } from "@elastic/react-search-ui";

import { searchConfig } from "../infrastructure/searchConnector";
import { SourceType } from "../models/dataType";
import { CategoryLayout } from "../components/CategoryLayout";
import {useParams} from "react-router-dom";

export const CategoryPage = ({
  activeCategory,
}: {
  activeCategory: SourceType | "product";
}) => {
  const { searchTerm } = useParams();

  const categoryFilterMap = {
    "supplier": "supplier",
    "customer": "buyer",
    "product": "product",
    "factory": "factory",
  }

  const categorySuppliersConfig = {
    ...searchConfig,
    searchQuery: {
      ...searchConfig.searchQuery,
      filters: [
        {
          field: "entity_type",
          values: [categoryFilterMap[activeCategory]],
          type: "all",
        } as Filter,
      ],
    },
  };

  return (
    <SearchProvider config={categorySuppliersConfig}>
      <WithSearch
        mapContextToProps={({ setSearchTerm }) => ({ setSearchTerm })}
      >
        {({ setSearchTerm}) => {
          setSearchTerm(searchTerm ? searchTerm : "");
          return <CategoryLayout activeCategory={activeCategory} />;
        }}
      </WithSearch>
    </SearchProvider>
  );
};
