import { Button, message, Typography, Upload } from "antd";
import { PlusOutlined } from "@ant-design/icons";
import { useTranslation } from "react-i18next";
import React, { useState } from "react";
import { RcFile } from "antd/es/upload";
import { validateImage } from "../services/UploadUtils";

const { Text } = Typography;

interface iImageSearchUploadProps {
  file?: File;
  setFile: (file: File) => void;
  onClick: () => void;
}

export const ImageSearchUpload: React.FC<iImageSearchUploadProps> = (props) => {
  const { t } = useTranslation();

  const [fileValid, setFileValid] = useState<boolean>(false);

  const beforeUpload = (file: RcFile) => {
    setFileValid(false);
    const [isJpg, isLt5M] = validateImage(file, "image/jpeg", 5);
    if (!isJpg) {
      message.error("Incorrect Image format. Only JPEG/JPG supported");
    }
    if (!isLt5M) {
      message.error("Image must smaller than 5MB");
    }
    if (isJpg && isLt5M) {
      setFileValid(true);
      message.success("Image uploaded successfully");
    }
    return isJpg && isLt5M;
  };

  const onChange = (file: any) => {
    if (file.status !== "uploading") {
      if (!fileValid) {
        return;
      }
      if (!file?.file?.originFileObj) return;
      props.setFile(file.file.originFileObj);

      // navigate(`/image/search`, { state: { file } })
    }
  };

  if (props.file) {
    URL.createObjectURL(props.file);
  }

  return (
    <div className="imageUpload">
      <Text style={{ color: "#515761" }} strong>
        Reference Image
      </Text>
      <Upload
        className="avatar-uploader"
        beforeUpload={beforeUpload}
        onChange={onChange}
        showUploadList={false}>
        <div className="imageUploadContainer">
          {props.file ? (
            <img
              alt="Uploaded image"
              src={URL.createObjectURL(props.file)}
              style={{
                height: "100%",
                width: "100%",
                borderRadius: "8px",
                objectFit: "cover",
              }}
            />
          ) : (
            <PlusOutlined style={{ fontSize: "50px", color: "#c5c5c5" }} />
          )}
        </div>
      </Upload>
      <Button
        shape="round"
        size="small"
        style={{
          backgroundColor: "var(--red)",
          color: "white",
          width: "150px",
          marginTop: "15px",
        }}
        onClick={props.onClick}>
        {t("SEARCH")}
      </Button>
    </div>
  );
};
