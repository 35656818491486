import AppSearchAPIConnector from "@elastic/search-ui-app-search-connector";
import { SearchDriverOptions } from "@elastic/search-ui";

const search_key = process.env.REACT_APP_SEARCH_KEY;
const engine = process.env.REACT_APP_SEARCH_ENGINE;
const endpoint = process.env.REACT_APP_SEARCH_ENDPOINT;

if (!(search_key && engine && endpoint)) {
  throw new Error("Missing Search Env Vars!");
}

const connector = new AppSearchAPIConnector({
  searchKey: search_key,
  engineName: engine,
  endpointBase: endpoint,
});

export const searchQueryOptions = {
  facets: {
    demographic__country_name: { type: "value" },
    demographic__location_code: { type: "value" },
    demographic__city_name: { type: "value" },
    "sales__export_country_agg.export_country": { type: "value" },
    product_family: { type: "value" },
    product_category: { type: "value" },
    product_category_group: { type: "value" },
    demographic__last_shipment_date: { type: "value" },
    demographic__FACTORY_TYPE: { type: "value" },
    compliance__social_compliance_rating: {
      type: "value",
      sort: { value: "asc" },
    },
    "compliance__social_compliance.A": {
      type: "range",
      ranges: [
        { from: 1, name: "Any" },
        { from: 1, to: 3, name: "Up to 5" },
        { from: 1, to: 7, name: "Up to 10" },
        { from: 1, to: 20, name: "Up to 20" },
      ],
    },
    "compliance__social_compliance.B": {
      type: "range",
      ranges: [
        { from: 1, name: "Any" },
        { from: 1, to: 3, name: "Up to 5" },
        { from: 1, to: 7, name: "Up to 10" },
        { from: 1, to: 20, name: "Up to 20" },
      ],
    },
    "compliance__social_compliance.C": {
      type: "range",
      ranges: [
        { from: 1, name: "Any" },
        { from: 1, to: 3, name: "Up to 5" },
        { from: 1, to: 7, name: "Up to 10" },
        { from: 1, to: 20, name: "Up to 20" },
      ],
    },
    "compliance__social_compliance.D": {
      type: "range",
      ranges: [
        { from: 1, name: "Any" },
        { from: 1, to: 3, name: "Up to 5" },
        { from: 1, to: 7, name: "Up to 10" },
        { from: 1, to: 20, name: "Up to 20" },
      ],
    },
    "compliance__social_compliance.UNRATED": {
      type: "range",
      ranges: [
        { from: 1, name: "Any" },
        { from: 1, to: 3, name: "Up to 5" },
        { from: 1, to: 7, name: "Up to 10" },
        { from: 1, to: 20, name: "Up to 20" },
      ],
    },
    "sales__product_category_cogs.product_category": {
      type: "value"
    }
  },
  disjunctiveFacets: [
    "demographic__country_name",
    "demographic__location_code",
    "compliance__social_compliance_rating",
  ],
  filters: [
    // {
    //   field: "entity_type", type: "all",values: ["supplier"]
    // } as Filter
  ],
};

const autocompleteQueryOptions = {
  results: {
    resultsPerPage: 3,
    result_fields: {
      // Add snippet highlighting within autocomplete suggestions
      customer_code: { snippet: { size: 100, fallback: true }, raw: {} },
      supplier_code: { snippet: { size: 100, fallback: true }, raw: {} },
      factory_code: { snippet: { size: 100, fallback: true }, raw: {} },
      product_id: { snippet: { size: 100, fallback: true }, raw: {} },
      product_category: { snippet: { size: 100, fallback: true }, raw: {} },
      demographic__factory_name: {
        snippet: { size: 100, fallback: true },
        raw: {},
      },
      demographic__customer_full_name: {
        snippet: { size: 100, fallback: true },
        raw: {},
      },
      demographic__customer_local_full_name: {
        snippet: { size: 100, fallback: true },
        raw: {},
      },
      demographic__supplier_full_name: {
        snippet: { size: 100, fallback: true },
        raw: {},
      },
      demographic__supplier_local_full_name: {
        snippet: { size: 100, fallback: true },
        raw: {},
      },
      demographic__country_name: {
        snippet: { size: 100, fallback: true },
        raw: {},
      },
    },
  },
  suggestions: {
    types: {
      // Limit query to only suggest based on "title" field
      documents: {
        fields: [
          "customer_code",
          "supplier_code",
          "factory_code",
          "product_id",
          "demographic__supplier_short_name",
          "demographic__supplier_full_name",
          "demographic__supplier_local_full_name",
          "demographic__country_name",
        ],
      },
    },
    // Limit the number of suggestions returned from the server
    size: 3,
  },
};

export const searchConfig: SearchDriverOptions = {
  alwaysSearchOnInitialLoad: false,
  apiConnector: connector,
  hasA11yNotifications: true,
  searchQuery: searchQueryOptions as any,
  autocompleteQuery: autocompleteQueryOptions,
};
