import { useEffect, useState } from "react";
import { useLoaderData, useNavigate } from "react-router-dom";
import { getSearchClient } from "../services/SearchService";
import { FacilityDetails } from "../components/FacilityDetails";

export const supplierDetailLoader = ({
  params,
  request,
}: {
  params: any;
  request: any;
}) => {
  const id = params.id;
  const category = params?.category;

  const url = new URL(request.url);
  const ps = url.searchParams.get("ps") === "1"; // transform into boolean
  const st = ps ? url.searchParams.get("st") : null;

  return { id, ps, st, category };
};

export const SupplierDetailPage = () => {
  const { id, ps, st, category } = useLoaderData() as any;
  const [result, setResult] = useState(null);

  const navigate = useNavigate();

  useEffect(() => {
    const client = getSearchClient();
    const options = {
      search_fields: { supplier_code: {} },
      // result_fields: searchQueryOptions.result_fields,
    };
    client
      .search(id, options)
      .then((resultList: any) => {
        if (resultList.rawResults.length == 0) {
          navigate("/not-found");
          return;
        }
        const filteredResultList = resultList.rawResults.filter(
          (result: any) => result.supplier_code?.raw === id,
        );
        if (filteredResultList.length == 0) {
          navigate("/not-found");
          return;
        }
        setResult(filteredResultList[0]);
      })
      .catch((error: any) => console.error(error));
  }, [id]);

  return (
    <>
      {result && (
        <FacilityDetails
          source={"supplier"}
          result={result}
          productFilter={ps ? st : null}
          productCategory={category}
        />
      )}
    </>
  );
};
