import axios from "axios";

const getImageByText = async (text: string, token: string) => {
  const url = process.env.REACT_APP_IMG_TEXT_SEARCH;

  if (!url || !token) return;

  const headers = { Authorization: `Bearer ${token}` };

  const response = await axios
    .post(
      url,
      {
        searchText: text,
      },
      { headers: headers },
    )
    .then((response) => {
      return response;
    })
    .catch((e) => console.error(e));

  if (!response) {
    return;
  }

  return response.data;
};

const getImageWithFile = async (
  fileName: string,
  fileType: string,
  fileObj: any,
  token: string,
) => {
  return new Promise<any>(async (resolve, reject) => {
    const reader = new FileReader();
    const file = fileObj;
    if (!file) {
      reject(new Error("File not found"));
      return;
    }
    reader.readAsDataURL(file);
    try {
      reader.onload = async () => {
        const base64String = reader.result;
        if (!base64String || typeof base64String !== "string") {
          reject(new Error("Invalid base64String"));
          return;
        }
        const base64Result = base64String.split(",")[1];
        if (!base64Result) {
          reject(new Error("Invalid base64Result"));
          return;
        }

        const response = await getImageByImage(
          base64Result,
          fileName,
          "jpg",
          fileType,
          token,
        );
        resolve(response);
      };
    } catch (e) {
      reject(new Error("Invalid file"));
    }
  });
};

const getImageByImage = async (
  base64: string,
  fileName: string,
  fileType: string,
  mimeType: string,
  token: string,
) => {
  const url = process.env.REACT_APP_IMG_IMG_SEARCH;

  if (!url || !token) return;

  const headers = { Authorization: `Bearer ${token}` };

  const response = await axios
    .post(
      url,
      {
        base64Content: base64,
        fileName: fileName,
        mimeType: mimeType,
        fileType: fileType,
      },
      { headers: headers },
    )
    .then((response) => {
      return response;
    })
    .catch((e) => console.error(e));

  if (!response) {
    return;
  }

  return response.data;
};

export { getImageByText, getImageByImage, getImageWithFile };
