import { useEffect, useState } from "react";
import { getProductClient } from "../services/SearchService";
import { Card, Spin } from "antd";
import { ImageCollageComponent } from "./ImageCollageComponent";

const { Meta } = Card;

interface ProductCategoriesProps {
  id: string;
}

const getCategoryImages = async (category: string) => {
  const client = getProductClient();
  const options = {
    search_fields: { product_category: {} },
    result_fields: { image_url: { raw: {} } },
  };

  const resultList = await client.search(category, options);
  const images = resultList.rawResults
    .filter((r: any) => r.image_url)
    .map((r: any) => r.image_url.raw + process.env.REACT_APP_SAS_TOKEN);

  return images;
};

export const ProductCategories = ({ id }: ProductCategoriesProps) => {
  const [categories, setCategories] = useState<any[]>();

  useEffect(() => {
    const client = getProductClient();
    const options = {
      search_fields: { sales__customer: {} },
      result_fields: { product_category: { raw: {} } },
    };

    const getProductCategories = async () => {
      const found: any[] = [];
      const resultList = await client.search(id, options);

      for (const result of resultList.rawResults) {
        const product_category = result.product_category.raw;

        if (!found.includes(product_category)) {
          found.push(product_category);
        }
      }
      setCategories(found);
    };

    getProductCategories();
  }, [id]);

  return categories ? (
    categories.length > 0 ? (
      <div className="product-grid">
        {categories.map((c) => (
          <Card
              style={{ width: 280 }}
            cover={
              <ImageCollageComponent
                entityId={c}
                getImages={getCategoryImages}
              />
              // <img
              //   src={process.env.PUBLIC_URL + "/fallbackImage.png"}
              //   alt=""
              //   className="rounded"
              // />
            }
            key={c}
          >
            <Meta title={c} />
          </Card>
        ))}
      </div>
    ) : (
      <p>No categories to show</p>
    )
  ) : (
    <Spin></Spin>
  );
};
