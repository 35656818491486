import {Typography, Card, Col, Row, Image, Tooltip, Table} from "antd";
import { DataType } from "../models/dataType";
import { createSearchParams, useNavigate } from "react-router-dom";
import {titleCase} from "./PerformanceMetrics";
import {GetMetricsColumns, TopThreeMetricsData} from "../utils/CommonUtils";

const { Text } = Typography;

interface FacilityCardProps {
  source: "supplier" | "factory";
  data: DataType;
}

export const FacilityCard = ({ source, data }: FacilityCardProps) => {
  const navigate = useNavigate();

  const goToFacility = () => {
    const params = { ps: "0", st: "" };
    navigate({
      pathname: `/${source}/${data.code}`,
      search: `?${createSearchParams(params)}`,
    });
  };

  const topProductCategoriesData: TopThreeMetricsData[] = data?.topProductCategories.slice(0, 3).map((value, index) => ({
    key: index.toString(),
    value: value,
    percentage: data.topProductCategoryCogs[index] ? data.topProductCategoryCogs[index] : '0',
    cogsValue: data.topProductCategoryCogValues[index] ? data.topProductCategoryCogValues[index] : '0'
  }))

  return (
    <Card onClick={goToFacility} className="clickable">
      <Row gutter={16}>
        <Col className="centered">
          <Image
            className="rounded"
            src={data.imageUrl + process.env.REACT_APP_SAS_TOKEN}
            fallback={process.env.PUBLIC_URL + "/fallbackImage.png"}
            width={150}
            height={100}
          />
        </Col>
        <Col>
          <Row>
            <Text strong>{data.name}</Text>
          </Row>
          <Row>
            <Col>
              <Tooltip title={"Country"}>
                <Text type="secondary">{"Country:" + " "}</Text>
                <Text >{titleCase(data.productionCountry)}</Text>
              </Tooltip>
            </Col>
          </Row>
          {/*<Row align={"middle"} gutter={10}>*/}
          {/*  <Col>*/}
          {/*    <Tooltip*/}
          {/*      className="tooltip"*/}
          {/*      title={data.topProductCategories[0]}*/}
          {/*    >{`${data.topProductCategories[0]} ${data.topProductCategoryCogs[0]}%`}</Tooltip>*/}
          {/*  </Col>*/}
          {/*  <Col>*/}
          {/*    <Tooltip*/}
          {/*      className="tooltip"*/}
          {/*      title={data.topProductCategories[1]}*/}
          {/*    >{`${data.topProductCategories[1]} ${data.topProductCategoryCogs[1]}%`}</Tooltip>*/}
          {/*  </Col>*/}
          {/*  <Col>*/}
          {/*    <Tooltip*/}
          {/*      className="tooltip"*/}
          {/*      title={data.topProductCategories[2]}*/}
          {/*    >{`${data.topProductCategories[2]} ${data.topProductCategoryCogs[2]}%`}</Tooltip>*/}
          {/*  </Col>*/}
          {/*</Row>*/}
        </Col>
      </Row>
      <Row>
        <Table style={{marginTop: 12, marginBottom: 12, width: '100%'}}
               pagination={false}
               columns={GetMetricsColumns({title: 'Top 3 Product Categories', dataIndex: 'value', key: 'value'})}
               dataSource={topProductCategoriesData}  />
      </Row>
    </Card>
  );
};
