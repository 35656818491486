import {Badge, Col, Popover, Typography} from "antd";
import {useTranslation} from "react-i18next";

import {useEffect, useState} from "react";
import ReactMarkdown from "react-markdown";



const { Text } = Typography;

export const ChangelogPopover = () => {

    const [markdownContent, setMarkdownContent] = useState('')

    const { t } = useTranslation();

    useEffect(() => {
        fetch(`${process.env.PUBLIC_URL}/markdown/NEW.md`)
            .then((response) => response.text())
            .then((data) => {
                setMarkdownContent(data);
            })
            .catch((error) => {
                console.error('Error fetching Markdown file:', error)
            });
    }, [])

    const content = (
        <div style={{maxHeight: '350px', padding: '10px', overflow: "auto"}}>
            <ReactMarkdown>
                {markdownContent}
            </ReactMarkdown>
        </div>
    );

    return (
        <Col flex="100px" style={{marginRight: "auto"}}>
            <Popover content={content}>
                <Badge offset={[12, 10]}>
                    <Text>{t("Whats new")}</Text>
                </Badge>
            </Popover>
        </Col>
    )
}
