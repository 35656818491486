import { Layout, Row, Space, Typography } from "antd";
import { ArrowLeftOutlined } from "@ant-design/icons";
import {useCallback, useEffect, useState} from "react";
import { ResultCard } from "./ResultCard";
import { CategoryGenerator } from "./CategoryGenerator";
import { NavBar } from "./NavBar";
import { useNavigate } from "react-router-dom";
import { ProductGrid } from "./ProductGrid";
import { useTranslation } from "react-i18next";
import { FacilityGrid } from "./FacilityGrid";
import { PerformanceMetrics } from "./PerformanceMetrics";
import { SourceType, formatFacilityResult } from "../models/dataType";
import { Contacts } from "./Contacts";
import { Relationships } from "./Relationships";
import {useCompareLists} from "../contexts/CompareListContext";
import {useShortLists} from "../contexts/ShortListContext";
import {isShortListed} from "../services/ShortListService";
import {CommentGrid} from "./CommentGrid";
import {Documents} from "./Documents";
const { Header, Content } = Layout;
const { Link } = Typography;

export const FacilityDetails = ({
  source,
  result,
  productFilter,
    productCategory
}: {
  source: Exclude<SourceType, "customer">;
  result: any;
  productFilter?: string;
  productCategory?: string
}) => {
  const [activeTab, setActiveTab] = useState("Products");
  const [tabNumber, setTabNumber] = useState(12);
  const [tabComponent, setTabComponent] = useState(0);

  const [isCompare, setIsCompare] = useState<boolean>(false)

  const [isShortList, setIsShortList] = useState<boolean>(false)

  const { isItemCompare } = useCompareLists()
  const { shortListIds } = useShortLists()

  const navigate = useNavigate();

  const { t } = useTranslation();

  const categories = [
    t("Products"),
    t("Performance"),
    t("Relationships"),
    t("Contacts"),
    t("Documents"),
    ...(productCategory ? [t("Related Suppliers")] : []),
    // t("Customers"),
    t("Comments"),
  ];

  // Differentiate based on facility source
  const facilityTab = source === "supplier" ? t("Factories") : t("Suppliers");
  categories.splice(1, 0, facilityTab);

  if (!result) {
    navigate('/backend-error')
  }

  const setShortStatus = useCallback(() => {
    try {
      const item = formatFacilityResult(source, result)
      if (!item) return
      const shortListed = isShortListed(item.code, shortListIds)
      setIsShortList(shortListed)
    } catch (e) {
      console.error(e)
    }
  }, [shortListIds, setIsShortList, result, source])

  const setCompareStatus = useCallback(() => {
    try {
      const item = formatFacilityResult(source, result)
      if (!item) return
      const compare = isItemCompare(source, item.code)
      setIsCompare(compare)
    } catch (e) {
      console.error(e)
    }
  }, [setIsCompare, isItemCompare, source, result])

  useEffect(() => {
    setShortStatus()
  }, [setShortStatus])

  useEffect(() => {
    setCompareStatus()
  }, [setCompareStatus])

  useEffect(() => {
    switch (activeTab) {
      case t("Products"):
        setTabComponent(
          result && (
            <ProductGrid
              source={source}
              data={formatFacilityResult(source, result)}
              search_term={productFilter ?? ""}
              setTabNumber={setTabNumber}
            />
          )
        );
        break;
      case t("Factories"):
      case t("Suppliers"):
        setTabComponent(
          result && (
            <FacilityGrid
              source={source === "supplier" ? "factory" : "supplier"}
              data={formatFacilityResult(source, result)}
              setTabNumber={setTabNumber}
            />
          )
        );
        break;
      case t("Performance"):
        setTabNumber(0);
        setTabComponent(
          result && (
            <PerformanceMetrics data={formatFacilityResult(source, result)} />
          )
        );
        break;
      case t("Relationships"):
        setTabNumber(0);
        setTabComponent(
          result && (
            <Relationships
              source={source}
              data={formatFacilityResult(source, result)}
              setTabNumber={setTabNumber}
            />
          )
        );
        break;
      case t("Documents"):
        setTabNumber(0);
        setTabComponent(
            result && (<Documents data={formatFacilityResult(source, result)}/>)
        );
        break;
      case t("Contacts"):
        setTabComponent(
          result && (
            <Contacts
              source={source}
              data={formatFacilityResult(source, result)}
            />
          )
        );
        break;
      case t("Comments"):
        setTabNumber(0);
        setTabComponent(
            result && (
                <CommentGrid source={source} data={formatFacilityResult(source, result)}/>
            )
        );
        break;
      case t("Related Suppliers"):
        setTabComponent(
            result && (
                <FacilityGrid
                    source={source === "supplier" ? "factory" : "supplier"}
                    data={formatFacilityResult(source, result)}
                    productCategory={productCategory}
                    setTabNumber={setTabNumber}
                />
            )
        )
    }
  }, [activeTab, productFilter, result, source, t]);

  return (
    <Layout className="frame">
      <Header id="search-header">
        <NavBar />
      </Header>
      <Content className="content">
        <Row id="search-backlink">
          <Link onClick={() => navigate(-1)}>
            <Space>
              <ArrowLeftOutlined />
              Back to Search Results
            </Space>
          </Link>
        </Row>
        <Row className="results">
          <ResultCard isCompared={isCompare} shortListed={isShortList} source={source} result={result} clickable={false} />
        </Row>
        <Row className="categories">
          {CategoryGenerator(categories, activeTab, setActiveTab, tabNumber)}
        </Row>
        <Row>{tabComponent}</Row>
      </Content>
    </Layout>
  );
};
