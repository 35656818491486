import { AxiosResponse } from "axios";
import axios from "axios";

const getReq = async (
  token: string,
  url: string,
  pagination?: { page?: number; size?: number } | undefined,
  params?: any,
) => {
  const parameter = pagination
    ? {
        page: pagination.page ? pagination.page : 0,
        size: pagination.size ? pagination.size : 10,
      }
    : {};

  const response: void | AxiosResponse = await axios
    .get(url, {
      params: params ? params : parameter,
      headers: {
        Authorization: "Bearer " + token,
      },
    })
    .then((response) => response)
    .catch((e) => console.error(e));

  return response;
};

export { getReq };
