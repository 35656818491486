import {Col, Layout, Row, Space, Typography} from "antd";
import {useCallback, useEffect, useState} from "react";
import {SourceType} from "../models/dataType";
import {NavBar} from "../components/NavBar";
import {ArrowLeftOutlined} from "@ant-design/icons";
import {CategoryGenerator} from "../components/CategoryGenerator";
import {useNavigate} from "react-router-dom";
import {useTranslation} from "react-i18next";
import {CompareCard} from "../components/CompareCard";
import {useCompareLists} from "../contexts/CompareListContext";
import {useShortLists} from "../contexts/ShortListContext";
import {isShortListed} from "../services/ShortListService";

const { Header, Content } = Layout;

const { Title } = Typography;

const { Link } = Typography;

const categories = [
    "Suppliers",
    "Factories"
]

export const ComparePage = () => {
    const [activeCategory, setActiveCategory] = useState("Suppliers");

    const navigate = useNavigate();

    const { t } = useTranslation();

    const { supplierListItems, factoryListItems, deleteComparedItem } = useCompareLists()

    const [activeList, setActiveList] = useState<any[]>(supplierListItems)

    const { shortListIds, addShortListId, deleteShortListId } = useShortLists()

    // Map item category to type category
    const categoryMap: { [index: string]: SourceType } = {
        Suppliers: "supplier",
        Factories: "factory",
        // Products: "product",
        Customers: "customer",
        // Compare: "compare"
    };

    const onRemoveCompare = (type: string, code: string) => {
        deleteComparedItem(type, code)
    }

    const onShortList = async (type: string, code: string) => {
        const isShortList = isShortListed(code, shortListIds)

        if (isShortList) {
            await deleteShortListId(type, code)
            return
        }

        await addShortListId(type, code)
    }

    const handleSetActiveCategory = (category?: any) => {
        let currentCategory = activeCategory
        if (category) {
            currentCategory = category
        }

        setActiveCategory(currentCategory)
        if (category === 'Suppliers') {
            setActiveList(supplierListItems)
            return
        }
        setActiveList(factoryListItems)
    };

    const handleSetCategory = useCallback((category?: any) => {
        let currentCategory = activeCategory
        if (category) {
            currentCategory = category
        }

        setActiveCategory(currentCategory)
        if (category === 'Suppliers') {
            setActiveList(supplierListItems)
            return
        }
        setActiveList(factoryListItems)
        return
    }, [activeCategory, factoryListItems, supplierListItems])

    useEffect(() => {
        handleSetCategory(activeCategory)
    }, [factoryListItems, supplierListItems, handleSetCategory, activeCategory])

    return (
        <Layout className="frame" >
            <Header id="search-header">
                <NavBar />
            </Header>
            <Content className="content">
                <Row id="search-title" style={{marginBottom: 0}}>
                    <div id="box" />
                    <Title level={2}>
                        {t("Compare")}
                    </Title>
                </Row>
                <Row id="search-backlink">
                    <Link onClick={() => navigate(-1)}>
                        <Space>
                            <ArrowLeftOutlined />
                            Back to Search Results
                        </Space>
                    </Link>
                </Row>
                <Row className="categories">
                    {CategoryGenerator(
                        categories,
                        activeCategory,
                        handleSetActiveCategory,
                        0
                    )}
                </Row>
                <Row style={{justifyItems: 'center', justifyContent: 'center'}} wrap={false}>
                    {activeList && activeList.length > 0 ?
                        (activeList.map((item) => {
                            const isShortList = isShortListed(item.code, shortListIds)
                            return <CompareCard isShortListed={isShortList} onShortList={onShortList} onRemove={onRemoveCompare} source={categoryMap[activeCategory]} data={item} />
                        }))
                        :
                        <Row>
                            <Col className="search-error" style={{marginTop: '10vh'}}>
                                <Row>
                                    <img src={process.env.PUBLIC_URL + "/icons/sad.svg"} alt="" />
                                </Row>
                                <Row>
                                    <Title level={3}>{t("No Results Found")}</Title>
                                </Row>
                            </Col>
                        </Row>
                    }
                </Row>
            </Content>
        </Layout>
    )
}