import { Card, Col, Row, Table, Typography } from "antd";
import { MapContainer, TileLayer, GeoJSON } from "react-leaflet";
import { useTranslation } from "react-i18next";
import "leaflet/dist/leaflet.css";
import { countriesGeo } from "../infrastructure/countries.geo";
import { DataType } from "../models/dataType";
import { ColumnsType } from "antd/es/table";

const { Text, Title } = Typography;

export const titleCase = (str: string) => {
  if (!str) {
    return "";
  }
  const words = str
    .split(" ")
    .map((c) => c[0]?.toUpperCase() + c.slice(1).toLowerCase());
  return words.join(" ");
};

export const dataIsAvailable = (value: string) => {
  if (value === "--" || value === "-" || !value) {
    return false;
  }
  return true;
};

interface MetricsData {
  key: string;
  Name: string;
  2024: string;
  2023: string;
  2022: string;
  2021: string;
  2020: string;
}

interface TopFiveMetricsData {
  key: string;
  value: string;
  percentage: string;
}

const GetMetricsColumns = (metricCol: {
  title: string;
  dataIndex: string;
  key: string;
}) => [
  {
    ...metricCol,
    width: "50em",
  },
  {
    title: "Percentage",
    dataIndex: "percentage",
    key: "percentage",
    render: (text: string) => <Text>{text === "0" ? "--" : `${text}%`}</Text>,
  },
];

export const PerformanceMetrics = ({ data }: { data: DataType }) => {
  const { t } = useTranslation();

  const formatNumber = (num: string): string => {
    if (!num || typeof num !== "string" || num === "--") return "--";

    const numFloat = parseFloat(num.replaceAll(",", ""));
    // const formatNum = numFloat % 1 !== 0 ? numFloat.toFixed(2) : numFloat.toFixed(0)
    if (numFloat < 0) return "--";
    const formatNum = numFloat.toFixed(2);

    const regex = /\B(?=(\d{3})+(?!\d))/g;
    return formatNum.replace(regex, ",");
  };

  const columns: ColumnsType<MetricsData> = [
    {
      title: "Name",
      dataIndex: "Name",
      key: "Name",
    },
    {
      title: "2024",
      dataIndex: "2024",
      key: "2024",
    },
    {
      title: "2023",
      dataIndex: "2023",
      key: "2023",
    },
    {
      title: "2022",
      dataIndex: "2022",
      key: "2022",
    },
    {
      title: "2021",
      dataIndex: "2021",
      key: "2021",
    },
    {
      title: "2020",
      dataIndex: "2020",
      key: "2020",
    },
  ];

  const dataSource = [
    {
      key: "COGS",
      Name: "COGS",
      "2024": formatNumber(data.cogs[0]),
      "2023": formatNumber(data.cogs[1]),
      "2022": formatNumber(data.cogs[2]),
      "2021": formatNumber(data.cogs[3]),
      "2020": formatNumber(data.cogs[4]),
    },
    {
      key: "Average Order Amount",
      Name: "Average Order Amount",
      "2024": formatNumber(data.avgOrderAmount[0]),
      "2023": formatNumber(data.avgOrderAmount[1]),
      "2022": formatNumber(data.avgOrderAmount[2]),
      "2021": formatNumber(data.avgOrderAmount[3]),
      "2020": formatNumber(data.avgOrderAmount[4]),
    },
    {
      key: "Average Order Quantity",
      Name: "Average Order Quantity",
      "2024": formatNumber(data.avgOrderQuantity[0]),
      "2023": formatNumber(data.avgOrderQuantity[1]),
      "2022": formatNumber(data.avgOrderQuantity[2]),
      "2021": formatNumber(data.avgOrderQuantity[3]),
      "2020": formatNumber(data.avgOrderQuantity[4]),
    },
    {
      key: "Average Lead Time",
      Name: "Average Lead Time",
      "2024": formatNumber(data.avgLeadTime[0]),
      "2023": formatNumber(data.avgLeadTime[1]),
      "2022": formatNumber(data.avgLeadTime[2]),
      "2021": formatNumber(data.avgLeadTime[3]),
      "2020": formatNumber(data.avgLeadTime[4]),
    },
    {
      key: "Average PM Cost",
      Name: "Average PM Cost",
      "2024": formatNumber(data.avgPmCost[0]),
      "2023": formatNumber(data.avgPmCost[1]),
      "2022": formatNumber(data.avgPmCost[2]),
      "2021": formatNumber(data.avgPmCost[3]),
      "2020": formatNumber(data.avgPmCost[4]),
    },
    {
      key: "No. of Shipments",
      Name: "No. of Shipments",
      "2024": formatNumber(data.shipmentNo[0]),
      "2023": formatNumber(data.shipmentNo[1]),
      "2022": formatNumber(data.shipmentNo[2]),
      "2021": formatNumber(data.shipmentNo[3]),
      "2020": formatNumber(data.shipmentNo[4]),
    },
    {
      key: "Quantity Shipped",
      Name: "Quantity Shipped",
      "2024": formatNumber(data.quantityShipped[0]),
      "2023": formatNumber(data.quantityShipped[1]),
      "2022": formatNumber(data.quantityShipped[2]),
      "2021": formatNumber(data.quantityShipped[3]),
      "2020": formatNumber(data.quantityShipped[4]),
    },
    ...(data.fob &&
    data.fob[0] !== "--" &&
    data.fob[1] !== "--" &&
    data.fob[2] !== "--"
      ? [
          {
            key: "FOB Cost",
            Name: "FOB Cost",
            "2024": formatNumber(data.fob[0]),
            "2023": formatNumber(data.fob[1]),
            "2022": formatNumber(data.fob[2]),
            "2021": formatNumber(data.fob[3]),
            "2020": formatNumber(data.fob[4]),
          },
        ]
      : []),
    {
      key: "Quality Pass Rate",
      Name: "Quality Pass Rate",
      "2024": formatNumber(data.qualityClaim[0]) + "%",
      "2023": formatNumber(data.qualityClaim[1]) + "%",
      "2022": formatNumber(data.qualityClaim[2]) + "%",
      "2021": formatNumber(data.qualityClaim[3]) + "%",
      "2020": formatNumber(data.qualityClaim[4]) + "%",
    },
    {
      key: "On Time Delivery Rate",
      Name: "On Time Delivery Rate",
      "2024": formatNumber(data.deliveryRate[0]) + "%",
      "2023": formatNumber(data.deliveryRate[1]) + "%",
      "2022": formatNumber(data.deliveryRate[2]) + "%",
      "2021": formatNumber(data.deliveryRate[3]) + "%",
      "2020": formatNumber(data.deliveryRate[4]) + "%",
    },
  ];

  const getColor = (value: any) => {
    return value > 2000
      ? "#FF546D"
      : value > 1700
      ? "#FF6767"
      : value > 1500
      ? "#FF7961"
      : value > 1240
      ? "#FF8C5B"
      : value > 1000
      ? "#FF9E55"
      : "#BBBBBB";
  };

  // TODO: get color based on FOB distribution
  const style = (feature: any) => {
    return {
      fillColor: getColor(feature.properties.value),
      weight: 1,
      opacity: 0.7,
      color: "white",
      fillOpacity: 0.7,
    };
  };

  const countriesValue: any = countriesGeo.features.map((feature) => {
    // Assign alternate codes to comply with GeoJSON
    const altCode: { [key: string]: string } = {
      UK: "GBR",
      HKG: "CHN",
    };

    const countries = data.topExportCountryCodes.map((c: string) => {
      return altCode[c] ?? c;
    });
    if (countries.includes(feature.id)) {
      const props = { ...feature.properties, value: 999999 };
      return { ...feature, properties: props };
    }
    return { ...feature, value: 0 };
  });

  const formatDataResult = (value: string) => {
    if (!dataIsAvailable(value) || value === "0") return "--";
    return value;
  };

  const topProductCategoriesData: TopFiveMetricsData[] =
    data?.topProductCategories
      .map((value, index) => ({
        key: index.toString(),
        value: value,
        percentage: data?.topProductCategoryCogs[index],
      }))
      .slice(0, 5);

  const topExportCountriesData: TopFiveMetricsData[] =
    data?.topExportCountriesCalculated
      .map((value, index) => ({
        key: index.toString(),
        value: value.country,
        percentage: value.cogPercent,
      }))
      .slice(0, 5);

  const topCustomersData: TopFiveMetricsData[] = data?.topCustomersCalculated
    .map((value, index) => ({
      key: index.toString(),
      value: value.customer,
      percentage: value.percent,
    }))
    .slice(0, 5);

  return (
    <>
      <Card className="card">
        <Row wrap={false}>
          <Col span={12}>
            <Row>
              <Title level={3} className="info-title">
                {t("Metrics")}
              </Title>
            </Row>
            <Row className="result-info">
              <Table
                columns={columns}
                dataSource={dataSource}
                pagination={false}
              />
            </Row>

            <div>
              <Table
                style={{ marginTop: 12 }}
                dataSource={topProductCategoriesData}
                columns={GetMetricsColumns({
                  title: "Top 5 Product Categories (Based on COGS)",
                  dataIndex: "value",
                  key: "value",
                })}
                pagination={false}
              />
              <Table
                style={{ marginTop: 12 }}
                dataSource={topExportCountriesData}
                columns={GetMetricsColumns({
                  title: "Top 5 Export Countries/Region (Based on COGS)",
                  dataIndex: "value",
                  key: "value",
                })}
                pagination={false}
              />
              <Table
                style={{ marginTop: 12 }}
                dataSource={topCustomersData}
                columns={GetMetricsColumns({
                  title: "Top 5 Customers (Based on FOB)",
                  dataIndex: "value",
                  key: "value",
                })}
                pagination={false}
              />
            </div>
          </Col>
          <Col flex={"auto"} span={12} style={{ marginLeft: "15em" }}>
            <Row>
              <Title level={3} className="info-title">
                {t("Distribution by FOB")}
              </Title>
            </Row>
            <Row id="map">
              <MapContainer
                center={[20, 0]}
                zoom={1.3}
                zoomSnap={0}
                scrollWheelZoom={false}
                attributionControl={false}>
                <TileLayer
                  url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
                  noWrap={true}
                  opacity={0}
                />
                <GeoJSON data={countriesValue} style={style} />
              </MapContainer>
            </Row>
          </Col>
        </Row>
      </Card>
    </>
  );
};
