import { Button, Card, Modal, Row, Tag, Typography } from "antd";
import { PostTransactionType, ProductDataType } from "../models/dataType";
import { useContext, useState } from "react";
import { DetailPopup } from "./DetailPopup";
import { InfoCircleOutlined } from "@ant-design/icons";
import { postTransaction } from "../services/TransactionService";
import { useMsalWrapper } from "../contexts/MSALContext";
import { useNavigate } from "react-router-dom";
import { UserContext } from "../contexts/UserInfoContext";
import { useTranslation } from "react-i18next";
import { useShortLists } from "../contexts/ShortListContext";
import { toast } from "react-toastify";

const { Meta } = Card;
const { Text } = Typography;

interface ProductGridCardProps {
  data: ProductDataType;
  shortListed: boolean;
  onShortListed?: (code: string) => void;
}
export const ProductGridCard = ({
  data,
  shortListed,
  onShortListed,
}: ProductGridCardProps) => {
  const [quicklook, setQuicklook] = useState(false);

  const { t } = useTranslation();

  const navigate = useNavigate();

  const user: any = useContext(UserContext);

  const { acquireToken } = useMsalWrapper();

  const { addShortListId, deleteShortListId } = useShortLists();

  const handleQuicklook = () => {
    setQuicklook(true);
  };

  const onShortList = async () => {
    // if (!data?.item_number) return;

    // if (onShortListed) {
    //   onShortListed(data.item_number);
    // }

    if (shortListed) {
      await deleteShortListId("product", data.item_number);
      return;
    }

    await addShortListId("product", data.item_number);
  };

  const onImageSearch = async () => {
    try {
      const imageResponse = await fetch(
        `${data.image_url}${process.env.REACT_APP_SAS_TOKEN}&xyz=${Date.now()}`,
        {
          method: "GET",
          mode: "cors",
          cache: "no-store",
          headers: {
            "Content-Type": "application/json",
          },
        },
      );
      // @ts-ignore
      // const img = new Image();
      // img.crossOrigin = 'Anonymous';
      //
      // img.onload = () => {
      //   console.log('onload')
      //   const canvas = document.createElement('canvas');
      //   canvas.width = img.width;
      //   canvas.height = img.height;
      //   const context = canvas.getContext('2d');
      //   console.log('context')
      //   console.log(context)
      //   if (context) {
      //     context.drawImage(img, 0, 0, img.width, img.height);
      //
      //     const base64 = canvas.toDataURL('image/jpeg'); // You can specify the desired image format
      //     console.log('yooo')
      //     console.log(base64)
      //   }
      // };

      // const reader= new FileReader()
      // //
      // const imgElement = document.getElementById('productThumbnail') as HTMLImageElement;
      // if (imgElement) {
      //   // Create a canvas element
      //   const canvas = document.createElement('canvas');
      //   const context = canvas.getContext('2d');
      //
      //   // Set the canvas dimensions to match the image
      //   canvas.width = 150;
      //   canvas.height = 150;
      //   if (context) {
      //     // Draw the image on the canvas
      //     context.drawImage(imgElement, 0, 0, 150, 150);
      //
      //     // Convert the canvas content to a base64 string (PNG format in this example)
      //     const base64String = canvas.toDataURL('image/png');
      //     console.log(base64String);
      //   }
      //
      // }

      //
      // const  xhr=new XMLHttpRequest();
      // console.log('wa')
      // xhr.open("GET", data.image_url + process.env.REACT_APP_SAS_TOKEN, true);
      // xhr.responseType = 'blob';
      // console.log('yo')
      // xhr.onload = function(e) {
      //     console.log('onload')
      //     console.log(this.response)
      //     const blob = this.response;
      //     reader.readAsDataURL(blob);
      //     reader.onloadend = function() {
      //         const base64data = reader.result;
      //         console.log(base64data);
      //     }
      // }

      // reader.readAsDataURL()
      //
      // img.src = data.image_url + process.env.REACT_APP_SAS_TOKEN;
      // console.log(img)
      const imageType = "image/jpeg";
      const blob = await imageResponse.blob();

      const fileObj = new File([blob], data.item_number, { type: imageType });
      const file = {
        file: {
          lastModified: fileObj.lastModified,
          name: fileObj.name,
          originFileObj: fileObj,
          type: fileObj.type,
        },
      };
      navigate(`/image/search`, { state: { file: file } });
    } catch (e) {
      console.error(e);
      toast.error("Image unavailable at this time");
    }
  };

  const goToProduct = async () => {
    const token = await acquireToken();

    if (!token || !user) return;

    const transaction: PostTransactionType = {
      user: user.token,
      method: "GET",
      path: "Detail - product",
      response: "200",
      accessedDate: parseInt(Date.now().toString()),
      payload: JSON.stringify({ id: data.product_id }),
    };

    await postTransaction(token, transaction);

    navigate(`/product/${data.item_run_number}`);
  };

  const image = data.image_url
    ? data.image_url + process.env.REACT_APP_SAS_TOKEN
    : process.env.PUBLIC_URL + "/fallbackImage.png";
  return (
    <>
      <Card
        cover={
          <img
            src={image}
            alt={data.description}
            height={250}
            width={150}
            className="product-img"
          />
        }
        className="product-card">
        <Row
          style={{ marginBottom: 20 }}
          className="card-buttons-small"
          wrap={true}>
          <Button
            size="small"
            style={{
              backgroundColor: shortListed ? "var(--red)" : "",
              color: shortListed ? "white" : "black",
            }}
            onClick={onShortList}>
            {t("Shortlist")}
          </Button>
          <Button size="small" onClick={goToProduct}>
            {t("View Product")}
          </Button>
          <Button size="small" onClick={onImageSearch}>
            {t("Find Similar")}
          </Button>
          <Button size="small" onClick={handleQuicklook}>
            <InfoCircleOutlined />
          </Button>
        </Row>
        <Meta
          title={data.product_id}
          description={
            <>
              {/* <Row>
              <Text>US ${data.target_price}</Text>
            </Row> */}
              <Row>
                <Text type="secondary">{data.item_description}</Text>
              </Row>

              <Row style={{ marginTop: "1px" }}>
                <Text type="secondary">
                  Order Size: <b>{data.sales__quantity}</b>
                </Text>
              </Row>
              {/* <Row>
              <Text type="secondary">Export Countries: 1 2 3</Text>
            </Row> */}
              <Row className="product-tags" style={{ marginTop: "10px" }}>
                <Tag>{data.product_category_group}</Tag>
                <Tag>{data.product_category}</Tag>
                <Tag>{data.product_family}</Tag>
              </Row>
            </>
          }
        />
      </Card>
      <Modal
        mask={false}
        open={quicklook}
        onCancel={() => setQuicklook(false)}
        footer={null}
        width={750}>
        <DetailPopup image={true} details={data} imageUrl={image} />
      </Modal>
    </>
  );
};
