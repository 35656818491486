import { ErrorBoundary, SearchBox } from "@elastic/react-search-ui";
import "../App.css";
import { Col, Row, Typography } from "antd";
import { useTranslation } from "react-i18next";
import { Link, useNavigate } from "react-router-dom";

const { Title } = Typography;

export const CustomSearchBox = () => {
  const { t } = useTranslation(); // eslint-disable-line
  const navigate = useNavigate();

  const engineFieldMap: any = {
    "factory-engine": "demographic__factory_name",
    "customer-engine": "demographic__customer_full_name",
    "supplier-engine": "demographic__supplier_full_name",
    "product-engine": "product_id",
    "factory-engine-dev": "demographic__factory_name",
    "customer-engine-dev": "demographic__customer_full_name",
    "supplier-engine-dev": "demographic__supplier_full_name",
    "product-engine-dev": "product_id",
  };
  const engineCategoryMap: any = {
    "factory-engine": "Factories",
    "customer-engine": "Customers",
    "supplier-engine": "Suppliers",
    "product-engine": "Products",
    "factory-engine-dev": "Factories",
    "customer-engine-dev": "Customers",
    "supplier-engine-dev": "Suppliers",
    "product-engine-dev": "Products",
  };

  const engineIconMap: any = {
    "supplier-engine": "warehouse.svg",
    "factory-engine": "factories.svg",
    "customer-engine": "customers.svg",
    "product-engine": "box.svg",
    "supplier-engine-dev": "warehouse.svg",
    "factory-engine-dev": "factories.svg",
    "customer-engine-dev": "customers.svg",
    "product-engine-dev": "box.svg",
  };

  return (
    <div className={"search-box"}>
      <ErrorBoundary>
        <SearchBox
          searchAsYouType={false}
          autocompleteSuggestions={{ sectionTitle: "Suggested Queries" }}
          onSubmit={(searchTerm) => {
            if (!searchTerm) return;
            navigate(`/search?q=${searchTerm}`);
          }}
          autocompleteResults={{
            sectionTitle: "Suggested Results",
            titleField: "id",
            urlField: "id",
          }}
          inputView={({ getAutocomplete, getInputProps }) => (
            <div className={"sui-search-box__wrapper"}>
              <input
                {...getInputProps()}
                {...getInputProps({
                  placeholder: "Looking for products, suppliers, or factories?",
                })}
              />
              {getAutocomplete()}
            </div>
          )}
          autocompleteView={({
            autocompletedResults,
            autocompletedSuggestions,
            getItemProps,
          }) => {
            return (
              <div className="sui-search-box__autocomplete-container">
                {autocompletedSuggestions.documents.length > 0 && (
                  <>
                    <Row>
                      <Title
                        type="secondary"
                        level={5}
                        style={{ marginTop: "8px" }}
                      >
                        {"SUGGESTIONS"}
                      </Title>
                    </Row>
                    <Row>
                      <Col className="autocomplete-col" flex={"1 0 auto"}>
                        {autocompletedSuggestions.documents.map(
                          (result: any, i) => {
                            return (
                              <Row
                                className="autocomplete-row"
                                key={result["suggestion"]}
                              >
                                <div
                                  className="clickable"
                                  {...getItemProps({
                                    key: result["suggestion"],
                                    index: i,
                                    item: result,
                                  })}
                                  onClick={() => {
                                    navigate(
                                      `/search?q=${result["suggestion"]}`
                                    );
                                  }}
                                >
                                  {result["suggestion"]}
                                </div>
                              </Row>
                            );
                          }
                        )}
                      </Col>
                    </Row>
                  </>
                )}
                <Row>
                  <Title
                    type="secondary"
                    level={5}
                    style={{ marginTop: "8px" }}
                  >
                    {"AUTOCOMPLETE RESULTS"}
                  </Title>
                </Row>
                <Row gutter={8}>
                  <Col className="autocomplete-col" flex={"1 0 auto"}>
                    {autocompletedResults.map((result, i) => {
                      const titleField = engineFieldMap[result._meta.engine];
                      const category = engineCategoryMap[result._meta.engine];

                      const data = `<div class="autocomplete-span"><div class="span-left"><img style="width:20px" src=${
                        process.env.PUBLIC_URL +
                        "/icons/" +
                        engineIconMap[result._meta.engine]
                      } /> <div>${
                        result[titleField].snippet
                      }</div></div><div>(<b>${category}</b>)</div></div> `;
                      return (
                        <Row className="autocomplete-row" key={result.id.raw}>
                          <Link
                            className="clickable"
                            reloadDocument
                            {...getItemProps({
                              key: result.id.raw,
                              index: i,
                              item: result,
                            })}
                            // to={`/search?q=${result[titleField].raw}/${category}`}
                            to={`/${category}/search?q=${result[titleField].raw}`}
                            dangerouslySetInnerHTML={{ __html: data }}
                          ></Link>
                        </Row>
                      );
                    })}
                  </Col>
                </Row>
              </div>
            );
          }}
        />
      </ErrorBoundary>
    </div>
  );
};
