import { Col, Row, Image, Button, Divider } from "antd";
import { SearchOutlined, HeartFilled } from "@ant-design/icons";
import { useTranslation } from "react-i18next";
import { ProductDataType } from "../models/dataType";
import { DataRow } from "./DataRow";

export const DetailPopup = ({
  details,
  image = true,
    imageUrl
}: {
  details: ProductDataType;
  image?: boolean;
  imageUrl?: string;
}) => {
  const { t } = useTranslation();

  return (
    <>
      {/*<Row>{details.product_id}</Row>*/}
      <Row gutter={8}>
        <Col span={16}>
          <Divider className="divider" />
          <DataRow text={"Product Family"} data={details.product_family} />
          <DataRow
            text={"Product Group"}
            data={details.product_category_group}
          />
          <DataRow text={"Product Category"} data={details.product_category} />
          {/*<DataRow text={"Product Type"} data={details.product_type} />*/}
          <Divider className="divider" />
          <DataRow text={"COGS"} data={details.sales__cogs ?? "-"} />
          <DataRow
            text={"Shipments"}
            data={details.sales__shipment_count ?? "-"}
          />
          <DataRow text={"Quantity"} data={details.sales__quantity ?? "-"} />
          <DataRow
            text={"Average Order Amount"}
            data={details.avg_order_amount ?? "-"}
          />
          <DataRow
            text={"Average Order Size"}
            data={details.sales__average_order_size ?? "-"}
          />
          <DataRow text={"Average Cost (FOB)"} data={details.avg_cost ?? "-"} />
        </Col>
        {image && (
          <Col className="popup-buttons">
            <Row>
              <Image
                className="rounded"
                src={(imageUrl ? imageUrl : details.image_url) + process.env.REACT_APP_SAS_TOKEN}
                fallback={process.env.PUBLIC_URL + "fallbackImage.png"}
                height={150}
              />
            </Row>
            <Row>
              <Button block>
                {t("Find Supplier")} <SearchOutlined />
              </Button>
            </Row>
            <Row>
              <Button block>
                {t("Shortlist")} <HeartFilled />
              </Button>
            </Row>
          </Col>
        )}
      </Row>
    </>
  );
};
