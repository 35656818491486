import {
  Avatar,
  Card,
  Typography,
  Col,
  Row,
  Space,
  Button,
  Image,
  Divider,
  Tooltip,
  Checkbox,
  List,
  Table,
} from "antd";
import { UpOutlined, DownOutlined } from "@ant-design/icons";
import { useContext, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { createSearchParams, useNavigate } from "react-router-dom";
import {
  DataType,
  formatFacilityResult,
  PostTransactionType,
  SourceType,
} from "../models/dataType";
import { DataRow } from "./DataRow";
import { titleCase } from "./PerformanceMetrics";
import { useShortLists } from "../contexts/ShortListContext";
// import { useCompareLists } from "../contexts/CompareListContext";
import { UserContext } from "../contexts/UserInfoContext";
import { postTransaction } from "../services/TransactionService";
import { CheckboxChangeEvent } from "antd/es/checkbox";
import { useMsalWrapper } from "../contexts/MSALContext";

import VirtualList from "rc-virtual-list";

const { Text } = Typography;

interface TopThreeMetricsData {
  key: string;
  value: string;
  percentage: string;
  cogsValue: string;
}

const GetMetricsColumns = (metricCol: {
  title: string;
  dataIndex: string;
  key: string;
}) => [
  {
    ...metricCol,
    width: "10em",
  },
  {
    title: "COGS",
    dataIndex: "cogsValue",
    key: "cogsValue",
    width: "20px",
    render: (text: string) => (
      <Text>
        {parseFloat(text) < 0 ? "--" : `${parseFloat(text).toFixed(2)}`}
      </Text>
    ),
  },
  {
    title: "Percentage",
    dataIndex: "percentage",
    key: "percentage",
    width: "20px",
    render: (text: string) => (
      <Text>{parseFloat(text) < 0 ? "--" : `${text}%`}</Text>
    ),
  },
];

export const ResultCard = ({
  source,
  result,
  mappedResult,
  clickable = true,
  shortListed = false,
  isCompared = false,
  onShortListed,
  selectItem,
}: {
  source: SourceType;
  result?: any;
  mappedResult?: any;
  clickable?: boolean;
  shortListed?: boolean;
  isCompared?: boolean;
  onShortListed?: (code: string) => void;
  selectItem?: (token: string, code: string, selected: boolean) => void;
  onSelect?: (data: DataType) => void;
}) => {
  const [showMore, setShowMore] = useState(false);
  const [data, setData] = useState<DataType | null>(
    mappedResult ? mappedResult : null,
  );
  const { t } = useTranslation();

  const { addShortListId, deleteShortListId } = useShortLists();

  // const { addComparedItem, deleteComparedItem } = useCompareLists();

  const { acquireToken } = useMsalWrapper();

  const user: any = useContext(UserContext);

  const navigate = useNavigate();

  // const onCompare = () => {
  //   if (!data?.code) return;
  //   if (isCompared) {
  //     deleteComparedItem(source, data.code);
  //     return;
  //   }
  //   addComparedItem(source, data);
  // };

  const onSelect = (e: CheckboxChangeEvent) => {
    if (!data?.code) return;

    if (!selectItem) return;

    selectItem(source, data.code, e.target.checked);
  };

  const onShortList = async () => {
    if (!data?.code) return;
    //
    // const token = await acquireToken()
    // if (!token) return
    if (onShortListed) {
      onShortListed(data.code);
    }

    if (shortListed) {
      await deleteShortListId(source, data.code);
      return;
    }

    await addShortListId(source, data.code);

    //
    // await shortListItem(source, data.code, token, shortListed)
  };

  const goToFacility = async () => {
    if (!data) return;
    const params = { ps: "0", st: "" };
    const token = await acquireToken();

    if (!token || !user) return;

    const transaction: PostTransactionType = {
      user: user.token,
      method: "GET",
      path: `Detail - ${source}`,
      response: "200",
      accessedDate: parseInt(Date.now().toString()),
      payload: JSON.stringify({ id: data.code }),
    };

    await postTransaction(token, transaction);

    navigate({
      pathname: `/${source}/${data.code}`,
      search: `?${createSearchParams(params)}`,
    });
  };

  useEffect(() => {
    if (!result) return;
    if (mappedResult) return;
    setData(formatFacilityResult(source, result));
  }, [result, source, mappedResult]);

  const currentDate = new Date();
  let dateDistance = data?.yearsWithLf;
  if (dateDistance !== "--" && data) {
    dateDistance =
      parseInt(data.yearsWithLf) < 500
        ? data?.yearsWithLf
        : (
            currentDate.getFullYear() - parseInt(data.yearsWithLf)
          ).toLocaleString();
  }

  const resultDataRows = (data: DataType, showMore: Boolean) => {
    let topSuppliersData: TopThreeMetricsData[] = [];

    let topExportCountriesData: TopThreeMetricsData[] =
      data?.topExportCountriesCalculated.slice(0, 3).map((value, index) => ({
        key: index.toString(),
        value: value.country,
        percentage: value.cogPercent,
        cogsValue: value.cogValue,
      }));

    const topProductCategoriesData: TopThreeMetricsData[] =
      data?.topProductCategories?.slice(0, 3).map((value, index) => {
        return {
          key: index.toString(),
          value: value,
          percentage: data?.topProductCategoryCogs
            ? data?.topProductCategoryCogs[index]
              ? data?.topProductCategoryCogs[index]
              : "0"
            : "0",
          cogsValue: data?.topProductCategoryCogValues
            ? data?.topProductCategoryCogValues[index]
              ? data?.topProductCategoryCogValues[index]
              : "0"
            : "0",
        };
      });

    let topProductionCountriesData: TopThreeMetricsData[] = [];

    if (source === "customer") {
      topProductionCountriesData = data?.topProductionCountries
        .slice(0, 3)
        .map((value, index) => ({
          key: index.toString(),
          value: value.country,
          percentage: value.cogPercent,
          cogsValue: value.cogValue,
        }));

      topSuppliersData = data?.topCustomersCalculated
        .slice(0, 5)
        .map((value, index) => ({
          key: index.toString(),
          value: value.name ? value.name : value.customer,
          percentage: value.percent,
          cogsValue: value.value ? value.value : "-100",
        }))
        .splice(0, 5);
    }

    const obsoleteIndex = data.factoryRelationshipsFullName.findIndex(
      (item) => item && item.includes("(OBSOLETE)"),
    );

    const factoryData = data.factoryRelationships.filter(
      (item, index) => index !== obsoleteIndex,
    );

    const factoryFullNameData = data.factoryRelationshipsFullName.filter(
      (item, index) => index !== obsoleteIndex,
    );

    const factoryComplianceData = data.factoryRelationshipsCompliance;

    return showMore ? (
      <Row className="result-info" wrap={false}>
        <Col className="info" flex={"auto"}>
          {source === "supplier" && (
            <>
              <DataRow
                reverseBold
                text="No. of Workers"
                data={data.noOfEmployees}
              />
              <DataRow
                reverseBold
                text="Top 3 Key Customers"
                data={
                  data.topCustomersCalculated[0]?.customer
                    ? data.topCustomersCalculated[0]?.customer
                    : "--"
                }
              />
              <DataRow
                reverseBold
                text=""
                data={
                  data.topCustomersCalculated[1]?.customer
                    ? data.topCustomersCalculated[1]?.customer
                    : "--"
                }
              />
              <DataRow
                reverseBold
                text=""
                data={
                  data.topCustomersCalculated[2]?.customer
                    ? data.topCustomersCalculated[2]?.customer
                    : "--"
                }
              />

              <DataRow
                reverseBold
                text="On Time Delivery Rate"
                data={
                  data.deliveryRate[0] && data.deliveryRate[0] !== "--"
                    ? `${data.deliveryRate[0]}%`
                    : "--"
                }
              />
              <DataRow
                reverseBold
                text="Quality Pass Rate"
                data={
                  data.qualityClaim[0] && parseFloat(data.qualityClaim[0]) > 0
                    ? `${data.qualityClaim[0]}%`
                    : "--"
                }
              />
              <DataRow
                text="Business Volume Overall"
                reverseBold
                data={data.businessVolOverall}
              />
              <List>
                <Text type="secondary">
                  {t(`Factory (${factoryData.length})`)}
                </Text>
                <VirtualList
                  itemHeight={47}
                  height={250}
                  itemKey="factoryRelationship"
                  style={{ marginTop: -25 }}
                  data={factoryData}>
                  {(item, index) => {
                    return (
                      <div>
                        <Row>
                          <DataRow
                            reverseBold
                            text=""
                            data={
                              factoryFullNameData[index]
                                ? factoryFullNameData[index]
                                : item
                            }
                            navigation={`/factory/${item}`}
                          />
                        </Row>
                        <Row style={{ marginBottom: 20 }}>
                          <DataRow
                            reverseBold
                            text=""
                            data={
                              factoryComplianceData[index] &&
                              parseFloat(factoryComplianceData[index]) > 0
                                ? `Compliance: ${factoryComplianceData[index]}`
                                : "Compliance: --"
                            }
                          />
                        </Row>
                      </div>
                    );
                  }}
                </VirtualList>
              </List>

              <Table
                style={{ marginTop: 12 }}
                pagination={false}
                columns={GetMetricsColumns({
                  title: "Top 3 Export Countries",
                  dataIndex: "value",
                  key: "value",
                })}
                dataSource={topExportCountriesData}
              />
              <Table
                style={{ marginTop: 12, marginBottom: 12 }}
                pagination={false}
                columns={GetMetricsColumns({
                  title: "Top 3 Product Categories",
                  dataIndex: "value",
                  key: "value",
                })}
                dataSource={topProductCategoriesData}
              />

              {/*{data.factoryRelationships.slice(1).map((factory, index) => (*/}
              {/*    <div style={{marginTop: 10, marginBottom: 10}}>*/}
              {/*      <Row>*/}
              {/*        <DataRow*/}
              {/*            text=""*/}
              {/*            data={data.factoryRelationshipsFullName[index + 1] ? data.factoryRelationshipsFullName[index + 1] : factory}*/}
              {/*            navigation={`/factory/${factory}`}*/}
              {/*        />*/}
              {/*      </Row>*/}
              {/*      <Row>*/}
              {/*        <DataRow*/}
              {/*            text=""*/}
              {/*            data={data.factoryRelationshipsCompliance[index + 1] ? `Compliance: ${data.factoryRelationshipsCompliance[index + 1]}` : 'Compliance: --'}*/}
              {/*        />*/}
              {/*      </Row>*/}
              {/*    </div>*/}
              {/*))}*/}
            </>
          )}
          {source === "factory" && (
            <>
              {/* <DataRow
                text="Gender Percentage (softline)"
                data={data.genderPercentage}
              /> */}
              {/*<DataRow*/}
              {/*    reverseBold*/}
              {/*    text="Business Volume Overall"*/}
              {/*    data={data.businessVolOverall}*/}
              {/*/>*/}
              <DataRow
                text="Top 3 Key Customers"
                reverseBold
                data={data.topCustomers[0] ? data.topCustomers[0] : "--"}
              />
              <DataRow
                text=""
                reverseBold
                data={data.topCustomers[1] ? data.topCustomers[1] : "--"}
              />
              <DataRow
                text=""
                reverseBold
                data={data.topCustomers[2] ? data.topCustomers[2] : "--"}
              />
              {/*<DataRow*/}
              {/*    reverseBold*/}
              {/*    text="Production Countries"*/}
              {/*    data={data.productionCountry}*/}
              {/*/>*/}
              {/* <DataRow text="Supplier Website" data={data.supplierWebsite} /> */}
              {/*<DataRow*/}
              {/*  text="Business Volume with LF"*/}
              {/*  data={data.businessVolLf}*/}
              {/*/>*/}

              <DataRow
                reverseBold
                text="Quality Claim Cases"
                data={
                  parseFloat(data.qualityClaim[0]) > 0
                    ? data.qualityClaim[0]
                    : "--"
                }
              />
              {/*<DataRow reverseBold text="Quality Claim Amount (USD)" data={"--"} />*/}
              <Table
                style={{ marginTop: 12 }}
                pagination={false}
                columns={GetMetricsColumns({
                  title: "Top 3 Export Countries",
                  dataIndex: "value",
                  key: "value",
                })}
                dataSource={topExportCountriesData}
              />
              <Table
                style={{ marginTop: 12, marginBottom: 12 }}
                pagination={false}
                columns={GetMetricsColumns({
                  title: "Top 3 Product Categories",
                  dataIndex: "value",
                  key: "value",
                })}
                dataSource={topProductCategoriesData}
              />
            </>
          )}
          {source === "customer" && (
            <>
              {/*<DataRow reverseBold text="Top 5 Suppliers" data={data.topCustomersCalculated[0].customer} />*/}
              {/*<DataRow reverseBold text="" data={data?.topCustomersCalculated[1]?.customer} />*/}
              {/*<DataRow reverseBold text="" data={data?.topCustomersCalculated[2]?.customer} />*/}
              {/*<DataRow reverseBold text="" data={data?.topCustomersCalculated[3]?.customer} />*/}
              {/*<DataRow reverseBold text="" data={data?.topCustomersCalculated[4]?.customer} />*/}
              {/*<DataRow reverseBold text="Top production countries" data={data.topProductionCountries[1].country} />*/}
              {/*<DataRow reverseBold text="" data={data.topProductionCountries[2].country} />*/}
              <Table
                style={{ marginTop: 12 }}
                pagination={false}
                columns={GetMetricsColumns({
                  title: "Top 5 Suppliers",
                  dataIndex: "value",
                  key: "value",
                })}
                dataSource={topSuppliersData}
              />
              <Table
                style={{ marginTop: 12 }}
                pagination={false}
                columns={GetMetricsColumns({
                  title: "Top 3 Export Countries",
                  dataIndex: "value",
                  key: "value",
                })}
                dataSource={topExportCountriesData}
              />
              <Table
                style={{ marginTop: 12 }}
                pagination={false}
                columns={GetMetricsColumns({
                  title: "Top 3 Product Categories",
                  dataIndex: "value",
                  key: "value",
                })}
                dataSource={topProductCategoriesData}
              />
              <Table
                style={{ marginTop: 12, marginBottom: 12 }}
                pagination={false}
                columns={GetMetricsColumns({
                  title: "Top 3 Production Countries",
                  dataIndex: "value",
                  key: "value",
                })}
                dataSource={topProductionCountriesData}
              />
              {/* <DataRow text="Supplier Website" data={data.supplierWebsite} /> */}
            </>
          )}
        </Col>
      </Row>
    ) : (
      <></>
    );
  };

  const header = data && (
    <Row
      align="middle"
      justify="space-between"
      className="card-header"
      wrap={false}>
      <Row
        className="clickable result-header"
        align={"middle"}
        gutter={12}
        wrap={false}
        onClick={clickable ? goToFacility : () => {}}>
        <Col>
          <Avatar />
        </Col>
        <Col>
          <Row gutter={16}>
            <Col>
              <Text>{data.name}</Text>
            </Col>
            <Col>
              <Text italic>({data.code})</Text>
            </Col>
          </Row>
          <Row>
            <Space>
              <Text type="secondary">{"LF Database"}</Text>
              <Text>{"Active"}</Text>
            </Space>
          </Row>
        </Col>
      </Row>
      <Col>
        <Row className="card-buttons" wrap={false}>
          {/* {source !== "customer" && (
            <Button
              shape="round"
              size="small"
              style={{
                backgroundColor: isCompared ? "var(--red)" : "",
                color: isCompared ? "white" : "black",
              }}
              onClick={onCompare}>
              {t("Compare")}
            </Button>
          )} */}
          <Button
            shape="round"
            size="small"
            style={{
              backgroundColor: shortListed ? "var(--red)" : "",
              color: shortListed ? "white" : "black",
            }}
            onClick={onShortList}>
            {t("Shortlist")}
          </Button>
          {selectItem && <Checkbox onChange={onSelect} />}
          {/* {showFacilityButton && (
            <Button shape="round" onClick={goToFacility} size="small">
              {t("View ") + t(titleCase(source))}
            </Button>
          )} */}
        </Row>
      </Col>
    </Row>
  );
  return (
    data && (
      <Card title={header} className="card">
        <Row justify={"space-between"} wrap={false}>
          <Col
            className="result-text"
            style={source === "customer" ? { height: 100 } : {}}
            span={18}>
            <Row className="result-text-row" gutter={24}>
              {source !== "customer" && (
                <>
                  <Col>
                    <Text type="secondary">
                      {t("Social Compliance") + ": "}
                    </Text>
                    <Text strong>{data.socialCompliance}</Text>
                  </Col>
                  <Col>
                    <Tooltip title={"Technical Audit Score (0 - 100)"}>
                      <Text type="secondary">{t("TA Score") + " "}</Text>
                      <Text strong>{data.auditScore}</Text>
                    </Tooltip>
                  </Col>
                  <Col>
                    <Tooltip title={"LF Score (0 - 5)"}>
                      <Text type="secondary">{t("LF Score") + " "}</Text>
                      <Text strong>{data.lfScore}</Text>
                    </Tooltip>
                  </Col>
                </>
              )}
              {/*{source === "customer" && (*/}
              {/*    <>*/}
              {/*      <Col>*/}
              {/*        <Text type="secondary">{t("Social Compliance") + ": "}</Text>*/}
              {/*        <Text strong>{data.socialCompliance ? data.socialCompliance : '--'}</Text>*/}
              {/*      </Col>*/}
              {/*    </>*/}
              {/*)}*/}
              <Col>
                <Tooltip title={"Country"}>
                  <Text type="secondary">{t("Country") + " "}</Text>
                  <Text strong>{titleCase(data.productionCountry)}</Text>
                </Tooltip>
              </Col>
            </Row>
            <DataRow text="Years with LF" data={dateDistance} reverseBold />
            <DataRow
              text="Last Shipment Date"
              data={data.lastShipment}
              reverseBold
            />
            {source !== "customer" && (
              <>
                <DataRow
                  text="Average Lead Time"
                  data={data.avgLeadTimeCalculated}
                  reverseBold
                />
                <DataRow
                  text="Average Order Amount"
                  data={data.avgOrderAmountCalculated}
                  reverseBold
                />
                <DataRow
                  text="Average Order Quantity"
                  data={data.avgOrderQuantityCalculated}
                  reverseBold
                />
                <DataRow
                  text="Average PM Cost"
                  data={data.avgPmCostCalculated}
                  reverseBold
                />
              </>
            )}

            {source === "customer" ? (
              <></>
            ) : (
              <Row align={"bottom"}>
                <Space>
                  {/* <img
                  alt=""
                  src={process.env.PUBLIC_URL + "/icons/factories.svg"}
                  height={24}
                />
                <Text>{t("Cut Make & Trim")}</Text> |{" "} */}
                  {/*<Text>{titleCase(data.productionCountry)}</Text>*/}
                </Space>
              </Row>
            )}
          </Col>
          <Col>
            <Image
              src={data.imageUrl + process.env.REACT_APP_SAS_TOKEN}
              fallback={process.env.PUBLIC_URL + "/fallbackImage.png"}
              className="rounded"
              // width={300}
              height={150}
            />
          </Col>
        </Row>
        <Divider className="divider" />
        <Row gutter={8}>
          <Col span={16}>
            {data && resultDataRows(data, showMore)}
            <Row
              className="show-more-row"
              onClick={() => setShowMore(!showMore)}>
              <Space>
                <Text className="show-more-text">
                  {showMore ? t("Show Less") : t("Show More Details")}
                </Text>
                {showMore ? (
                  <UpOutlined className="red" />
                ) : (
                  <DownOutlined className="red" />
                )}
              </Space>
            </Row>
          </Col>
          <Col flex={"auto"}>
            {/* {showMore && (
              <Row justify={"end"}>
                <Text strong>{t("Top Products")}</Text>
              </Row>
            )} */}
          </Col>
        </Row>
      </Card>
    )
  );
};
