import { useEffect, useState } from "react";
import VisGraph, { GraphData, Options } from "react-vis-graph-wrapper";

import { DataType, SourceType } from "../models/dataType";
import { getRelationships } from "../services/DatabricksService";
import {Empty} from "antd";

interface RelationshipsProps {
  source: SourceType;
  data: DataType;
  setTabNumber: (num: number) => void;
}

const NodeColor = {
  supplier: "lightblue",
  customer: "lightgreen",
  factory: "orange",
};

const createNode = (code: string, label: string, source: SourceType) => {
  return {
    id: code,
    label: label,
    group: source,
    title: code,
    size: 100,
  };
};

export const Relationships = ({ source, data, setTabNumber }: RelationshipsProps) => {
  const [relationships, setRelationships] = useState<any[]>();
  const [graph, setGraph] = useState<GraphData>();

  useEffect(() => {
    const loadRelationships = async () => {
      const rows = await getRelationships(source, data.code);
      setRelationships(rows);
    };

    loadRelationships();
  }, [data.code, source]);

  useEffect(() => {
    if (!relationships) return;
    const nodes: any[] = [];
    const edges: any[] = [];
    const done = new Set<string>();

    for (const row of relationships) {
      const { CUST_CODE, CUST_NAME, FAC_CODE, FAC_NAME, SUP_CODE, SUP_NAME } = row;
      if (!done.has(CUST_CODE)) {
        nodes.push(createNode(CUST_CODE, CUST_NAME, "customer"));
        done.add(CUST_CODE);
      }
      if (!done.has(FAC_CODE)) {
        nodes.push(createNode(FAC_CODE, FAC_NAME, "factory"));
        done.add(FAC_CODE);
      }
      if (!done.has(SUP_CODE)) {
        nodes.push(createNode(SUP_CODE, SUP_NAME, "supplier"));
        done.add(SUP_CODE);
      }

      edges.push({ from: CUST_CODE, to: SUP_CODE });
      edges.push({ from: SUP_CODE, to: FAC_CODE });
    }

    setTabNumber(nodes.length)
    const graphData: GraphData = { nodes, edges };
    setGraph(graphData);
  }, [relationships, setTabNumber]);

  const options: Options = {
    groups: {
      customer: {
        color: {
          background: NodeColor.customer,
        },
      },
      supplier: {
        color: {
          background: NodeColor.supplier,
        },
      },
      factory: {
        color: {
          background: NodeColor.factory,
        },
      },
    },
    layout: {
      improvedLayout: true,
      hierarchical: {
        direction: "LR",
        enabled: true,
        levelSeparation: 350,
        nodeSpacing: 50,
        shakeTowards: "leaves",
        sortMethod: "directed",
      },
    },
    physics: false,
    nodes: {
      shape: "box",
    },
  };

  return (
    <div style={{ flex: 1, position: "relative", height: "100%" }}>
      {graph ? (
        <VisGraph
          graph={graph}
          options={options}
          style={{ width: "100%", height: "500px" }}
        />
      ) : <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />}
    </div>
  );
};
