import { Row, Col, Typography } from "antd";
const { Title, Text } = Typography;

export const MaintenancePage = () => {
  return (
    <Row>
      <Col className="search-error">
        <Row>
          <img src={process.env.PUBLIC_URL + "/icons/maintenance.svg"} alt="" />
        </Row>
        <Row>
          <Title level={3}>System Under Maintenance</Title>
        </Row>
        <Row>
          <Text>We're making your experiene better</Text>
        </Row>
        <Row>
          <Text>We will be back soon so please try again later</Text>
        </Row>
      </Col>
    </Row>
  );
};
