import { useEffect, useState } from "react";
import { CustomerDetails } from "../components/CustomerDetails";
import { getCustomerClient } from "../services/SearchService";
import {useLoaderData, useNavigate} from "react-router-dom";

export const customerDetailLoader = ({ params }: { params: any }) => {
  const id = params.id;
  return { id };
};

export const CustomerDetailPage = () => {
  const [result, setResult] = useState(null);
  const { id } = useLoaderData() as any;

  const navigate = useNavigate();

  useEffect(() => {
    const client = getCustomerClient();
    const options = {
      search_fields: { customer_code: {} },
      // result_fields: searchQueryOptions.result_fields,
    };
    client
      .search(id, options)
      .then((resultList: any) => {
        if (resultList.rawResults.length == 0) {
          navigate('/not-found')
          return
        }
        const filteredResultList = resultList.rawResults.filter((result: any) => result.customer_code?.raw === id)
        if (filteredResultList.length == 0) {
          navigate('/not-found')
          return
        }
        setResult(filteredResultList[0])
      })
      .catch((error: any) => console.error(error));
  }, [id]);

  return <CustomerDetails result={result} />;
};
