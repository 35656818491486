interface ImageSearchPagingInfoProps {
    totalResults: number
    currentPage: number
}

export const ImageSearchPagingInfo: React.FC<ImageSearchPagingInfoProps> = (props) => {
    return (
        <div className="sui-paging-info">
            <div>Showing{" "}
                <strong>
                    1 - {props.currentPage}
                </strong>{" "}
                out of{" "}
                <strong>{props.totalResults}</strong>
            </div>
        </div>
    )
}
