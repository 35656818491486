import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";

export const IconLink = ({
  src,
  text,
  link,
}: {
  src?: string;
  text: string;
  link: string;
}) => {
  const { t } = useTranslation();

  return (
    <div className="icon-link">
      <Link to={link}>
        <img className="rounded" src={src} alt={text} />
        <p> {t(text)} </p>
      </Link>
    </div>
  );
};
