import { Col, Typography } from "antd";
const { Text } = Typography;

export const CategoryGenerator = (
  categories: string[],
  activeCategory: string,
  setActiveCategory: (category: string) => void,
  numResults: number,
  categoryIndex?: number
) => {
  return categories.map((category) => {
    return (
      <Col
        key={category}
        className={"category " + (activeCategory === category ? "active" : "")}
        onClick={() => setActiveCategory(category)}
      >
        {category === 'Compare' ?
            <Text  className="category-title clickable" >
              {category} {categoryIndex &&
                categoryIndex > 0 ?
                  `(${categoryIndex})`
                  :
                  ''
              }
            </Text>
            :
            <Text  className="category-title clickable" >
              {category} {activeCategory === category && numResults > 0 && `(${numResults})`}
            </Text>
        }
      </Col>
    );
  });
};
