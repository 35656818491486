import React, {createContext, useContext, useEffect, useMemo, useState} from "react";
import {getShortList, isShortListed, shortListItem} from "../services/ShortListService";
import {useMsalWrapper} from "./MSALContext";

interface ShortListContextData {
    shortListIds: string[],
    addShortListId: (type:string, postId: string) => Promise<void>,
    deleteShortListId: (type:string, postId: string) => Promise<void>,
    fetchShortListIds: () => void
}

interface iShortListProviderProps {
    children: React.ReactNode;
}

const ShortListContext = createContext<ShortListContextData>({} as ShortListContextData)

const useShortLists = (): ShortListContextData  => {
    return useContext(ShortListContext)
}



const ShortListProvider: React.FC<iShortListProviderProps> = ({children}) => {
    const [shortListIds, setShortListIds] = useState<string[]>([])

    const { acquireToken } = useMsalWrapper()

    useEffect(() => {
        if (shortListIds.length === 0) {
            fetchShortListIds().catch((e) => console.error(`Failed to fetch shortlist: ${e}`))
            return
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])


    const addShortListId = async (type:string, postId: string) => {
        const token = await acquireToken()
        if (!token) return

        if (isShortListed(postId, shortListIds)) return

        await shortListItem(type, postId, token).then((response) => {
            if (!response) return
            setShortListIds((currentIds) => [...currentIds, postId])
        }).catch((e) => console.error(`Failed to save shortlist item: ${e}`))
    }

    const deleteShortListId = async (type:string, postId: string) => {
        const token = await acquireToken()
        if (!token) return

        await shortListItem(type, postId, token, true).then((response) => {
            if (!response) return
            setShortListIds((currentIds) => currentIds.filter((code) => code !== postId))
        })
            .catch((e) => console.error(`Failed to delete shortlist item: ${e}`))
    }

    const fetchShortListIds = async () => {
        const token = await acquireToken()
        if (!token) return

        const shortList = await getShortList(token)

        if (!shortList?.data) {
            setShortListIds([])
            return
        }

        let itemIdArr: string[] = []

        shortList.data.forEach((item: any) => {
            itemIdArr.push(item.code)
        })

        setShortListIds(itemIdArr)
    }

    const shortListContextValue: ShortListContextData = useMemo(() => ({
        shortListIds,
        addShortListId,
        deleteShortListId,
        fetchShortListIds
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }), [shortListIds])

    return (
        <ShortListContext.Provider value={shortListContextValue}>
            {children}
        </ShortListContext.Provider>
    )
}

export { ShortListProvider, useShortLists }
