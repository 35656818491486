export const pageview = (url: string) => {

  if (typeof window.gtag !== 'undefined') {
    if (!process.env.REACT_APP_GA_TRACKING_ID) {
      console.error("Missing Google Analytics TRACKING ID");
      return;
    }
    window.gtag('config', `${process.env.REACT_APP_GA_TRACKING_ID}`, {
      page_path: url
    })
  }
}
