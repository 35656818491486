var ElasticAppSearch = require("@elastic/app-search-javascript");

const search_key = process.env.REACT_APP_SEARCH_KEY;
const engine = process.env.REACT_APP_SEARCH_ENGINE;
const endpoint = process.env.REACT_APP_SEARCH_ENDPOINT;

export const getSearchClient = () => {
  return ElasticAppSearch.createClient({
    searchKey: search_key,
    engineName: engine,
    endpointBase: endpoint,
  });
};

export const getProductClient = () => {
  return ElasticAppSearch.createClient({
    searchKey: search_key,
    engineName: "product-engine",
    endpointBase: endpoint,
  });
};

export const getSupplierClient = () => {
  return ElasticAppSearch.createClient({
    searchKey: search_key,
    engineName: "supplier-engine",
    endpointBase: endpoint,
  });
};

export const getFactoryClient = () => {
  return ElasticAppSearch.createClient({
    searchKey: search_key,
    engineName: "factory-engine",
    endpointBase: endpoint,
  });
};

export const getCustomerClient = () => {
  return ElasticAppSearch.createClient({
    searchKey: search_key,
    engineName: "customer-engine",
    endpointBase: endpoint,
  });
};
