import { MenuProps, Dropdown, Typography, Col, Badge } from "antd";
import { UserOutlined } from "@ant-design/icons";
import { useMsal } from "@azure/msal-react";
import { useContext } from "react";
import { AccountInfoContext } from "../contexts/AccountInfoContext";
import { UserContext } from "../contexts/UserInfoContext";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";

const { Text, Link } = Typography;

export const UserDropdown = () => {
  const { t } = useTranslation();
  const accountInfo = useContext(AccountInfoContext);
  const user: any = useContext(UserContext);
  const { instance } = useMsal();

  const navigate = useNavigate();

  const signOutClickHandler = () => {
    const logoutRequest = {
      // account: currentAccount,
      postLogoutRedirectUri:
        "https://login.microsoftonline.com/common/oauth2/logout",
      // onRedirectNavigate: (url: string) => true
    };
    // instance.logout(logoutRequest)
    sessionStorage.clear();
    localStorage.clear();
    instance.logoutRedirect(logoutRequest);
    sessionStorage.clear();
    localStorage.clear();
  };

  const items: MenuProps["items"] = [
    {
      key: "1",
      label: <p>{t("Preferences")}</p>,
    },
    {
      key: "2",
      label: <p onClick={() => signOutClickHandler()}>{t("Signout")}</p>,
      icon: <img src={process.env.PUBLIC_URL + "/icons/sign-out.svg"} alt="" />,
    },
  ];

  return (
    <>
      <Col flex="100px">
        <Badge count={user?.wishListItems ?? 0} offset={[12, 10]}>
          <Link strong onClick={() => navigate("/shortlist")}>
            {t("Shortlist")}
          </Link>
        </Badge>
      </Col>
      <Col>
        <Dropdown menu={{ items }}>
          <div className="user">
            <UserOutlined />
            <Text className="clickable" strong>
              {accountInfo?.name}
            </Text>
          </div>
        </Dropdown>
      </Col>
    </>
  );
};
