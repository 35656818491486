import { useEffect, useState } from "react";
import {
  ProductDataType,
  SourceType,
} from "../models/dataType";
import {
  getCustomerClient,
  getFactoryClient,
  getSupplierClient,
} from "../services/SearchService";
import { ResultCard } from "./ResultCard";

interface ProductFacilityGridProps {
  source: SourceType;
  data: ProductDataType;
}

export const ProductFacilityGrid = ({
  source,
  data,
}: ProductFacilityGridProps) => {
  const [result, setResult] = useState<any>();

  useEffect(() => {
    let facility = "";
    let client: any;
    let options: any;

    switch (source) {
      case "supplier":
        facility = data.supplier_code;
        client = getSupplierClient();
        options = {
          search_fields: { supplier_code: {} },
          page: { size: 1, current: 1 },
        };
        break;
      case "factory":
        facility = data.factory_code;
        client = getFactoryClient();
        options = {
          search_fields: { factory_code: {} },
          page: { size: 1, current: 1 },
        };
        break;
      case "customer":
        facility = data.customer[0];
        client = getCustomerClient();
        options = {
          search_fields: { customer_code: {} },
          page: { size: 1, current: 1 },
        };
        break;
    }
    if (!facility) return;

    client
      .search(facility, options)
      .then((resultList: any) => {
        setResult(resultList.rawResults[0]);
      })
      .catch((error: any) => console.error(error));
  }, [data.customer, data.factory_code, data.supplier_code, result, source]);

  return (
    <div className="card">
      {result && (
        <ResultCard
          source={source}
          result={result}
        />
      )}
      {!result && <p>No relationship to show</p>}
    </div>
  );
};
