const CryptoJS = require("crypto-js");

function padMessage(message: string) {
  const blockSize = 8;
  const paddedLength = Math.ceil(message.length / blockSize) * blockSize;
  const paddingLength = paddedLength - message.length;
  const paddingChar = String.fromCharCode(paddingLength);
  return message + paddingChar.repeat(paddingLength);
}

/**
 * Given a user key and document barcode, return the barcode_index for DMS url
 * @returns barcode index value
 */
export const decryptDocument = (barcode: string): string | null => {
  const user_key = process.env.REACT_APP_DMS_USER_KEY;
  if (!user_key) {
    throw new Error("Missing user key for document decryption");
  }
  if (!barcode) {
    return null;
  }

  try {
    // generate cipher with DES and user_key
    const des_key = CryptoJS.enc.Utf8.parse(user_key);
    const iv = CryptoJS.lib.WordArray.create(0, 8);
    const paddedBarcode = padMessage(barcode);
    const cipher = CryptoJS.DES.encrypt(paddedBarcode, des_key, { iv: iv });

    // encrypted barcode is encoded as hex, then utf-8 upper case
    const hex_str: string = cipher.ciphertext
      .toString(CryptoJS.enc.Hex)
      .toUpperCase()
      .slice(0, -16);
    return hex_str;
  } catch (e) {
    console.error(`Failed to decrypt DMS: ${e}`);
    return null;
  }
};

/**
 * Construct URL using params that points to a given document
 * @param loginName user dms login
 * @param loginPass user dms password
 * @param barcodeIV document index value from decryption
 * @returns document DMS URL
 */
export const getDMSUrl = (
  loginName: string,
  loginPass: string,
  barcodeIV: string,
): string => {
  return `${process.env.REACT_APP_DMS_BASE_URL}?LoginName=${loginName}&LoginPwd=${loginPass}&CommonIndexName=Barcode&IndexValue=${barcodeIV}&ReturnMethod=1`;
};

/**
 * Error handling for document generation, both for division and dms documents
 * @param document either Division or DMS document
 * @returns URL if valid, null if errors
 */
export const generateDocUrl = (document: any): string | null => {
  if (document.fileUrl) {
    return document.fileUrl + process.env.REACT_APP_SUPPLIER_SAS_TOKEN;
  }
  const barcode = document.barcode;
  const loginName = process.env.REACT_APP_DMS_LOGIN;
  const loginPass = process.env.REACT_APP_DMS_PASS;

  if (!loginName || !loginPass) {
    console.error("Missing credentials for document login");
    return null;
  }
  const barcodeIV = decryptDocument(barcode);
  if (!barcodeIV) {
    console.error("Invalid barcode: ", barcode);
    return null;
  }

  return getDMSUrl(loginName, loginPass, barcodeIV);
};
