import { Layout, Typography, Row, Col } from "antd";
import { useTranslation } from "react-i18next";
import { NavBar } from "../components/NavBar";

const { Header, Content } = Layout;
const { Title, Text } = Typography;

export const SearchMaintenancePage = () => {
  const { t } = useTranslation();

  return (
    <Layout className="frame">
      <Header id="search-header">
        <NavBar />
      </Header>
      <Content className="content">
        <Row className="search-error">
          <Col>
            <Row>
              <img
                src={process.env.PUBLIC_URL + "/icons/maintenance.svg"}
                alt=""
              />
            </Row>
            <Row>
              <Title level={3}>{t("System Under Maintenance")}</Title>
            </Row>
            <Row>
              <Text>{t("We're making your experience better")}</Text>
            </Row>
            <Row>
              <Text>{t("We will be back soon so please try again later")}</Text>
            </Row>
          </Col>
        </Row>
      </Content>
    </Layout>
  );
};
