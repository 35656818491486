import { Button, Card, Col, Divider, Row, Typography } from "antd";
// import {UploadOutlined} from '@ant-design/icons';
import { useTranslation } from "react-i18next";
import { RatingTextSpan } from "./RatingTextSpan";
import TextArea from "antd/es/input/TextArea";
import React, { useEffect, useState } from "react";
import { CommentCard } from "./CommentCard";
import { useMsalWrapper } from "../contexts/MSALContext";
import { DataType, SourceType } from "../models/dataType";
import {
  getComments,
  getRatings,
  postComment,
} from "../services/CommentService";
import { toast } from "react-toastify";

const { Text } = Typography;

interface iCommentGridProps {
  source: SourceType;
  data: DataType;
}

export const CommentGrid: React.FC<iCommentGridProps> = (props) => {
  // const [fileList, setFileList] = useState<UploadFile[]>();

  const [ratingValue, setRatingValue] = useState<number>();

  const [body, setBody] = useState<string>();

  const [comments, setComments] = useState<any[]>([]);

  const { acquireToken } = useMsalWrapper();

  const { t } = useTranslation();
  const fetchComments = async () => {
    const subject = props?.data?.code;

    const token = await acquireToken();
    if (!token) return;

    const response = await getComments(
      token,
      undefined,
      undefined,
      subject,
      props.source,
    );

    if (!response) return;

    const commentArr = response.data.comments;

    for (const comment of commentArr) {
      const rating = await getRatings(token, props.source, comment.id);
      if (!rating || rating.data?.length == 0) continue;
      const ratingData = rating.data[0];
      comment.ratingValue = ratingData.value;
    }

    setComments(commentArr);
  };

  useEffect(() => {
    fetchComments();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onSubmit = async () => {
    const token = await acquireToken();

    if (!token) return;

    if (!body || !ratingValue) {
      toast.error(
        `${!body ? "Text body" : ""} ${!body && !ratingValue ? " & " : ""} ${
          !ratingValue ? "Rating" : ""
        } missing`,
        { position: "bottom-center", autoClose: 5000, closeOnClick: true },
      );
      return;
    }

    const subject = props.data.code;
    const objectType = props.source;

    const response = await postComment(
      token,
      subject,
      body,
      objectType,
      ratingValue,
    );

    if (!response) {
      toast.error("Failed to upload comment", {
        position: "bottom-center",
        autoClose: 5000,
        closeOnClick: true,
      });
      return;
    }

    toast.success("Successfully uploaded comment", {
      position: "bottom-center",
      autoClose: 5000,
      closeOnClick: true,
    });

    fetchComments();
  };

  return (
    <>
      <Card className="card">
        <Row justify={"space-between"} wrap={false}>
          <Col style={{ marginBottom: "12px" }} span={8}>
            <Row>
              <Text style={{ color: "#515761" }} strong>
                Rate this supplier
              </Text>
            </Row>
            <RatingTextSpan
              setRating={setRatingValue}
              defaultValue={ratingValue}
              text={t("Overall Rating")}
              marginTop={"12px"}
              starSize={25}
            />
            <Row>
              <Divider className="divider" />
            </Row>
            <RatingTextSpan text={t("On Time Delivery")} />
            <RatingTextSpan text={t("Responsiveness")} />
            <RatingTextSpan text={t("Sustainability")} />
            <RatingTextSpan text={t("Capacity")} />
          </Col>
          <Col style={{ marginBottom: "12px" }} span={8}>
            <Row>
              <Text style={{ color: "#515761" }} strong>
                Write your review
              </Text>
            </Row>
            <Row style={{ marginTop: "12px" }}>
              <TextArea
                style={{ backgroundColor: "#f2f2f2" }}
                bordered={false}
                maxLength={200}
                autoSize={{ minRows: 8, maxRows: 8 }}
                onBlur={(e) => setBody(e.target.value)}
                rows={4}
                placeholder="Tell us what you think"
              />
            </Row>
            {/*<Row style={{marginTop: '12px'}}>*/}
            {/*    <Row>*/}
            {/*        <Upload fileList={fileList}>*/}
            {/*            <Button icon={<UploadOutlined />}>Upload</Button>*/}
            {/*        </Upload>*/}
            {/*    </Row>*/}

            {/*</Row>*/}
            {/*<Row style={{marginTop: '5px'}}>*/}
            {/*    <Text style={{fontSize: '12px', marginRight: '5px'}}>File types:</Text>*/}
            {/*    <Text style={{fontSize: '12px'}} strong>JPG, PNG</Text>*/}
            {/*</Row>*/}
            {/*<Row>*/}
            {/*    <Text style={{fontSize: '12px', marginRight: '5px'}}>Max file size: </Text>*/}
            {/*    <Text style={{fontSize: '12px'}} strong>5MB</Text>*/}
            {/*</Row>*/}
            <Row style={{ marginTop: "12px" }}>
              <Button
                className="card-buttons"
                shape="round"
                htmlType="submit"
                onClick={onSubmit}
                style={{
                  backgroundColor: "var(--red)",
                  color: "white",
                  marginLeft: "auto",
                }}
                size="small">
                {t("Submit Review")}
              </Button>
            </Row>
          </Col>
        </Row>
      </Card>
      <Row className="product-searchbar">
        <div className="product-searchbar-left">
          <Text className="clickable">{t("All Comments")}</Text>
        </div>
      </Row>
      <Row style={{ width: "100%", marginBottom: "20px" }}>
        <div className="facility-grid">
          {comments.map((data) => {
            data.imageUrl = props.data?.imageUrl;
            data.title = props.data?.name;
            return <CommentCard data={data} />;
          })}
        </div>
      </Row>
    </>
  );
};
