import {Col, Row} from "antd";
import {IconLink} from "../components/IconLink";
import {CustomImageSearchBox} from "../components/CustomImageSearchBox";
import {ChangelogPopover} from "../components/ChangelogPopover";
import {UserDropdown} from "../components/UserDropDown";

export const ImageLandingPage = () => {
    return (
        <Col>
            <Row className="landing-top">
                <div id="landing-col">
                    <Row id="icons-row">
                        <Col span={8} flex={"auto"}>
                            <img
                                src={process.env.PUBLIC_URL + "/icons/LFLogo.svg"}
                                alt="Li & Fung"
                            />
                        </Col>
                        <Col className="icons" span={8}>
                            <IconLink
                                src={process.env.PUBLIC_URL + "/icons/back.svg"}
                                text="Back"
                                link="/"
                            />
                        </Col>
                    </Row>
                    <Row>
                        <CustomImageSearchBox />
                    </Row>
                    <Row id="landing-user">
                        <ChangelogPopover />
                        <UserDropdown />
                    </Row>
                </div>
            </Row>
        </Col>
    )
}
