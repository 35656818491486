import {Row, Col, Typography, Space} from "antd";
import { useTranslation } from "react-i18next";
import {ArrowLeftOutlined} from "@ant-design/icons";
import React from "react";
import {useNavigate} from "react-router-dom";
const { Title, Text } = Typography;

const { Link } = Typography;

export const NotFoundPage = () => {
    const {t} = useTranslation();
    const navigate = useNavigate();
    return (
        <Row>
            <Col className="search-error">
                <Row>
                    <img src={process.env.PUBLIC_URL + "/icons/error.svg"} alt="" />
                </Row>
                <Row>
                    <Title level={3}>{t('404')}</Title>
                </Row>
                <Row>
                    <Text>{t('Sorry, the page you\'re looking for cannot be found.')}</Text>
                </Row>
                <Row>
                    <Link onClick={() => navigate('/')}>
                        <Space>
                            {t("Back to home page")}
                        </Space>
                    </Link>
                </Row>
            </Col>
        </Row>
    );
};
