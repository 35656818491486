import { Row, Col } from "antd";
import { useNavigate } from "react-router-dom";
import { UserDropdown } from "./UserDropDown";
import { CustomSearchBox } from "./CustomSearchBox";

export const NavBar = () => {
  const navigate = useNavigate();

  return (
    <Row id="navbar">
      <Col className="lf-logo" onClick={() => navigate("/")}>
        <img
          src={process.env.PUBLIC_URL + "/icons/LFLogo.svg"}
          alt="Li & Fung"
        />
      </Col>
      <Col flex="auto">
        <CustomSearchBox />
      </Col>
      <UserDropdown />
    </Row>
  );
};
