import axios from "axios";
import { Preference } from "../contexts/UserInfoContext";

export const getUser = async (idToken: string) => {
  const url = process.env.REACT_APP_USERS_BACKEND;
  if (!url) throw new Error("Missing User API");

  const headers = { Authorization: `Bearer ${idToken}` };
  try {
    const getResponse = await axios.get(`${url}/me`, { headers: headers });
    return getResponse.data;
  } catch (error: any) {
    console.error("Did not find User");
    throw error;
  }
};

export const submitPreferences = async (
  idToken: string,
  preferences: Preference[]
) => {
  const url = process.env.REACT_APP_USERS_BACKEND;
  if (!url) throw new Error("Missing User API");

  const headers = { Authorization: `Bearer ${idToken}` };
  const body = { preferences: preferences };

  try {
    const response = await axios.patch(`${url}/preference`, body, {
      headers: headers,
    });
    return response
  } catch (error) {
    console.error("Could not update user Preference");
    throw error;
  }
};
