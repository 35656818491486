import { Layout, Typography, Row, Space } from "antd";
import { ArrowLeftOutlined } from "@ant-design/icons";
import {useCallback, useEffect, useState} from "react";
import { useNavigate } from "react-router-dom";
import { CategoryGenerator } from "../components/CategoryGenerator";
import { NavBar } from "../components/NavBar";
import { useTranslation } from "react-i18next";
import { ResultProductCard } from "../components/ResultProductCard";
import {ProductDataType} from "../models/dataType";
import { ProductFacilityGrid } from "../components/ProductFacilityGrid";
import {useShortLists} from "../contexts/ShortListContext";
import {isShortListed} from "../services/ShortListService";

const { Header, Content } = Layout;
const { Link } = Typography;

export const ProductDetails = ({ data }: { data: ProductDataType }) => {
  const [activeTab, setActiveTab] = useState("Supplier");
  const [tabComponent, setTabComponent] = useState<any>();

  const [isShortList, setIsShortList] = useState<boolean>(false)

  const { shortListIds } = useShortLists()

  const navigate = useNavigate();
  const { t } = useTranslation();
  const categories = [
    t("Supplier"),
    t("Factory"),
    t("Customers")
  ];

  const setShortStatus = useCallback(() => {
    try {
      if (!data.item_number) return
      const shortListed = isShortListed(data.item_number, shortListIds)
      setIsShortList(shortListed)
    } catch (e) {
      console.error(e)
    }
  }, [shortListIds, setIsShortList, data])

  useEffect(() => {
    setShortStatus()
  }, [setShortStatus])

  useEffect(() => {
    switch (activeTab) {
      case "Supplier":
        setTabComponent(
          data && <ProductFacilityGrid source="supplier" data={data} />
        );
        break;
      case "Factory":
        setTabComponent(
          data && <ProductFacilityGrid source="factory" data={data} />
        );
        break;
      case "Customers":
        setTabComponent(
            data && <ProductFacilityGrid source="customer" data={data} />
        );
    }
  }, [activeTab, data]);
  return (
    <Layout className="frame">
      <Header id="search-header">
        <NavBar />
      </Header>
      <Content className="content">
        <Row id="search-backlink">
          <Link onClick={() => navigate(-1)}>
            <Space>
              <ArrowLeftOutlined />
              Back to Search Results
            </Space>
          </Link>
        </Row>
        <Row className="results">
          <ResultProductCard shortListed={isShortList} data={data} showButtons={false} />
        </Row>
        <Row className="categories">
          {CategoryGenerator(categories, activeTab, setActiveTab, 0)}
        </Row>
        <Row>{tabComponent}</Row>
      </Content>
    </Layout>
  );
};
