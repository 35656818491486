import React, {useEffect, useState} from "react";

import type { FieldValue } from "@elastic/search-ui";
import {FacetViewProps} from "@elastic/react-search-ui-views";
import appendClassName from "../utils/elasticUtils/appendClassName";
import getFilterValueDisplay from "../utils/elasticUtils/getFilterValueDisplay";

export default function RegionMultiCheckboxFacet({
  className,
  label,
  onMoreClick,
  onRemove,
  onSelect,
  options,
  showMore,
  showSearch,
  onSearch,
  searchPlaceholder
}: FacetViewProps) {

  const [selectedOptions, setSelectedOptions] = useState<FieldValue[]>([]);

  const [optionsState, setOptionsState] = useState(options)

  useEffect(() => {
    const initializeOptions = () => {
      const tempOptions = options

      let turkeyOption
      let portugalOption
      let ukOption
      let franceOption
      let germanyOption
      let italyOption
      let netherlandsOption

      let turkeyCount = 0
      let portugalCount = 0
      let ukCount = 0
      let franceCount = 0
      let germanyCount = 0
      let italyCount = 0
      let netherlandsCount = 0
      let euCount = 0

      if (!showMore && !options.find((option) => option.value === 'EU')) {
        turkeyOption = options.find((option) => option.value === "TURKEY")
        if (turkeyOption) {
          portugalOption = options.find((option) => option.value === "PORTUGAL")
        } else {
          ukOption = options.find((option) => option.value === "UNITED KINGDOM")
          franceOption = options.find((option) => option.value === "FRANCE")
          germanyOption = options.find((option) => option.value === "GERMANY")
          italyOption = options.find((option) => option.value === "ITALY")
          netherlandsOption = options.find((option) => option.value === "NETHERLANDS")
        }

        turkeyCount = turkeyOption ? turkeyOption.count : 0
        portugalCount = portugalOption ? portugalOption.count : 0
        ukCount = ukOption ? ukOption.count : 0
        franceCount = franceOption ? franceOption.count : 0
        germanyCount = germanyOption ? germanyOption.count : 0
        italyCount = italyOption ? italyOption.count : 0
        netherlandsCount = netherlandsOption ? netherlandsOption.count : 0
        euCount = turkeyCount + portugalCount + ukCount + franceCount + germanyCount + italyCount + netherlandsCount

        tempOptions.push({count: euCount, value: 'EU', selected: false})
      }

      const turkeySelected = turkeyOption ? turkeyOption.selected : false
      const portugalSelected = portugalOption ? portugalOption.selected : false
      const ukSelected = ukOption ? ukOption.selected : false
      const franceSelected = franceOption ? franceOption.selected : false
      const germanySelected = germanyOption ? germanyOption.selected : false
      const italySelected = italyOption ? italyOption.selected : false
      const netherlandsSelected = netherlandsOption ? netherlandsOption.selected : false

      if ((turkeySelected && portugalSelected) || (ukSelected && franceSelected && germanySelected && italySelected && netherlandsSelected)) {
        const euOption = tempOptions.find((option) => option.value === "EU")
        if (euOption) {
          euOption.selected = true
        }
      }


      setOptionsState(tempOptions)
    }
    initializeOptions()
    // eslint-disable-next-line
  }, [options])

  const handleSelect = (value: FieldValue) => {
    const updatedSelectedOptions = selectedOptions.includes(value)
        ? selectedOptions.filter((option) => option !== value)
        : [...selectedOptions, value];
    setSelectedOptions(updatedSelectedOptions);

    if (selectedOptions.includes(value)) {
      if (value === 'EU') {
        onRemove('PORTUGAL')
        onRemove('TURKEY')
        onRemove('UNITED KINGDOM')
        onRemove('FRANCE')
        onRemove('GERMANY')
        onRemove('ITALY')
        onRemove('NETHERLANDS')
        onRemove('EU')
        return
      }
      onRemove(value);
    } else {
      if (value === 'EU') {
        onSelect('PORTUGAL')
        onSelect('TURKEY')
        onSelect('UNITED KINGDOM')
        onSelect('FRANCE')
        onSelect('GERMANY')
        onSelect('ITALY')
        onSelect('NETHERLANDS')
        onSelect('EU')
        return
      }
      onSelect(value);
    }
  };

  return (
    <fieldset className={appendClassName("sui-facet", className)}>
      <legend className="sui-facet__title">{label}</legend>

      {showSearch && (
        <div className="sui-facet-search">
          <input
            className="sui-facet-search__text-input"
            type="search"
            placeholder={searchPlaceholder || "Search"}
            onChange={(e) => {
              onSearch(e.target.value);
            }}
          />
        </div>
      )}

      <div className="sui-multi-checkbox-facet">
        {optionsState.length < 1 && <div>No matching options</div>}
        {optionsState.map((option) => {
          const checked = option.selected;
          const value = option.value as FieldValue;
          return (
            <label
              key={`${getFilterValueDisplay(option.value)}`}
              htmlFor={`example_facet_${label}${getFilterValueDisplay(
                option.value
              )}`}
              className="sui-multi-checkbox-facet__option-label"
            >
              <div className="sui-multi-checkbox-facet__option-input-wrapper">
                <input
                  data-transaction-name={`facet - ${label}`}
                  id={`example_facet_${label}${getFilterValueDisplay(
                    option.value
                  )}`}
                  type="checkbox"
                  className="sui-multi-checkbox-facet__checkbox"
                  checked={checked}
                  onChange={() => handleSelect(value)}
                />
                <span className="sui-multi-checkbox-facet__input-text">
                  {getFilterValueDisplay(option.value)}
                </span>
              </div>
              <span className="sui-multi-checkbox-facet__option-count">
                {option.count.toLocaleString("en")}
              </span>
            </label>
          );
        })}
      </div>

      {showMore && (
        <button
          type="button"
          className="sui-facet-view-more"
          onClick={onMoreClick}
          aria-label="Show more options"
        >
          + More
        </button>
      )}
    </fieldset>
  );
}
