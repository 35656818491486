import { Layout, Row, Space, Typography } from "antd";
import { ArrowLeftOutlined } from "@ant-design/icons";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { CategoryGenerator } from "./CategoryGenerator";
import { NavBar } from "./NavBar";
import { ResultCard } from "./ResultCard";
import { useEffect, useState } from "react";
import { ProductCategories } from "./ProductCategories";
import { formatFacilityResult } from "../models/dataType";
import { Contacts } from "./Contacts";
import { CustomerFacilityGrid } from "./CustomerFacilityGrid";
import { Relationships } from "./Relationships";

const { Header, Content } = Layout;
const { Link } = Typography;

interface CustomerDetailsProps {
  result: any;
}

export const CustomerDetails = ({ result }: CustomerDetailsProps) => {
  const [activeTab, setActiveTab] = useState("Product Categories");
  const [tabNumber, setTabNumber] = useState(0);
  const [tabComponent, setTabComponent] = useState();

  const navigate = useNavigate();
  const { t } = useTranslation();

  const categories = [
    "Product Categories",
    "Suppliers",
    "Factories",
    "Relationships",
    "Contacts",
  ];

  useEffect(() => {
    switch (activeTab) {
      case "Product Categories":
        setTabNumber(0);
        setTabComponent(
          result && <ProductCategories id={result.customer_code.raw} />
        );
        break;
      case "Suppliers":
        setTabComponent(
          result && (
            <CustomerFacilityGrid
              source="supplier"
              data={formatFacilityResult("customer", result)}
              setTabNumber={setTabNumber}
            />
          )
        );
        break;
      case "Factories":
        setTabComponent(
          result && (
            <CustomerFacilityGrid
              source="factory"
              data={formatFacilityResult("customer", result)}
              setTabNumber={setTabNumber}
            />
          )
        );
        break;
      case "Relationships":
        setTabNumber(0);
        setTabComponent(
          result && (
            <Relationships
              source={"customer"}
              data={formatFacilityResult("customer", result)}
              setTabNumber={setTabNumber}
            />
          )
        );
        break;
      case "Contacts":
        setTabNumber(0);
        setTabComponent(
          result && (
            <Contacts
              source="customer"
              data={formatFacilityResult("customer", result)}
            />
          )
        );
        break;
    }
  }, [activeTab, result]);

  return (
    <Layout className="frame">
      <Header id="search-header">
        <NavBar />
      </Header>
      <Content className="content">
        <Row id="search-backlink">
          <Link onClick={() => navigate(-1)}>
            <Space>
              <ArrowLeftOutlined />
              {t("Back to Search Results")}
            </Space>
          </Link>
        </Row>
        <Row className="results">
          <ResultCard source={"customer"} result={result} clickable={false} />
        </Row>
        <Row className="categories">
          {CategoryGenerator(categories, activeTab, setActiveTab, tabNumber)}
        </Row>
        <Row>{tabComponent}</Row>
      </Content>
    </Layout>
  );
};
