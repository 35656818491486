import axios from "axios";
import { PostTransactionType } from "../models/dataType";

const postTransaction = async (
  token: string,
  transaction: PostTransactionType,
) => {
  const url = process.env.REACT_APP_LOGGING_BACKEND;

  if (!url) return;

  return await axios
    .post(`${url}/transactions`, transaction, {
      headers: { Authorization: "Bearer " + token },
    })
    .then((response) => {
      return response;
    })
    .catch((e) => console.error(e));
};

export { postTransaction };
