import "@elastic/react-search-ui-views/lib/styles/styles.css";
import "./App.css";

import { RouterProvider, createBrowserRouter } from "react-router-dom";
import { ConfigProvider } from "antd";
import { MsalProvider } from "@azure/msal-react";
import { PublicClientApplication } from "@azure/msal-browser";
import { Provider } from "@rollbar/react";

import { hotjar } from "react-hotjar";
import { HelmetProvider, Helmet } from "react-helmet-async";

import { ErrorPage } from "./routes/ErrorPage";
import { RootPage } from "./routes/RootPage";
import { SearchPage } from "./routes/SearchPage";
import { LandingPage } from "./routes/LandingPage";
import {
  FactoryDetailPage,
  factoryDetailLoader,
} from "./routes/FactoryDetailPage";
import {
  SupplierDetailPage,
  supplierDetailLoader,
} from "./routes/SupplierDetailPage";
import {
  ProductDetailPage,
  productDetailLoader,
} from "./routes/ProductDetailPage";
import { MaintenancePage } from "./routes/MaintenancePage";
import { BackendErrorPage } from "./routes/BackendErrorPage";
import {
  CustomerDetailPage,
  customerDetailLoader,
} from "./routes/CustomerDetailPage";
import { useEffect } from "react";
import { LogoutPage } from "./routes/LogoutPage";
import { LoginPage } from "./routes/LoginPage";
import { CategoryPage } from "./routes/CategoryPage";
import { PreferencesPage } from "./routes/PreferencesPage";
import {ShortListPage} from "./routes/ShortListPage";
import {ComparePage} from "./routes/ComparePage";
import {MsalWrapperProvider} from "./contexts/MSALContext";
import {ImageLandingPage} from "./routes/ImageLandingPage";
import {ImageSearchPage} from "./routes/ImageSearchPage";
import {ToastContainer} from "react-toastify";
import 'react-toastify/dist/ReactToastify.css';
import {NotFoundPage} from "./routes/NotFoundPage";

const antdTheme = {
  token: {
    fontFamily:
      "Open Sans, -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Helvetica Neue, Arial, Noto Sans, sans-serif, Apple Color Emoji, Segoe UI Emoji, Segoe UI Symbol, Noto Color Emoji",
    colorPrimary: "#972d47",
    colorLink: "#972d47",
    colorLinkHover: "#e3436a",
  },
};

export const routerConfig = [
  {
    errorElement: <ErrorPage />,
    children: [
      {
        element: <RootPage />,
        children: [
          {
            path: "/",
            element: <LandingPage />,
          },
          {
            path: "/image",
            element: <ImageLandingPage />,
            // element: <ImageLandingPage />
          },
          {
            path: "/preferences",
            element: <PreferencesPage />,
          },
          {
            path: "/image/search",
            element: <ImageSearchPage />,
          },
          {
            path: "/search",
            element: <SearchPage />,
          },
          {
            path: "/:id/search",
            element: <SearchPage />,
          },
          {
            path: "factory/:id",
            element: <FactoryDetailPage />,
            loader: factoryDetailLoader,
          },
          {
            path: "supplier/:id",
            element: <SupplierDetailPage />,
            loader: supplierDetailLoader,
          },
          {
            path: "supplier/:id/:category",
            element: <SupplierDetailPage />,
            loader: supplierDetailLoader,
          },
          {
            path: "suppliers/:searchTerm",
            element: <CategoryPage activeCategory="supplier" />,
          },
          {
            path: "suppliers",
            element: <CategoryPage activeCategory="supplier" />,
          },
          {
            path: "factories",
            element: <CategoryPage activeCategory="factory" />,
          },
          {
            path: "products",
            element: <CategoryPage activeCategory="product" />,
          },
          {
            path: "customers",
            element: <CategoryPage activeCategory="customer" />,
          },
          {
            path: "product/:id",
            element: <ProductDetailPage />,
            loader: productDetailLoader,
          },
          {
            path: "customer/:id",
            element: <CustomerDetailPage />,
            loader: customerDetailLoader,
          },
          {
            path: "/maintenance",
            element: <MaintenancePage />,
          },
          {
            path: "/backend-error",
            element: <BackendErrorPage />,
          },
          {
            path: "/not-found",
            element: <NotFoundPage />,
          },
          {
            path: "/logout",
            element: <LogoutPage />,
          },
          {
            path: "/login",
            element: <LoginPage />,
          },
          {
            path: "/shortlist",
            element: <ShortListPage />
          },
          {
            path: "/compare",
            element: <ComparePage />
          }
        ],
      },
    ],
  },
];

export const App = ({
  msalInstance,
}: {
  msalInstance: PublicClientApplication;
}) => {
  const router = createBrowserRouter(routerConfig);
  const helmetContext = {};

  const rollbarConfig = {
    accessToken: process.env.REACT_APP_ROLLBAR_POST_CLIENT_ITEM_TOKEN,
    captureUncaught: true,
    captureUnhandledRejections: true,
    payload: {
      client: {
        javascript: {
          code_version: "1.0.0",
          source_map_enabled: true,
        },
      },
    },
  };

  // Hotjar initialization
  useEffect(() => {
    if (!process.env.REACT_APP_HJID || !process.env.REACT_APP_HJSV) {
      console.error("Unable to initialize hotjar, missing env vars");
    } else {
      const hjid = parseInt(process.env.REACT_APP_HJID);
      const hjsv = parseInt(process.env.REACT_APP_HJSV);
      hotjar.initialize({id: hjid, sv: hjsv});
    }
  });

  return (
    <HelmetProvider context={helmetContext}>
      <Helmet>
        <script type="text/javascript">
          {`
   window.dataLayer = window.dataLayer || [];
   function gtag(){dataLayer.push(arguments);}
   gtag('js', new Date());
   gtag('config', '${process.env.REACT_APP_GA_TRACKING_ID}');`}
        </script>
      </Helmet>
      <Provider config={rollbarConfig}>
        <MsalProvider instance={msalInstance}>
          <MsalWrapperProvider msalInstance={msalInstance}>
            <ConfigProvider theme={antdTheme}>
              <RouterProvider router={router} />
              <ToastContainer />
            </ConfigProvider>
          </MsalWrapperProvider>
        </MsalProvider>
      </Provider>
    </HelmetProvider>
  );
};
