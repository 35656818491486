import { useEffect, useState } from "react";
import {useLoaderData, useNavigate} from "react-router-dom";
import { getSearchClient } from "../services/SearchService";
import { FacilityDetails } from "../components/FacilityDetails";

export const factoryDetailLoader = ({
  params,
  request,
}: {
  params: any;
  request: any;
}) => {
  const id = params.id;

  const url = new URL(request.url);
  const ps = url.searchParams.get("ps") === "1"; // transform into boolean
  const st = ps ? url.searchParams.get("st") : null;

  return { id, ps, st };
};

export const FactoryDetailPage = () => {
  const { id, ps, st } = useLoaderData() as any;
  const [result, setResult] = useState(null);

  const navigate = useNavigate();

  useEffect(() => {
    const client = getSearchClient();
    const options = {
      search_fields: { factory_code: {} },
      // result_fields: searchQueryOptions.result_fields,
    };
    client
      .search(id, options)
      .then((resultList: any) => {
        if (resultList.rawResults.length == 0) {
          navigate('/not-found')
          return
        }
        const filteredResultList = resultList.rawResults.filter((result: any) => result.factory_code?.raw === id)
        if (filteredResultList.length == 0) {
          navigate('/not-found')
          return
        }
        setResult(filteredResultList[0])
      })
      .catch((error: any) => console.error(error));
  }, [id]);

  return (
    <>
      {
        result &&
          <FacilityDetails source={"factory"} result={result} productFilter={ps ? st : null}/>
      }
    </>
  );
};
