import { useEffect, useState } from "react";
import { formatFacilityResult } from "../models/dataType";
import { getFactoryClient, getSupplierClient } from "../services/SearchService";
import { FacilityCard } from "./FacilityCard";
import { Spin } from "antd";

interface FacilityGridProps {
  source: "factory" | "supplier";
  data: { [index: string]: any };
  setTabNumber: (num: number) => void;
  productCategory?: string
}

export const FacilityGrid = ({
  source,
  data,
  setTabNumber,
    productCategory
}: FacilityGridProps) => {
  // TODO: search with given origin
  const [results, setResults] = useState<any[]>();
  const facilityMap: any = {
    factory: "factoryRelationships",
    supplier: "supplierRelationships",
  };
  const relationshipField = facilityMap[source];

  useEffect(() => {
    setTabNumber(data[relationshipField]?.length ?? 0);
  }, [setTabNumber, data, relationshipField]);

  useEffect(() => {
    if (!results) return;
    setTabNumber(results?.length);
  }, [setTabNumber, results]);

  useEffect(() => {
    if (!data[relationshipField]) return;
    const client =
      source === "factory" ? getFactoryClient() : getSupplierClient();
    const options =
      source === "factory"
        ? {
            search_fields: { factory_code: {}
              // , ...( productCategory && { product_category : {productCategory} })
            },
            page: { size: 1, current: 1 },
          }
        : {
            search_fields: { supplier_code: {}
            },
            page: { size: 1, current: 1 },
          };

    const facilities = data[relationshipField].slice();

    const getFacilities = async () => {
      const doneFacilities: string[] = [];
      let newResults: any[] = [];

      if (productCategory) {
        const results = await getSupplierClient().search('', {
          search_fields: { supplier_code: {},
          },
          page: { size: productCategory ? 100 : 1, current: 1 },
          filters: { "sales__product_category_cogs.product_category" : [productCategory?.toUpperCase()] }
        })
        setResults([...results.rawResults])
        return
      }

      for (const facility of facilities) {

        const resultList = await client.search(facility, options);
        if (productCategory) {
          newResults = resultList.rawResults
          return
        }
        if (!doneFacilities.includes(facility)) {
          const result = resultList.rawResults[0]
          if (!result) continue

          const code = source === "factory" ? result.factory_code.raw : result.supplier_code.raw
          if (code === facility) {
            newResults.push(resultList.rawResults[0]);
            doneFacilities.push(facility);
          }
        }
      }
      setResults([...newResults]);
    };

    getFacilities();
  }, [data, relationshipField, source]);

  return data[relationshipField] ? (
    results ? (
      results.length === 0 ? (
        <p>No relationships to show</p>
      ) : (
        <div className="facility-grid">
          {results.map((result: any) => {
            return (
              result && (
                productCategory ?
                    <FacilityCard
                        key={result.id.raw}
                        source={"supplier"}
                        data={formatFacilityResult("supplier", result)}
                    />
                    :
                    <FacilityCard
                        key={result.id.raw}
                        source={source}
                        data={formatFacilityResult(source, result)}
                    />
              )
            );
          })}
        </div>
      )
    ) : (
      <div className="facility-grid">
        <Spin></Spin>
      </div>
    )
  ) : (
    <p>No relationships to show</p>
  );
};
