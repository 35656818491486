import { Col, Input, Row, Spin, Switch, Typography } from "antd";
import { FilterFilled } from "@ant-design/icons";
import { ProductGridCard } from "./ProductGridCard";
import { useTranslation } from "react-i18next";
import { useEffect, useState } from "react";
import { getProductClient } from "../services/SearchService";
import { DataType, formatProductResult } from "../models/dataType";
import { isShortListed } from "../services/ShortListService";
import { useShortLists } from "../contexts/ShortListContext";

const { Text } = Typography;
const { Search } = Input;

interface ProductGridProps {
  source: "factory" | "supplier";
  data: DataType;
  search_term: string;
  setTabNumber: (num: number) => void;
}
/**
 * Calls product search on load, to find products from the given supplier / factory
 * Presents the products in a grid
 */
export const ProductGrid = ({
  source,
  data,
  search_term,
  setTabNumber,
}: ProductGridProps) => {
  const [useFilter, setUseFilter] = useState(false);
  const [products, setProducts] = useState<any>([]);
  const [loading, setLoading] = useState(true);

  const pageSize = 60;

  const [pageNumber, setPageNumber] = useState<number>(1);

  const [currentPage, setCurrentPage] = useState<number>(1);

  const [totalPages, setTotalPages] = useState<number>(1);

  const { t } = useTranslation();

  const { shortListIds } = useShortLists();

  const handleOnSearch = (value: string) => {
    // TODO: Send to Search to filter results
    // Test with live data later
  };

  const handleOnScroll = () => {
    const scrollPercent =
      window.scrollY / document.documentElement.scrollHeight;

    if (scrollPercent < 0.5) return;

    if (pageNumber !== currentPage || currentPage === totalPages) return;

    setPageNumber(pageNumber + 1);
  };

  useEffect(() => {
    window.addEventListener("scroll", handleOnScroll, { passive: true });

    return () => {
      window.removeEventListener("scroll", handleOnScroll);
    };
  });

  useEffect(() => {
    const client = getProductClient();
    const options =
      source === "factory"
        ? {
            search_fields: { "sales__manufacturer.factory_code": {} },
            page: { size: 100, current: 1 },
            // result_fields: searchQueryOptions.result_fields,
          }
        : {
            search_fields: { "sales__manufacturer.supplier_code": {} },
            page: { size: pageSize, current: pageNumber },
          };

    const getProducts = async () => {
      const resultList = await client.search(data.code, options);

      setProducts(products.concat(resultList.rawResults));

      setTabNumber(resultList.rawInfo.meta.page.total_results);

      setCurrentPage(resultList.rawInfo.meta.page.current);
      setTotalPages(resultList.rawInfo.meta.page.total_pages);

      setLoading(false);
    };

    getProducts();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [setTabNumber, source, data, pageNumber]);

  return loading ? (
    <Spin tip={"Loading..."}></Spin>
  ) : (
    <>
      <Row style={{ width: "100%" }}>
        {/*<Row className="product-searchbar">*/}
        {/*  /!*<div className="product-searchbar-left">*!/*/}
        {/*  /!*  <Text className="clickable">{t("All Products")}</Text>*!/*/}
        {/*  /!*  <Text className="clickable" type="secondary">*!/*/}
        {/*  /!*    {t("Categories")}*!/*/}
        {/*  /!*  </Text>*!/*/}
        {/*  /!*</div>*!/*/}
        {/*  /!*<div className="product-searchbar-right">*!/*/}
        {/*  /!*  <Col>*!/*/}
        {/*  /!*    <Search*!/*/}
        {/*  /!*        placeholder="Search"*!/*/}
        {/*  /!*        allowClear*!/*/}
        {/*  /!*        enterButton*!/*/}
        {/*  /!*        onSearch={handleOnSearch}*!/*/}
        {/*  /!*    />*!/*/}
        {/*  /!*  </Col>*!/*/}
        {/*  /!*  <Col className="product-filter-switch">*!/*/}
        {/*  /!*    <FilterFilled />*!/*/}
        {/*  /!*    <Text>{t("Filter & Sort")}</Text>*!/*/}
        {/*  /!*    <Switch onChange={() => setUseFilter(!useFilter)} />*!/*/}
        {/*  /!*  </Col>*!/*/}
        {/*  /!*</div>*!/*/}
        {/*</Row>*/}
        <Row className="product-grid">
          {products.map((product: any) => {
            const itemNumber = product.item_number?.raw;
            const isShortList = isShortListed(itemNumber, shortListIds);
            return (
              <ProductGridCard
                key={product.id.raw}
                data={formatProductResult(product)}
                shortListed={isShortList}
              />
            );
          })}
        </Row>
      </Row>
      <div
        style={{
          alignItems: "center",
          justifyItems: "center",
          alignContent: "center",
          marginTop: "50px",
          marginBottom: "50px",
        }}>
        {currentPage === totalPages && (
          <Text
            style={{
              position: "absolute",
              left: "50%",
              transform: "translate(-50%, -50%)",
            }}>
            {t("No More Products")}
          </Text>
        )}
      </div>
    </>
  );
};
