import axios from "axios";
import { SourceType } from "../models/dataType";

export interface UniqueEntities {
  customers: string[];
  suppliers: string[];
  factories: string[];
}

export interface RelationshipRow {
  CUST_CODE: string; //"PUIP",
  FAC_CODE: string; //"DOFAGR",
  SUP_CODE: string; //"WINKOI",
  UPDDAT: string; //"2023-06-08T03:16:34.000+08:00",
  extraction_date: string; //"2023-06-08"
}

interface Edge {
  start: string;
  end: string;
}

export const getUniqueEntities = (rows: RelationshipRow[]): UniqueEntities => {
  let customers: string[] = [];
  let suppliers: string[] = [];
  let factories: string[] = [];

  // Get all unique entities
  for (const row of rows) {
    const rowCus = row.CUST_CODE;
    const rowFac = row.FAC_CODE;
    const rowSup = row.SUP_CODE;

    if (rowCus && !customers.includes(rowCus)) customers.push(rowCus);
    if (rowFac && !customers.includes(rowFac)) customers.push(rowFac);
    if (rowSup && !customers.includes(rowSup)) customers.push(rowSup);
  }

  return { customers, suppliers, factories };
};

export const getEdgePairs = (rows: RelationshipRow[]): Edge[] => {
  let pairs: Edge[] = [];
  for (const row of rows) {
    const cs = { start: row.CUST_CODE, end: row.SUP_CODE };
    const sf = { start: row.SUP_CODE, end: row.FAC_CODE };

    if (!pairs.includes(cs)) pairs.push(cs);
    if (!pairs.includes(sf)) pairs.push(sf);
  }

  return pairs;
};

export const getRelationships = async (
  source: SourceType,
  id: string,
): Promise<RelationshipRow[]> => {
  const url = process.env.REACT_APP_RELATIONSHIPS_API as string;
  if (!url) throw new Error("Missing Relationships API");

  const apiUrl = url + `/${source}_relationships`;

  const body = { entity_id: id };
  const headers = {
    "Ocp-Apim-Subscription-Key": process.env.REACT_APP_RELATIONSHIPS_API_KEY,
  };
  const response = await axios.post(apiUrl, body, { headers: headers });
  if (response.status !== 200) throw new Error("Relationships API not okay");

  const relationships: RelationshipRow[] = response.data.data.rows;
  return relationships;
};
