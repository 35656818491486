import { Button, message, Upload } from "antd";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import React, { useState } from "react";
import { RcFile } from "antd/es/upload";
import { validateImage } from "../services/UploadUtils";
import { UploadOutlined } from "@ant-design/icons";

interface iCustomImageSearchBoxProps {
  hideImageButton?: boolean;
}

export const CustomImageSearchBox: React.FC<iCustomImageSearchBoxProps> = (
  props,
) => {
  const { t } = useTranslation();

  const navigate = useNavigate();

  const [searchTerm, setSearchTerm] = useState<string>("");

  const [fileValid, setFileValid] = useState<boolean>(false);

  const beforeUpload = (file: RcFile) => {
    setFileValid(false);
    const [isJpg, isLt5M] = validateImage(file, "image/jpeg", 5);
    if (!isJpg) {
      message.error("Incorrect Image format. Only JPEG/JPG supported");
    }
    if (!isLt5M) {
      message.error("Image must smaller than 5MB");
    }
    if (isJpg && isLt5M) {
      setFileValid(true);
      message.success("Image uploaded successfully");
    }
    return isJpg && isLt5M;
  };

  const onChange = (file: any) => {
    if (file.status !== "uploading") {
      if (!fileValid) {
        console.error("File invalid");
        return;
      }
      navigate(`/image/search`, { state: { file } });
    }
  };

  return (
    <div className="search-box">
      <div className="sui-search-box">
        <div className={"sui-search-box__image_wrapper"}>
          <form
            style={{ width: "100%", display: "flex", flexDirection: "row" }}
            onSubmit={(e) => {
              e.preventDefault();
              navigate(`/image/search?q=${searchTerm}`);
            }}>
            {!props?.hideImageButton && (
              <Upload
                beforeUpload={beforeUpload}
                onChange={onChange}
                showUploadList={false}>
                <Button
                  className="sui-search-box__text-image-button-input"
                  style={{ color: "white" }}>
                  {t("IMAGE")}
                  <UploadOutlined />
                </Button>
              </Upload>
            )}
            <input
              className={
                props?.hideImageButton
                  ? "sui-search-box__text-image-input-text"
                  : "sui-search-box__text-image-input"
              }
              placeholder="Looking for products, suppliers, or factories?"
              value={searchTerm}
              onChange={(e) => setSearchTerm(e.target.value)}
            />
          </form>
        </div>
      </div>
    </div>
  );
};
