import { useEffect, useState } from "react";
import { DataType, formatFacilityResult } from "../models/dataType";
import { getFactoryClient, getSupplierClient } from "../services/SearchService";
import { FacilityCard } from "./FacilityCard";
import { ResultCard } from "./ResultCard";
import { Spin } from "antd";

interface CustomerFacilityGridProps {
  source: "factory" | "supplier";
  data: DataType;
  setTabNumber: (num: number) => void;
}

export const CustomerFacilityGrid = ({
  source,
  data,
  setTabNumber,
}: CustomerFacilityGridProps) => {
  const [results, setResults] = useState<any[]>();
  // Set tabnumber
  useEffect(() => {
    if (!results) return;
    setTabNumber(results.length);
  }, [setTabNumber, results]);

  useEffect(() => {
    setResults(undefined);

    const getCustomerFacilities = async () => {
      const doneFacilities: string[] = [];
      const newResults: any[] = [];

      const relationshipFacilities =
        source === "factory"
          ? data["factoryRelationships"]
          : data.topCustomersCalculated;
      const subClient =
        source === "factory" ? getFactoryClient() : getSupplierClient();
      const subOptions =
        source === "factory"
          ? {
              search_fields: { factory_code: {} },
              page: { size: 1, current: 1 },
            }
          : {
              search_fields: { supplier_code: {} },
              page: { size: 1, current: 1 },
            };

      for (const facility of relationshipFacilities) {
        if (typeof facility === "string") {
          const resultList = await subClient.search(facility, subOptions);

          if (!doneFacilities.includes(facility)) {
            newResults.push(resultList.rawResults[0]);
            doneFacilities.push(facility);
          }
        } else {
          const resultList = await subClient.search(
            facility.customer,
            subOptions,
          );

          if (!doneFacilities.includes(facility.customer)) {
            newResults.push(resultList.rawResults[0]);
            doneFacilities.push(facility.customer);
          }
        }
      }
      const filteredResults = newResults.filter((e) => e !== undefined);
      setResults([...filteredResults]);
    };

    getCustomerFacilities();
  }, [data, source]);

  return results ? (
    results.length === 0 ? (
      <p>No relationships to show</p>
    ) : (
      <div className={source === "factory" ? "facility-grid" : "results"}>
        {results.map((r: any) =>
          source === "factory" ? (
            <FacilityCard
              key={r.id.raw}
              source={source}
              data={formatFacilityResult(source, r)}
            />
          ) : (
            <ResultCard key={r.id.raw} source={"supplier"} result={r} />
          ),
        )}
      </div>
    )
  ) : (
    <div className={source === "factory" ? "facility-grid" : "results"}>
      <Spin></Spin>
    </div>
  );
};
