import {Card, Col, Image, Rate, Row, Typography} from "antd";
import { UserOutlined, MailOutlined } from "@ant-design/icons";
import React from "react";

const {Text} = Typography;

interface CommentType {
    body: string,
    id: string,
    objectType: string,
    subject: string,
    title: string,
    owner: {
        firstName: string,
        lastName: string
    },
    ratingValue?: number,
    imageUrl?: string,
    itemTag?: string
}

export const CommentCard = ({data}: { data: CommentType }) => {
    return (
        <Card>
            <Row>
                <Col>
                    <Image
                        src={data.imageUrl ? data.imageUrl : ''}
                        fallback={process.env.PUBLIC_URL + "/fallbackImage.png"}
                        className="rounded"
                        height={150}
                    />

                </Col>
                <Col style={{marginLeft: '14px'}}>
                    <Row>
                        <Col>
                            <Rate allowHalf disabled defaultValue={data.ratingValue ? data.ratingValue : 0} style={{fontSize: 16}}/>
                        </Col>
                        <Col style={{alignItems: 'center', marginLeft: '10px', marginTop: '2px'}}>
                            <Text style={{color: '#234963', fontSize: 15}}>{data.ratingValue ? data.ratingValue.toFixed(1) : 0}</Text>
                        </Col>
                    </Row>
                    <Row style={{marginTop: '10px'}}>
                        <Text style={{ color: "#515761" }} strong>{data.title}</Text>
                    </Row>
                    <Row>
                        <Text style={{ color: "#7a8489" }}>{data.itemTag ? data.itemTag : '--'}</Text>
                    </Row>
                    <Row style={{marginTop: '15px', maxWidth: '562px'}}>
                        <Text >{data.body}</Text>
                    </Row>
                    <Row style={{marginTop: '15px'}}>
                        <Col>
                            <div className="user" style={{backgroundColor: "#972d47", borderRadius: '100%'}}>
                                <UserOutlined style={{color: 'white', padding: '4px'}}/>
                            </div>
                        </Col>
                        <Col style={{alignItems: 'center', marginLeft: '5px'}}>
                            <Text style={{color: "#7a8489"}}>{data.owner ? `${data.owner.firstName} ${data.owner.lastName}` : '--'},</Text>
                        </Col>
                        {/*<Col style={{alignItems: 'center', marginLeft: '5px'}}>*/}
                        {/*    <Text style={{color: "#7a8489"}}>{data.company ? data.company : '--'}</Text>*/}
                        {/*</Col>*/}
                        <Col style={{alignItems: 'center', marginLeft: '10px'}}>
                            <MailOutlined style={{color: "#972d47"}}/>
                        </Col>
                    </Row>
                </Col>

            </Row>

        </Card>
    );
}
