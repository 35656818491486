import { useContext, useEffect, useState } from "react";
import { Preference, UserContext } from "../contexts/UserInfoContext";
import { Button, Input, Layout, Spin, Typography, message } from "antd";
import { NavBar } from "../components/NavBar";
import { useTranslation } from "react-i18next";
import { submitPreferences } from "../services/UserService";
import { IdTokenContext } from "../contexts/IdTokenContext";

const { Header, Content } = Layout;
const { Title } = Typography;

export const PreferencesPage = () => {
  const user = useContext(UserContext);
  const token = useContext(IdTokenContext);
  const [preferences, setPreferences] = useState<Preference[]>();
  const [language, setlanguage] = useState<string>();
  const [messageApi, contextHolder] = message.useMessage();

  const { t } = useTranslation();

  useEffect(() => {
    const prefs = user?.preferences;
    if (!prefs) return;
    if (prefs.length === 0) {
      prefs.push({ parameter: "language", value: "en" });
    }
    setPreferences(prefs);
  }, [user]);

  useEffect(() => {
    if (!preferences) return;
    const langPref = preferences.find((pref) => pref.parameter === "language");

    if (langPref) setlanguage(langPref.value);
  }, [preferences]);

  const handleSubmit = async () => {
    const newPreferences: Preference[] = [];
    if (language) {
      newPreferences.push({
        parameter: "language",
        value: language,
      });
    } else {
      messageApi.warning("Cannot submit empty preference")
      return
    }

    const res = await submitPreferences(token, newPreferences);
    if (res.status=== 200) {
      messageApi.success("Successfully updated preferences")
    } else {
      messageApi.error("Failed to update preferences!")
      console.error(res)
    }
  };

  return user && preferences ? (
    <Layout className="frame">
      {contextHolder}
      <Header className="header">
        <NavBar />
      </Header>
      <Content className="content">
        <Title>{t("User Preferences")}</Title>
        {(
          <Input
            addonBefore="Language"
            value={language}
            onInput={(e) => {
              setlanguage(e.currentTarget.value);
            }}
          />
        )}
        <Button type="primary" size="middle" onClick={handleSubmit}>
          {t("Submit")}
        </Button>
      </Content>
    </Layout>
  ) : (
    <Spin tip="Loading..."></Spin>
  );
};
