import { Row, Col } from "antd";
import { useNavigate } from "react-router-dom";
import { UserDropdown } from "./UserDropDown";
import {CustomImageSearchBox} from "./CustomImageSearchBox";

export const ImageNavBar = () => {
  const navigate = useNavigate();

  return (
    <Row id="navbar">
      <Col className="lf-logo" onClick={() => navigate("/image")}>
        <img
          src={process.env.PUBLIC_URL + "/icons/LFLogo.svg"}
          alt="Li & Fung"
        />
      </Col>
      <Col flex="auto">
        <CustomImageSearchBox hideImageButton={true}/>
      </Col>
      <UserDropdown />
    </Row>
  );
};
