import axios from "axios";

const url = process.env.REACT_APP_USERS_BACKEND;

// Posts or deletes via the type & code of item
const shortListItem = async (type: string, code: string, token: string, isDelete?: boolean) => {
    if (!url) throw new Error("Missing User API");

    const headers = {Authorization: `Bearer ${token}`};

    const apiUrl = `${url}/me/shortlists/${type}/${code}`

    let response

    if (isDelete) {
        response = await axios.delete(apiUrl, { headers: headers })
            .then((response) => {return response})
            .catch((e) => console.error(e))
    } else {
        response = await axios.post(apiUrl, {}, { headers: headers })
            .then((response) => {return response})
            .catch((e) => console.error(e))
    }

    if (!response) return

    return response
}

// Gets all id of user's shortlisted items
const getShortList = async (token: string) => {
    const headers = {Authorization: `Bearer ${token}`};

    const response = await axios.get(`${url}/me/shortlists`, { headers: headers })
        .then((response) => {return response})
        .catch((e) => console.error(e))

    if (!response) return

    return response
}

// Takes item code & short list id array.
// Returns bool on if item is found in id array
const isShortListed = (code: string | undefined, shortListIds: string[]): boolean => {
    if (!code) return false
    return shortListIds.includes(code)
}


export {shortListItem, getShortList, isShortListed}