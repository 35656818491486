import { Col, Layout, Row, Spin, Typography } from "antd";
import { useLocation, useSearchParams } from "react-router-dom";
import { ImageNavBar } from "../components/ImageNavBar";
import { useContext, useEffect, useState } from "react";
import { getImageWithFile } from "../services/ImageSearchService";
import { getProductClient, getSearchClient } from "../services/SearchService";
import { ErrorBoundary, withSearch } from "@elastic/react-search-ui";
import { ResultProductCard } from "../components/ResultProductCard";
import { formatProductResult } from "../models/dataType";
import { isShortListed } from "../services/ShortListService";
import { useShortLists } from "../contexts/ShortListContext";
import { ImageSearchPagingInfo } from "../components/ImageSearchPagingInfo";
import { ImageSearchUpload } from "../components/ImageSearchUpload";
import { useTranslation } from "react-i18next";
import { useMsalWrapper } from "../contexts/MSALContext";
import {
  Filter,
  FilterType,
  FilterValue,
  SearchResult,
} from "@elastic/search-ui";
import { ImageSearchPaging } from "../components/ImageSearchPaging";
import { UserContext } from "../contexts/UserInfoContext";

const { Header, Content } = Layout;
const { Title } = Typography;

interface SearchProps {
  resultSearchTerm: string;
  setSearchTerm: (term: string) => void;
  results: SearchResult[];
  filters: Filter[] | undefined;
  setFilter: (
    name: string,
    value: FilterValue,
    type?: FilterType | undefined,
  ) => void;
  clearFilters: (except?: string[] | undefined) => void;
  totalResults: number;
  isLoading: boolean;
}

export const ImageSearchPageContent = ({
  resultSearchTerm,
  setSearchTerm,
  filters,
  setFilter,
  clearFilters,
  totalResults,
  isLoading,
}: SearchProps) => {
  const location = useLocation();
  const [searchParams, setSearchParams] = useSearchParams();
  const [results, setResults] = useState<any[]>([]);
  const [loading, setLoading] = useState<boolean>(false);
  const [file, setFile] = useState<any>(undefined);
  const pageSize = 20;
  const [currentPage, setCurrentPage] = useState<number>(1);
  const [totalMetaPages, setMetaTotalPages] = useState<number>(0);
  const [totalMetaResults, setMetaTotalResults] = useState<number>(0);
  const [displaySearchTerm, setDisplaySearchTerm] = useState<string>();
  const [imageResults, setImageResults] = useState<any[]>([]);
  const [isLFEU, setIsLFEU] = useState<boolean | undefined>(undefined);
  const { t } = useTranslation();
  const { acquireToken } = useMsalWrapper();
  const { shortListIds } = useShortLists();
  const user: any = useContext(UserContext);

  useEffect(() => {
    setIsLFEU(user?.divisions?.includes("lfeu") ?? false);
  }, [user]);

  const onClickImgSearch = async () => {
    if (!file) return;
    const token = await acquireToken();
    if (!token) return;
    setDisplaySearchTerm(`image`);
    setSearchParams("");
    setLoading(true);
    setFile(file);
    const response = await getImageWithFile(file.name, file.type, file, token);
    if (!response) {
      setLoading(false);
      return;
    }
    setImageResults(response);
    setLoading(false);
  };

  const getProductByText = async (searchTerm: string) => {
    const filters = isLFEU
      ? { all: [{ entity_type: ["product"] }, { operating_group: ["LFEU"] }] }
      : { all: [{ entity_type: ["product"] }] };
    const options = { filters, page: { size: pageSize, current: currentPage } };
    const client = getSearchClient();
    const result = await client.search(searchTerm, options);
    setMetaTotalPages(result.info.meta.page.total_pages);
    setMetaTotalResults(result.info.meta.page.total_results);
    setResults(result.rawResults);
  };

  useEffect(() => {
    setLoading(true);
    const fetchResults = async () => {
      const token = await acquireToken();
      if (!token) return;
      if (location?.state?.file?.file?.originFileObj) {
        const fileObj = location?.state?.file?.file?.originFileObj;
        setFile(location?.state?.file?.file?.originFileObj);
        setDisplaySearchTerm(`image`);
        const response = await getImageWithFile(
          fileObj.name,
          fileObj.type,
          fileObj,
          token,
        );
        if (!response) {
          setImageResults([]);
          return;
        }
        setImageResults(response);
      }
      const searchTerm = searchParams.get("q");
      if (!searchTerm) return;
      getProductByText(searchTerm)
        .then(() => setLoading(false))
        .catch(() => setLoading(false));
    };
    if (isLFEU !== undefined) {
      fetchResults();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    searchParams,
    location?.state?.file?.file?.originFileObj,
    currentPage,
    isLFEU,
  ]);

  useEffect(() => {
    const client = getProductClient();
    const options = {
      search_fields: { image_url: {} },
      filters: isLFEU ? { operating_group: "LFEU" } : {},
    };
    const getMultiProduct = async (imageIDs: number[]) => {
      const searches = imageIDs.map((id) => ({
        query: id.toString(),
        options,
      }));
      const responses = await client.multiSearch(searches, options);
      return responses
        .filter(
          (i: any) => Array.isArray(i.rawResults) && i.rawResults.length > 0,
        )
        .map((i: any) => i.rawResults[0]);
    };
    const getAllProducts = async () => {
      const results: any = [];
      const batches = [
        imageResults.slice(0, 10),
        imageResults.slice(10, 20),
        imageResults.slice(20, 30),
        imageResults.slice(30, 40),
        imageResults.slice(40, 50),
      ];

      const seenIds = new Set();

      for (const batch of batches) {
        const response = await getMultiProduct(
          batch.map((image) => image.imageID),
        );
        if (!response) continue;

        response.forEach((product: any) => {
          if (!seenIds.has(product.id.raw)) {
            seenIds.add(product.id.raw);
            results.push(product);
          }
        });
      }

      setResults(results);
    };

    if (imageResults.length > 0 && isLFEU !== undefined) {
      setLoading(true);
      getAllProducts()
        .then(() => setLoading(false))
        .catch(() => setLoading(false));
    }
  }, [imageResults, isLFEU]);

  return (
    <Layout className="frame">
      <Header className="header">
        <ImageNavBar />
      </Header>
      <Content className="content">
        <ErrorBoundary>
          {loading ? (
            <Spin tip="Loading..." style={{ marginTop: "30px" }} />
          ) : (
            <>
              <Row id="search-title">
                <div id="box" />
                <Title level={2}>
                  {t("Search Results for") +
                    ` ${
                      searchParams.get("q")
                        ? `"${searchParams.get("q")}"`
                        : "image"
                    }`}
                </Title>
              </Row>
              <Row
                id="search-paging"
                style={{ marginTop: "15px", marginBottom: "3px" }}>
                <div style={{ opacity: 0 }}>
                  <ImageSearchPagingInfo
                    currentPage={results.length}
                    totalResults={totalMetaResults}
                  />
                </div>
                {searchParams && !file && (
                  <ImageSearchPaging
                    current={currentPage}
                    onChange={(selectedPage: number) =>
                      setCurrentPage(selectedPage)
                    }
                    resultsPerPage={pageSize}
                    totalPages={totalMetaPages}
                  />
                )}
              </Row>
              <Row className="search-body" wrap={false}>
                <Col>
                  <ImageSearchUpload
                    file={file}
                    setFile={setFile}
                    onClick={onClickImgSearch}
                  />
                </Col>
                <Col
                  className="results"
                  flex={"auto"}
                  style={{ marginBottom: "50px" }}>
                  {results.length > 0 ? (
                    results.map((result) => {
                      const itemNumber =
                        result.item_number?.raw || result.item_number;
                      const isShortList = isShortListed(
                        itemNumber,
                        shortListIds,
                      );
                      return (
                        <ResultProductCard
                          data={formatProductResult(result)}
                          shortListed={isShortList}
                          key={result.id.raw}
                        />
                      );
                    })
                  ) : (
                    <Col>
                      <Row>
                        <img
                          src={process.env.PUBLIC_URL + "/icons/sad.svg"}
                          alt=""
                        />
                      </Row>
                      <Row>
                        <Title level={3}>{t("No Results Found")}</Title>
                      </Row>
                    </Col>
                  )}
                </Col>
              </Row>
            </>
          )}
        </ErrorBoundary>
      </Content>
    </Layout>
  );
};

const ImageSearchPageWrappedContent = withSearch((SearchProps) => SearchProps)(
  ImageSearchPageContent,
);

export const ImageSearchPage = () => <ImageSearchPageWrappedContent />;
